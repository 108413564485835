import dayjs, { Dayjs } from "dayjs";

export class Credentials {
  dns = "";

  login = "";

  password = "";
}

export class Tenant {
  id = "";

  name = "";

  creationDate: Dayjs = dayjs();

  isActive = false;

  credentials: Credentials = new Credentials();
}

export class TenantForm extends Tenant {}

export interface TenantRow {
  selected: boolean;

  id: string;

  name: string;

  creationDate: Dayjs;
}
