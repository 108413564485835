import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Avatar } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  GridActionsCellItem,
  GridRenderCellParams,
  GridPaginationModel,
} from "@mui/x-data-grid-pro";
import { AccountCircle } from "@mui/icons-material";
import {
  AppSyncSearchQuery,
  AppSyncSearchResult,
} from "@commr/admin/models/common/appSyncSearch";
import { ResidentRow } from "@commr/admin/models/user/resident/resident";
import { setUserPicture } from "@commr/admin/utils/userUtils";
import CustomDataGrid from "../common/customDataGrid";

interface Props {
  searchResult?: AppSyncSearchResult;
  searchQuery: AppSyncSearchQuery;
  setSearchQuery: React.Dispatch<React.SetStateAction<AppSyncSearchQuery>>;
}

const ResidentHit: React.FC<React.PropsWithChildren<Props>> = ({
  searchResult,
  searchQuery,
  setSearchQuery,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [residentRows, setResidentRows] = useState<ResidentRow[]>([]);
  const setAvatar = (params: GridRenderCellParams) => (
    <Avatar src={params.value?.toString()} />
  );

  const residentsColumns: any = [
    {
      field: "avatar",
      headerName: "",
      flex: 0.1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<any>) => setAvatar(params),
      renderHeader: () => <AccountCircle />,
    },
    {
      field: "externalId",
      headerName: t("residents:filter.liviaId"),
      flex: 0.4,
    },
    {
      field: "lastName",
      headerName: t("residents:filter.lastName"),
      flex: 1,
    },
    {
      field: "firstName",
      headerName: t("residents:filter.firstName"),
      flex: 1,
    },
    {
      field: "email",
      headerName: t("residents:filter.email"),
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      flex: 0.3,
      align: "center",
      headerAlign: "center",
      getActions: (params: any) => [
        <GridActionsCellItem
          label={t("common:profile")}
          onClick={() =>
            history.push(
              `/tenant/${params.row.tenantId}/residency/${params.row.residencyId}/resident/${params.id}`
            )
          }
          showInMenu
        />,
      ],
    },
  ];

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: searchQuery.limit,
  });

  useEffect(() => {
    if (searchResult) {
      setResidentRows([]);
      searchResult.items?.map(async (hit) => {
        const avatar = await setUserPicture(hit.profilePicture);
        const resident: ResidentRow = {
          id: hit.id,
          avatar,
          externalId: hit.externalId,
          isMember: hit.cognitoId ? "true" : "false",
          lastName: hit.lastName,
          firstName: hit.firstName,
          email: hit.email,
          selected: false,
          creationDate: hit.creationDate,
          residencyId: hit.residencyId,
          tenantId: hit.tenantId,
        };
        setResidentRows((dataGridRows) => [...dataGridRows, resident]);
      });
    }
  }, [searchResult]);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
    setSearchQuery((old) => ({
      ...old,
      from: newPaginationModel.page * searchQuery.limit!,
    }));
  };

  const gridProps = {
    pagination: true,
    rowCount: searchResult?.total ?? 0,
    pageSizeOptions: [searchQuery.limit],
    rows: residentRows,
    columns: residentsColumns,
    paginationMode: "server",
    getRowId: (row: any) => row.externalId,
    onPaginationModelChange: handlePaginationModelChange,
    paginationModel,
    loading: !searchResult,
  };

  return (
    <div style={{ height: 600, width: "100%" }}>
      <CustomDataGrid attribute={gridProps} />
    </div>
  );
};

export default ResidentHit;

ResidentHit.defaultProps = {
  searchResult: undefined,
};
