import React, { useContext } from "react";
import { DataGridPro, frFR } from "@mui/x-data-grid-pro";
import { Language } from "@commr/admin/models/user/user";
import { TranslationContext } from "@commr/admin/context/translationContext";

interface Props {
  attribute: any;
}

const CustomDataGrid: React.FC<React.PropsWithChildren<Props>> = ({
  attribute,
}) => {
  const { language } = useContext(TranslationContext);

  return (
    <DataGridPro
      {...attribute}
      localeText={
        language === Language.French
          ? frFR.components.MuiDataGrid.defaultProps.localeText
          : undefined
      }
    />
  );
};

export default CustomDataGrid;
