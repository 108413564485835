import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GridActionsCellItem, GridPaginationModel } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import { TenantRow } from "@commr/admin/models/tenant";
import {
  AppSyncSearchQuery,
  AppSyncSearchResult,
} from "@commr/admin/models/common/appSyncSearch";
import CustomDataGrid from "../common/customDataGrid";

interface Props {
  searchResult?: AppSyncSearchResult;
  searchQuery: AppSyncSearchQuery;
  setSearchQuery: Dispatch<SetStateAction<AppSyncSearchQuery>>;
}

const TenantsHits: React.FC<React.PropsWithChildren<Props>> = ({
  searchResult,
  searchQuery,
  setSearchQuery,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [tenantRows, setTenantRows] = useState<TenantRow[]>([]);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: searchQuery.limit,
  });

  const getGridActionsCellItem = (params: any) => (
    <GridActionsCellItem
      label={t("common:actions.edit")}
      onClick={() => history.push(`/tenant/${params.id}`)}
      showInMenu
    />
  );

  const tenantsColumns: any = [
    {
      field: "id",
      headerName: t("common:id"),
      flex: 1,
    },
    {
      field: "name",
      headerName: t("common:name"),
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      getActions: (params: any) => [getGridActionsCellItem(params)],
    },
  ];

  useEffect(() => {
    setTenantRows([]);
    searchResult?.items?.map(async (hit) => {
      const tenant = {
        id: hit.id,
        name: hit.name,
        selected: false,
        creationDate: hit.creationDate,
      };
      setTenantRows((dataGridRows) => [...dataGridRows, tenant]);
    });
  }, [searchResult]);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
    setSearchQuery((old) => ({
      ...old,
      from: newPaginationModel.page * searchQuery.limit!,
    }));
  };

  const gridProps = {
    pagination: true,
    disableColumnMenu: true,
    disableSelectionOnClick: true,
    disableColumnSelector: true,
    loading: !searchResult,
    rows: tenantRows,
    columns: tenantsColumns,
    onPaginationModelChange: handlePaginationModelChange,
    paginationModel,
  };

  return (
    <div style={{ height: 700, width: "100%" }}>
      <CustomDataGrid attribute={gridProps} />
    </div>
  );
};

export default TenantsHits;

TenantsHits.defaultProps = {
  searchResult: undefined,
};
