import { Credentials } from "@commr/admin/models/tenant";
import { apiGet, getAuthorizationHeader } from "@commr/shared/utils/awsUtils";

const apiName = "liviaapi";

export const getLiviaTenantResidencies = async (credentials: Credentials) => {
  try {
    const path = `/residencies`;
    const response = await apiGet(
      path,
      await getAuthorizationHeader(null, {
        dns: credentials.dns,
        login: credentials.login,
        password: credentials.password,
      }),
      apiName
    );
    return response;
  } catch (error) {
    console.log("Error getLiviaTenantResidencies:", error);
    throw error;
  }
};

export const getLiviaResidentsByResidencyId = async (
  tenantId: string,
  liviaResidencyId: string
) => {
  try {
    const path = `/tenants/${tenantId}/residencies/${liviaResidencyId}`;
    const response = await apiGet(
      path,
      await getAuthorizationHeader(),
      apiName
    );

    return response;
  } catch (error) {
    console.log("Error getLiviaTenantResidencies:", error);
    throw error;
  }
};
