import { API, graphqlOperation } from "aws-amplify";
import {
  AppSyncSearchQuery,
  AppSyncSearchResult,
} from "@commr/admin/models/common/appSyncSearch";
import { Feed } from "@commr/admin/models/feed/feed";
import { Media } from "@commr/admin/models/common/media";
import {
  createNews,
  deleteNews,
  fillFeedData,
  fillFeedsData,
  getNews,
  searchNews,
  updateNews,
} from "@commr/admin/utils/feedUtils";
import { uploadUserMedia } from "./userService";

export const createFeed = async (feed: Feed, media?: Media) => {
  const newFeedData: any = {
    id: feed.id,
    residencyID: feed.residencyId,
    tenantID: feed.tenantId,
    authorID: feed.authorId,
    title: feed.title,
    content: feed.content,
    picture: feed.picture,
  };

  if (media) newFeedData["picture"] = await uploadUserMedia(media);

  await API.graphql(graphqlOperation(createNews, { input: newFeedData }));
};

export const updateFeed = async (feed: Feed, media?: Media) => {
  const updatedFeedData: any = {
    id: feed.id,
    residencyID: feed.residencyId,
    tenantID: feed.tenantId,
    authorID: feed.authorId,
    title: feed.title,
    content: feed.content,
  };

  if (media) updatedFeedData["picture"] = await uploadUserMedia(media);

  const updatedFeed = await API.graphql(
    graphqlOperation(updateNews, {
      input: updatedFeedData,
    })
  );
  return updatedFeed;
};

export const deleteFeed = async (id: string) => {
  const deleteFeedData = {
    id,
  };

  const removedFeeds = await API.graphql(
    graphqlOperation(deleteNews, {
      input: deleteFeedData,
    })
  );

  return removedFeeds;
};

export const getFeedsByResidencyId = async (
  searchQuery: AppSyncSearchQuery,
  residencyId: string
) => {
  const { query, limit, from } = searchQuery;
  const filter =
    query.length > 0 || ""
      ? {
          residencyID: { eq: residencyId },
          or: [
            { id: { matchPhrasePrefix: query } },

            { title: { matchPhrasePrefix: query } },

            { description: { matchPhrasePrefix: query } },
          ],
        }
      : { residencyID: { eq: residencyId } };

  const allSearchFeeds = (await API.graphql(
    graphqlOperation(searchNews, {
      filter,
      limit,
      from,
      sort: { direction: "desc", field: "createdAt" },
    })
  )) as {
    data: any;
  };

  const searchResult = allSearchFeeds?.data.searchNews;
  let result = new AppSyncSearchResult([], 0, 0);

  if (searchResult) {
    result = new AppSyncSearchResult(
      await fillFeedsData(allSearchFeeds.data),
      searchResult.total ?? 0,
      0
    );
  }

  return result;
};

export const getFeedById = async (
  id: string,
  residencyID: string,
  tenantID: string
) => {
  const feed = (await API.graphql(
    graphqlOperation(getNews, { id, residencyID, tenantID })
  )) as {
    data: any;
  };

  return fillFeedData(feed?.data?.getNews);
};
