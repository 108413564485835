import dayjs, { Dayjs } from "dayjs";

export class Residency {
  tenantId = "";

  address = "";

  name = "";

  email = "";

  creationDate: Dayjs = dayjs();

  isActive = false;

  externalId = "";
}

export class ResidencyForm extends Residency {}

export interface ResidencyRow {
  selected: boolean;

  externalId: string;

  name: string;

  email: string;

  address: string;

  creationDate: Dayjs;
}
