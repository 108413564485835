import React, { createContext, useState, useEffect, useContext } from "react";
import en from "dayjs/locale/en";
import fr from "dayjs/locale/fr";
import i18n from "i18next";
import { Language } from "@commr/admin/models/user/user";
import { defaultTranslationContext } from "@commr/admin/utils/contextUtils";
import dayjs from "dayjs";
import { UserContext } from "./userContext";

interface Props {
  children?: React.ReactNode;
}

export const TranslationContext = createContext(defaultTranslationContext);
const TranslationContextProvider: React.FC<Props> = ({ children }) => {
  const { user } = useContext(UserContext);
  const [language, setLanguage] = useState<Language>(user.language);
  const [locale, setLocale] = useState<string>(
    defaultTranslationContext.locale
  );

  useEffect(() => {
    switch (Number(user.language)) {
      case Language.English:
        i18n.changeLanguage("en");
        dayjs.locale(en);
        setLocale("en-En");
        break;
      case Language.French:
      default:
        i18n.changeLanguage("fr");
        setLocale("fr-Fr");
        dayjs.locale(fr);

        break;
    }
  }, [language, user]);

  return (
    <TranslationContext.Provider
      value={{
        language,
        locale,
        setLanguage,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

TranslationContextProvider.defaultProps = {
  children: undefined,
};

export default TranslationContextProvider;
