import { CircularProgress } from "@mui/material";
import { CircleMember } from "@commr/admin/models/user/circleMember/circleMember";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCircleMemberById } from "@commr/admin/services/circleMemberService";
import CircleMemberDataContainer from "@commr/admin/components/circleMembers/circleMemberDataContainer";
import { setUserPicture } from "@commr/admin/utils/userUtils";

const UpdateCircleMemberPage: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const [circleMember, setCircleMember] = useState<CircleMember>();
  const { circleMemberId, residentId } = useParams<{
    circleMemberId: string;
    residentId: string;
  }>();

  useEffect(() => {
    if (circleMemberId && residentId)
      getCircleMemberById(circleMemberId!, residentId).then(
        (memberResponse) => {
          setUserPicture(memberResponse.profilePicture).then((picture) => {
            memberResponse.profilePicture = picture;
            setCircleMember(memberResponse);
          });
        }
      );
  }, [circleMemberId]);

  if (!circleMember) return <CircularProgress size={60} />;
  return <CircleMemberDataContainer circleMember={circleMember} />;
};

export default UpdateCircleMemberPage;
