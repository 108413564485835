import { Role } from "@commr/admin/models/role/role";
import { API, graphqlOperation } from "aws-amplify";
import {
  AppSyncSearchQuery,
  AppSyncSearchResult,
} from "@commr/admin/models/common/appSyncSearch";
import {
  fillRolesData,
  fillRoleData,
  createRoleGql,
  updateRoleGql,
  searchRoles,
  getRole,
} from "@commr/admin/utils/roleUtils";

export const createRole = async (role: Role) =>
  API.graphql(
    graphqlOperation(createRoleGql, {
      input: { role: role.role },
    })
  );

export const updateRole = async (role: Role) => {
  const updatedRole = await API.graphql(
    graphqlOperation(updateRoleGql, {
      input: {
        id: role.id,
        role: role.role,
      },
    })
  );
  return updatedRole;
};

export const getRoles = async (searchQuery: AppSyncSearchQuery) => {
  const { query, limit, from } = searchQuery;
  let filter;
  if (query.length > 0 || "") {
    filter = {
      or: { role: { matchPhrasePrefix: query } },
    };
  }

  const searchRolesResult = (await API.graphql(
    graphqlOperation(searchRoles, {
      filter,
      limit,
      from,
      sort: { direction: "desc", field: "createdAt" },
    })
  )) as {
    data: any;
  };

  const searchResult = searchRolesResult?.data?.searchRoles;
  let result = new AppSyncSearchResult([], 0, 0);

  if (searchResult) {
    result = new AppSyncSearchResult(
      await fillRolesData(searchResult.items),
      searchResult.total ?? 0,
      0
    );
  }

  return result;
};

export const getRoleById = async (id: string) => {
  const role = (await API.graphql(graphqlOperation(getRole, { id }))) as {
    data: any;
  };
  return fillRoleData(role?.data?.getRole);
};
