import React from "react";
import { Grid } from "@mui/material";
import { Control, UseFormRegister } from "react-hook-form";
import { TenantForm } from "@commr/admin/models/tenant";
import TenantGeneralInfos from "./tenantGeneralInfos";
import TenantConnectionInfos from "./tenantConnectionInfos";

interface Props {
  control: Control<TenantForm, any>;
  isUpdate: boolean;
}

const TenantProfileInfosForm: React.FC<React.PropsWithChildren<Props>> = ({
  control,
  isUpdate,
}) => (
  <Grid item container xs={12} spacing={1}>
    <Grid item xs={12}>
      <TenantGeneralInfos isUpdate={isUpdate} control={control} />
    </Grid>

    <Grid item xs={12}>
      <TenantConnectionInfos control={control} />
    </Grid>
  </Grid>
);

export default TenantProfileInfosForm;
