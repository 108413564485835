import React from "react";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import LoginPage from "../users/loginPage";

const SignInRoutes: React.FC = () => (
  <BrowserRouter>
    <Redirect path="*" to="/login" />
    <Route path="/login" component={LoginPage} />
  </BrowserRouter>
);

export default SignInRoutes;
