import React from "react";
import { Grid } from "@mui/material";
import { Control } from "react-hook-form";
import { ResidencyForm } from "@commr/admin/models/residency/residency";
import ResidencyGeneralInfosForm from "./residencyGeneralInfosForm";

interface Props {
  control: Control<ResidencyForm, any>;
}

const ResidencyInfosForm: React.FC<React.PropsWithChildren<Props>> = ({
  control,
}) => (
  <Grid item container xs={12} spacing={1}>
    <Grid item xs={12}>
      <ResidencyGeneralInfosForm control={control} />
    </Grid>
  </Grid>
);

export default ResidencyInfosForm;
