import React, { useContext } from "react";
import { AppContext } from "@commr/admin/context/appContext";
import SideMenu from "./sideMenu";
import TopMenu from "./topMenu";

interface Props {
  title: string;
  children?: React.ReactNode;
}

const AppLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  title,
}) => {
  const { isSideMenuFold } = useContext(AppContext);

  return (
    <div className="app-layout">
      <SideMenu />
      <div className={`page-layout${isSideMenuFold ? " isFolded" : ""}`}>
        <TopMenu title={title} />
        <div className="page-container">{children}</div>
      </div>
    </div>
  );
};

AppLayout.defaultProps = {
  children: undefined,
};

export default AppLayout;
