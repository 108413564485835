import { createMapper, mapFrom } from "@automapper/core";
import { classes } from "@automapper/classes";
import {
  Residency,
  ResidencyForm,
} from "@commr/admin/models/residency/residency";

const ResidencyToResidencyForm = createMapper({
  name: "ResidencyToResidencyForm",
  pluginInitializer: classes,
});

ResidencyToResidencyForm.createMap(Residency, ResidencyForm)
  .forMember(
    (destination) => destination.name,
    mapFrom((source) => source.name)
  )
  .forMember(
    (destination) => destination.creationDate,
    mapFrom((source) => source.creationDate)
  )
  .forMember(
    (destination) => destination.address,
    mapFrom((source) => source.address)
  )
  .forMember(
    (destination) => destination.externalId,
    mapFrom((source) => source.externalId)
  )
  .forMember(
    (destination) => destination.tenantId,
    mapFrom((source) => source.tenantId)
  )
  .forMember(
    (destination) => destination.isActive,
    mapFrom((source) => source.isActive)
  );

export default ResidencyToResidencyForm;
