import React, { useContext, useEffect, useState } from "react";
import { Tenant } from "@commr/admin/models/tenant";
import { Residency } from "@commr/admin/models/residency/residency";
import { AppContext } from "@commr/admin/context/appContext";
import { t } from "i18next";
import SearchedUserResult from "@commr/admin/components/common/search/searchResult";
import ResidenciesHit from "@commr/admin/components/residencies/residenciesHit";
import { getLiviaTenantResidencies } from "../../services/liviaService";

interface Props {
  tenant: Tenant;
}

const ResidenciesContainer: React.FC<React.PropsWithChildren<Props>> = ({
  tenant,
}) => {
  const [residenciesHits, setResidenciesHits] = useState<Residency[]>();
  const { showToast } = useContext(AppContext);

  useEffect(() => {
    if (tenant)
      getLiviaTenantResidencies(tenant.credentials)
        .then((response: Residency[]) => {
          setResidenciesHits(response);
        })
        .catch((e) => {
          console.log("getLiviaTenantResidencies error", e);
          showToast(t("forms:errorMessages.apiConnection"), "error");
          setResidenciesHits([]);
        });
  }, [tenant]);

  return (
    <div className="tenants-container">
      <SearchedUserResult
        hitComponent={
          <ResidenciesHit tenantId={tenant.id} hits={residenciesHits} />
        }
      />
    </div>
  );
};

export default ResidenciesContainer;
