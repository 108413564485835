import RoutesData from "@commr/admin/models/common/iRoutesData";
import AdminProfilePage from "../../admins/adminProfilePage";
import AddAdminPage from "../../admins/addAdminPage";

const adminsRoutesData: RoutesData[] = [
  {
    path: "/tenant/:tenantId/residency/:residencyId/admins/addAdmin",
    component: AddAdminPage,
    title: "route:addAdmin",
  },
  {
    path: "/tenant/:tenantId/residency/:residencyId/admin/:adminId",
    component: AdminProfilePage,
    title: "route:adminProfile",
  },
];

export default adminsRoutesData;
