import { Assignment, Dashboard, PeopleAlt } from "@mui/icons-material";

export const adminSideMenuData = (tenantId: string, residencyId: string) => {
  return [
    {
      sectionName: "sideMenu:users.sectionName",
      links: [
        {
          linkName: "common:news",
          icon: Assignment,
          path: `/tenant/${tenantId}/residency/${residencyId}/feeds`,
        },
        {
          linkName: "sideMenu:users.residents",
          icon: PeopleAlt,
          path: `/tenant/${tenantId}/residency/${residencyId}/residents`,
        },
      ],
    },
  ];
};

export const superAdminSideMenuData = [
  {
    sectionName: "sideMenu:management.sectionName",
    links: [
      {
        linkName: "sideMenu:management.tenant",
        icon: Dashboard,
        path: "/tenants",
      },
      {
        linkName: "sideMenu:users.superAdmins",
        icon: PeopleAlt,
        path: "/superAdmins",
      },
      {
        linkName: "sideMenu:management.roles",
        icon: PeopleAlt,
        path: "/roles",
      },
    ],
  },
];
