import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GridToolbarFilterButton } from "@mui/x-data-grid-pro";
import { LiviaResident } from "@commr/admin/models/user/resident/resident";
import CustomDataGrid from "@commr/admin/components/common/customDataGrid";

interface Props {
  liviaResidents?: LiviaResident[];
  setCurrentLiviaResident: Dispatch<SetStateAction<LiviaResident>>;
}

const LiviaResidentsHits: React.FC<React.PropsWithChildren<Props>> = ({
  liviaResidents,
  setCurrentLiviaResident,
}) => {
  const { t } = useTranslation();
  const [residentRows, setResidentRows] = useState<LiviaResident[]>([]);
  const [pageSize, setPageSize] = React.useState(10);

  const residentsColumns: any = [
    {
      field: "externalID",
      headerName: t("common:id"),
      flex: 1,
    },
    {
      field: "lastName",
      headerName: t("residents:filter.lastName"),
      flex: 1,
    },
    {
      field: "firstName",
      headerName: t("residents:filter.firstName"),
      flex: 1,
    },
    {
      field: "birthDate",
      headerName: t("common:birthDate"),
      flex: 1,
    },
    {
      field: "email",
      headerName: t("residents:filter.email"),
      flex: 1,
    },
  ];

  useEffect(() => {
    if (liviaResidents) setResidentRows(liviaResidents);
  }, [liviaResidents]);

  const gridProps = {
    pagination: true,
    pageSizeOptions: [],
    slots: {
      toolbar: GridToolbarFilterButton,
    },
    rows: residentRows,
    columns: residentsColumns,
    onPageSizeChange: (newPageSize: number) => setPageSize(newPageSize),
    getRowId: (row: any) => row.externalID,
    loading: !liviaResidents,
    onRowClick: (value: any) => setCurrentLiviaResident(value.row),
    initialState: {
      pagination: { paginationModel: { pageSize: 20 } },
      sorting: {
        sortModel: [{ field: "externalID", sort: "asc" }],
      },
    },
  };

  return (
    <div style={{ height: 600, width: "100%" }}>
      <CustomDataGrid attribute={gridProps} />
    </div>
  );
};

export default LiviaResidentsHits;

LiviaResidentsHits.defaultProps = {
  liviaResidents: undefined,
};
