const schema = {
  models: {
    Message: {
      name: "Message",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        authorId: {
          name: "authorId",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        content: {
          name: "content",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        isSent: {
          name: "isSent",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        conversation: {
          name: "conversation",
          isArray: false,
          type: {
            model: "Conversation",
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "conversationId",
          },
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Messages",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "searchable",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byConversation",
            queryField: "messagesByConversation",
            fields: ["conversationId", "createdAt"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Conversation: {
      name: "Conversation",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        messages: {
          name: "messages",
          isArray: true,
          type: {
            model: "Message",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "conversation",
          },
        },
        calls: {
          name: "calls",
          isArray: true,
          type: {
            model: "Call",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "conversation",
          },
        },
        participants: {
          name: "participants",
          isArray: true,
          type: "ID",
          isRequired: true,
          attributes: [],
          isArrayNullable: false,
        },
        isFamilyConversation: {
          name: "isFamilyConversation",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Conversations",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Call: {
      name: "Call",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        authorId: {
          name: "authorId",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        isSent: {
          name: "isSent",
          isArray: false,
          type: "Boolean",
          isRequired: false,
          attributes: [],
        },
        history: {
          name: "history",
          isArray: false,
          type: {
            nonModel: "CallHistory",
          },
          isRequired: false,
          attributes: [],
        },
        status: {
          name: "status",
          isArray: false,
          type: {
            enum: "CallStatus",
          },
          isRequired: true,
          attributes: [],
        },
        roomId: {
          name: "roomId",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        endedAt: {
          name: "endedAt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        conversation: {
          name: "conversation",
          isArray: false,
          type: {
            model: "Conversation",
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: "BELONGS_TO",
            targetName: "conversationId",
          },
        },
      },
      syncable: true,
      pluralName: "Calls",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byStatus",
            fields: ["status", "updatedAt"],
          },
        },
        {
          type: "key",
          properties: {
            name: "byRoomId",
            queryField: "callByRoomId",
            fields: ["roomId", "createdAt"],
          },
        },
        {
          type: "key",
          properties: {
            name: "byConversation",
            queryField: "callByConversation",
            fields: ["conversationId", "createdAt"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    UserConversations: {
      name: "UserConversations",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        associated: {
          name: "associated",
          isArray: true,
          type: {
            model: "UserConversations",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "userConversationsAssociatedId",
          },
        },
        conversation: {
          name: "conversation",
          isArray: false,
          type: {
            model: "Conversation",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "HAS_ONE",
            associatedWith: "id",
            targetName: "userConversationsConversationId",
          },
        },
        userId: {
          name: "userId",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        userConversationsAssociatedId: {
          name: "userConversationsAssociatedId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        userConversationsConversationId: {
          name: "userConversationsConversationId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        userConversationsConnectionUserConversationsId: {
          name: "userConversationsConnectionUserConversationsId",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
      },
      syncable: true,
      pluralName: "UserConversations",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    UserConversationsConnection: {
      name: "UserConversationsConnection",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        nextToken: {
          name: "nextToken",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        userConversations: {
          name: "userConversations",
          isArray: true,
          type: {
            model: "UserConversations",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "userConversationsConnectionUserConversationsId",
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "UserConversationsConnections",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Residency: {
      name: "Residency",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        tenantID: {
          name: "tenantID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        Residents: {
          name: "Residents",
          isArray: true,
          type: {
            model: "Resident",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "residencyID",
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Residencies",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byTenant",
            fields: ["tenantID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Resident: {
      name: "Resident",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        firstName: {
          name: "firstName",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        lastName: {
          name: "lastName",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        email: {
          name: "email",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        conversationsConnectionId: {
          name: "conversationsConnectionId",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        conversations: {
          name: "conversations",
          isArray: false,
          type: {
            model: "UserConversationsConnection",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "HAS_ONE",
            associatedWith: "id",
            targetName: "conversationsConnectionId",
          },
        },
        residencyID: {
          name: "residencyID",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        birthDate: {
          name: "birthDate",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
        address: {
          name: "address",
          isArray: false,
          type: "AWSJSON",
          isRequired: false,
          attributes: [],
        },
        professionals: {
          name: "professionals",
          isArray: true,
          type: {
            model: "ResidentsProfessionals",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "resident",
          },
        },
        circleMembers: {
          name: "circleMembers",
          isArray: true,
          type: {
            model: "ResidentCircleMemberRelation",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "resident",
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Residents",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            name: "byResidency",
            fields: ["residencyID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    ResidentsProfessionals: {
      name: "ResidentsProfessionals",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        professional: {
          name: "professional",
          isArray: false,
          type: {
            model: "Professional",
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: "HAS_ONE",
            associatedWith: "residents",
            targetName: "professionalID",
          },
        },
        professionalID: {
          name: "professionalID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        resident: {
          name: "resident",
          isArray: false,
          type: {
            model: "Resident",
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: "HAS_ONE",
            associatedWith: "professionals",
            targetName: "residentID",
          },
        },
        residentID: {
          name: "residentID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "ResidentsProfessionals",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            fields: ["professionalID", "residentID"],
          },
        },
        {
          type: "key",
          properties: {
            name: "byProfessional",
            fields: ["professionalID"],
          },
        },
        {
          type: "key",
          properties: {
            name: "byResident",
            fields: ["residentID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Professional: {
      name: "Professional",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        firstName: {
          name: "firstName",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        lastName: {
          name: "lastName",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        email: {
          name: "email",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        conversationsConnectionId: {
          name: "conversationsConnectionId",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        conversations: {
          name: "conversations",
          isArray: false,
          type: {
            model: "UserConversationsConnection",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "HAS_ONE",
            associatedWith: "id",
            targetName: "conversationsConnectionId",
          },
        },
        residents: {
          name: "residents",
          isArray: true,
          type: {
            model: "ResidentsProfessionals",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "professional",
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Professionals",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    ResidentCircleMemberRelation: {
      name: "ResidentCircleMemberRelation",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        circleMember: {
          name: "circleMember",
          isArray: false,
          type: {
            model: "CircleMember",
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: "HAS_ONE",
            associatedWith: "residents",
            targetName: "circleMemberID",
          },
        },
        circleMemberID: {
          name: "circleMemberID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        relation: {
          name: "relation",
          isArray: false,
          type: {
            enum: "Relation",
          },
          isRequired: true,
          attributes: [],
        },
        resident: {
          name: "resident",
          isArray: false,
          type: {
            model: "Resident",
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: "HAS_ONE",
            associatedWith: "circleMembers",
            targetName: "residentID",
          },
        },
        residentID: {
          name: "residentID",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "ResidentCircleMemberRelations",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "key",
          properties: {
            fields: ["circleMemberID", "residentID"],
          },
        },
        {
          type: "key",
          properties: {
            name: "byCircleMember",
            fields: ["circleMemberID"],
          },
        },
        {
          type: "key",
          properties: {
            name: "byResident",
            fields: ["residentID"],
          },
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    CircleMember: {
      name: "CircleMember",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        firstName: {
          name: "firstName",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        lastName: {
          name: "lastName",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        email: {
          name: "email",
          isArray: false,
          type: "String",
          isRequired: true,
          attributes: [],
        },
        conversationsConnectionId: {
          name: "conversationsConnectionId",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        conversations: {
          name: "conversations",
          isArray: false,
          type: {
            model: "UserConversationsConnection",
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: "HAS_ONE",
            associatedWith: "id",
            targetName: "conversationsConnectionId",
          },
        },
        residents: {
          name: "residents",
          isArray: true,
          type: {
            model: "ResidentCircleMemberRelation",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "circleMember",
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "CircleMembers",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
    Tenant: {
      name: "Tenant",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: true,
          attributes: [],
        },
        Residencies: {
          name: "Residencies",
          isArray: true,
          type: {
            model: "Residency",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
          association: {
            connectionType: "HAS_MANY",
            associatedWith: "tenantID",
          },
        },
        createdAt: {
          name: "createdAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "AWSDateTime",
          isRequired: false,
          attributes: [],
          isReadOnly: true,
        },
      },
      syncable: true,
      pluralName: "Tenants",
      attributes: [
        {
          type: "model",
          properties: {},
        },
        {
          type: "auth",
          properties: {
            rules: [
              {
                allow: "private",
                operations: ["create", "update", "delete", "read"],
              },
            ],
          },
        },
      ],
    },
  },
  enums: {
    CallStatus: {
      name: "CallStatus",
      values: [
        "initiated",
        "ongoing",
        "rejected",
        "busy",
        "unanswered",
        "cancelled",
        "ended",
      ],
    },
    Relation: {
      name: "Relation",
      values: [
        "Self",
        "Unknown",
        "Husband",
        "Wife",
        "Spouse",
        "Son",
        "Daughter",
        "Brother",
        "Sister",
        "Cousin",
        "Uncle",
        "Aunt",
        "Father",
        "Mother",
        "SonInLaw",
        "DaughterInLaw",
        "Grandson",
        "Granddaughter",
        "Friend",
      ],
    },
    UserType: {
      name: "UserType",
      values: ["Professional", "Resident", "CircleMember"],
    },
  },
  nonModels: {
    CallHistory: {
      name: "CallHistory",
      fields: {
        decliners: {
          name: "decliners",
          isArray: true,
          type: {
            nonModel: "UserHistory",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
        joiners: {
          name: "joiners",
          isArray: true,
          type: {
            nonModel: "UserHistory",
          },
          isRequired: false,
          attributes: [],
          isArrayNullable: true,
        },
      },
    },
    UserHistory: {
      name: "UserHistory",
      fields: {
        id: {
          name: "id",
          isArray: false,
          type: "ID",
          isRequired: false,
          attributes: [],
        },
        updatedAt: {
          name: "updatedAt",
          isArray: false,
          type: "String",
          isRequired: false,
          attributes: [],
        },
      },
    },
  },
  version: "d855d80d21c5b17ad44303567dd7adfd",
};

export default schema;
