import { Residency } from "@commr/admin/models/residency/residency";
import {
  AppContext,
  ResidencyContext,
  TranslationContext,
  UserContext,
} from "@commr/admin/models/common/iContext";
import { Language } from "@commr/admin/models/user/user";
import { Admin } from "@commr/admin/models/user/admin/admin";
import emptySnackbarMessage from "./snackbarUtils";

export const defaultAppContext: AppContext = {
  isSideMenuFold: false,
  toast: emptySnackbarMessage,
  setIsSideMenuFold: () => ({}),
  showToast: (
    errorMessage: string,
    severity: "error" | "success" | "info" | "warning"
  ) => {
    console.log(severity, errorMessage);
  },
  environment: {},
};

export const defaultUserContext: UserContext = {
  user: new Admin(),
  setUser: () => ({}),
};

export const defaultTranslationContext: TranslationContext = {
  language: Language.French,
  locale: "fr-FR",
  setLanguage: () => Language,
};

export const defaultResidencyContext: ResidencyContext = {
  residency: new Residency(),
  setResidency: () => ({}),
};
