import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getResidentById } from "@commr/admin/services/residentService";
import { Resident } from "@commr/admin/models/user/resident/resident";
import LoadingScreen from "@commr/admin/components/common/layout/loadingScreen";
import { setUserPicture } from "@commr/admin/utils/userUtils";
import ResidentDataContainer from "../../components/residents/residentDataContainer";

const ResidentProfilePage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [resident, setResident] = useState<Resident>();
  const { tenantId, residencyId, residentId } = useParams<{
    tenantId: string;
    residencyId: string;
    residentId: string;
  }>();

  useEffect(() => {
    if (tenantId && residencyId && residentId)
      getResidentById(tenantId, residencyId, residentId).then(
        (residentResponse) => {
          setUserPicture(residentResponse.profilePicture).then((picture) => {
            residentResponse.profilePicture = picture;
            setResident(residentResponse);
          });
        }
      );
  }, [residentId]);

  if (!resident) return <LoadingScreen />;
  return <ResidentDataContainer resident={resident} />;
};

export default ResidentProfilePage;
