import { Divider, Grid, Paper, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import ControlledInput from "../../common/formFields/controlledInput";

interface Props {
  control: Control<any, any>;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
}

const TenantConnectionInfos: React.FC<React.PropsWithChildren<Props>> = ({
  control,
  setIsDirty,
}) => {
  const { t } = useTranslation();

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

  const handleChange = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    initialValue: string
  ) => {
    if (setIsDirty && e.target.value !== initialValue) setIsDirty(true);
  };

  return (
    <Item>
      <Grid item xs={12} sx={{ paddingBottom: 2 }}>
        <Typography variant="h5">{t("userForm:loginInfo")}</Typography>
        <Divider variant="fullWidth" />
      </Grid>

      <Grid item container spacing={1} xs={12}>
        <Grid item xs={4}>
          <ControlledInput
            controllerProps={{
              name: "credentials.dns",
              control,
            }}
            label={t("common:dns")}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledInput
            controllerProps={{
              name: "credentials.login",
              control,
            }}
            label={t("common:login")}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={4}>
          <ControlledInput
            name="credentials.password"
            controllerProps={{
              name: "credentials.password",
              control,
            }}
            label={t("common:password")}
            type="password"
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
    </Item>
  );
};

TenantConnectionInfos.defaultProps = {
  setIsDirty: undefined,
};
export default TenantConnectionInfos;
