import { Tenant } from "@commr/admin/models/tenant";

export const fillTenantData = (tenantResult: any) => {
  const tenant = new Tenant();
  if (tenantResult) {
    tenant.id = tenantResult.id;
    tenant.name = tenantResult.name;
    tenant.credentials = tenantResult.credentials;
  }

  return tenant;
};

export const fillTenantsData = async (tenantsResult: any) => {
  const tenants: Tenant[] = [];

  if (tenantsResult) {
    tenantsResult.forEach((item: Tenant) => {
      tenants.push(fillTenantData(item));
    });
  }
  return tenants;
};

export const createTenantGql = /* GraphQL */ `
  mutation CreateTenant(
    $input: CreateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    createTenant(input: $input, condition: $condition) {
      id
      Residencies {
        items {
          externalID
          tenantID
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      address {
        civicNumber
        street
        postalCode
        province
        city
        country
      }
      credentials {
        dns
        login
        password
      }
      createdAt
      updatedAt
    }
  }
`;

export const searchTenants = /* GraphQL */ `
  query SearchTenants(
    $filter: SearchableTenantFilterInput
    $sort: [SearchableTenantSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTenantAggregationInput]
  ) {
    searchTenants(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        Residencies {
          nextToken
        }
        name
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        credentials {
          dns
          login
          password
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const updateTenantGql = /* GraphQL */ `
  mutation UpdateTenant(
    $input: UpdateTenantInput!
    $condition: ModelTenantConditionInput
  ) {
    updateTenant(input: $input, condition: $condition) {
      id
      Residencies {
        items {
          externalID
          tenantID
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      address {
        civicNumber
        street
        postalCode
        province
        city
        country
      }
      credentials {
        dns
        login
        password
      }
      createdAt
      updatedAt
    }
  }
`;

export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      Residencies {
        items {
          externalID
          tenantID
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      address {
        civicNumber
        street
        postalCode
        province
        city
        country
      }
      credentials {
        dns
        login
        password
      }
      createdAt
      updatedAt
    }
  }
`;
