import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { API, Auth } from "aws-amplify";

export const adminCreateUser = async (
  email: string,
  password: string,
  groupname: string,
  isConfirmed: boolean
) => {
  const apiName = "CustomAdminQueries";
  const path = "/createUser";
  const myInit = {
    body: {
      username: email,
      password,
      groupname,
      isConfirmed,
      email,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };
  return API.post(apiName, path, myInit)
    .then((response) => response.User)
    .catch((e) => {
      throw new Error(e.response?.data?.code);
    });
};

export const adminConfirmSignUp = async (email: string) => {
  const apiName = "CustomAdminQueries";
  const path = "/confirmUserSignUp";
  const myInit = {
    body: {
      username: email,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };
  const res = await API.post(apiName, path, myInit);
  return res;
};

export const getCurrentAuthenticatedUser = () =>
  Auth.currentAuthenticatedUser({
    bypassCache: false,
  })
    .then((user) => user)
    .catch((err) => console.log(err));

export const getSession = () => Auth.currentSession();

export const signInWithEmail = async (username: string, password: string) => {
  await Auth.signIn(username, password);
};

export const signOut = async () => {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
};

export const getAttributes = async () =>
  new Promise((resolve) => {
    getCurrentAuthenticatedUser().then((user: any) => {
      resolve({
        ...(user?.attributes as CognitoUserAttribute[]),
        roles: user?.signInUserSession?.accessToken?.payload["cognito:groups"],
      });
    });
  }).catch((err) => {
    throw err;
  });

export const setAttribute = (attribute: any) =>
  new Promise((resolve, reject) => {
    const res = Auth.updateUserAttributes(
      getCurrentAuthenticatedUser(),
      attribute
    );

    getCurrentAuthenticatedUser().then((user: any) => {
      const attributeList = [];
      attributeList.push(res);
      user.attributes(attributeList, (err: any, result: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }).catch((err) => {
    throw err;
  });

/**
 *
 * @param username
 */
export const forgotPassword = async (username: string) =>
  Auth.forgotPassword(username);

export const verifyCode = async (
  username: string,
  new_password: string,
  code: string
) =>
  Auth.forgotPasswordSubmit(username, code, new_password)
    .then((data) => console.log(data))
    .catch((err) => console.log(err));

/**
 *
 * @param oldPassword
 * @param newPassword
 */
export async function changePassword(oldPassword: string, newPassword: string) {
  return new Promise((resolve, reject) => {
    getCurrentAuthenticatedUser().then((current: any) => {
      current.changePassword(oldPassword, newPassword, (err: any, res: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
    });
  });
}
