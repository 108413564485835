import { Residency } from "@commr/admin/models/residency/residency";

export const fillResidencyData = (residencyResult: any) => {
  const residency = new Residency();
  if (residencyResult) {
    residency.tenantId = residencyResult.tenantID;
    residency.externalId = residencyResult.externalID;

    const { street } = residencyResult.address;
    residency.address = `${street}`;
    residency.name = residencyResult.name;
  }

  return residency;
};

export const fillResidenciesData = async (residenciesResult: any) => {
  const residencies: Residency[] = [];
  const items = residenciesResult?.items;

  if (items) {
    items.forEach((item: any) => {
      residencies.push(fillResidencyData(item));
    });
  }
  return residencies;
};

export const createResidencyGql = /* GraphQL */ `
  mutation CreateResidency(
    $input: CreateResidencyInput!
    $condition: ModelResidencyConditionInput
  ) {
    createResidency(input: $input, condition: $condition) {
      externalID
      tenantID
      tenant {
        id
        Residencies {
          nextToken
        }
        name
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        credentials {
          dns
          login
          password
        }
        createdAt
        updatedAt
      }
      residents {
        items {
          cognitoID
          externalID
          firstName
          lastName
          email
          profilePicture
          conversationsConnectionID
          residencyID
          tenantID
          sex
          birthDate
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      address {
        civicNumber
        street
        postalCode
        province
        city
        country
      }
      createdAt
      updatedAt
    }
  }
`;

export const getResidency = /* GraphQL */ `
  query GetResidency($externalID: ID!, $tenantID: ID!) {
    getResidency(externalID: $externalID, tenantID: $tenantID) {
      externalID
      tenantID
      tenant {
        id
        Residencies {
          nextToken
        }
        name
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        credentials {
          dns
          login
          password
        }
        createdAt
        updatedAt
      }
      residents {
        items {
          cognitoID
          externalID
          firstName
          lastName
          email
          profilePicture
          conversationsConnectionID
          residencyID
          tenantID
          sex
          birthDate
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      address {
        civicNumber
        street
        postalCode
        province
        city
        country
      }
      createdAt
      updatedAt
    }
  }
`;

export const searchResidencies = /* GraphQL */ `
  query SearchResidencies(
    $filter: SearchableResidencyFilterInput
    $sort: [SearchableResidencySortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableResidencyAggregationInput]
  ) {
    searchResidencies(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        externalID
        tenantID
        tenant {
          id
          name
          createdAt
          updatedAt
        }
        residents {
          nextToken
        }
        name
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
