import { createMapper, mapFrom } from "@automapper/core";
import { classes } from "@automapper/classes";
import { User, UserForm } from "@commr/admin/models/user/user";
import {
  CircleMemberForm,
  CircleMember,
} from "@commr/admin/models/user/circleMember/circleMember";

const CircleMemberUserToCircleMemberForm = createMapper({
  name: "CircleMemberUserToCircleMemberForm",
  pluginInitializer: classes,
});

CircleMemberUserToCircleMemberForm.createMap(CircleMember, User);
CircleMemberUserToCircleMemberForm.createMap(UserForm, User, {
  extends: [CircleMemberUserToCircleMemberForm.getMapping(CircleMember, User)],
})
  .forMember(
    (destination) => destination.address,
    mapFrom((source) => source.address)
  )
  .forMember(
    (destination) => destination.sex,
    mapFrom((source) => source.sex)
  );

CircleMemberUserToCircleMemberForm.createMap(CircleMember, CircleMemberForm, {
  extends: [CircleMemberUserToCircleMemberForm.getMapping(UserForm, User)],
}).forMember(
  (destination) => destination.relation,
  mapFrom((source) => source.relation)
);

export default CircleMemberUserToCircleMemberForm;
