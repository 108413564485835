import { DatePicker } from "@mui/x-date-pickers";
import { TextField, TextFieldProps } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useController, UseControllerProps } from "react-hook-form";

interface Props {
  controllerProps: UseControllerProps<any>;
  label: string;
  isDisabled?: boolean;
}

const ControlledDatePicker: React.FC<React.PropsWithChildren<Props>> = ({
  controllerProps,
  label,
  isDisabled,
}) => {
  const { field, fieldState } = useController(controllerProps);

  return (
    <DatePicker
      disabled={isDisabled}
      label={label}
      renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
        <TextField
          required={!!controllerProps.rules?.required}
          disabled={isDisabled}
          fullWidth
          {...params}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
        />
      )}
      onChange={(day) => field.onChange(day?.format("MM-DD-YYYY"))}
      value={field.value}
      ref={field.ref}
    />
  );
};

ControlledDatePicker.defaultProps = {
  isDisabled: undefined,
};

export default ControlledDatePicker;
