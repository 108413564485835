import React, { createContext, useState, useEffect, useContext } from "react";
import { getAdminByCognitoId } from "@commr/admin/services/adminService";
import { defaultUserContext } from "@commr/admin/utils/contextUtils";
import { AuthContext, AuthStatus } from "./authContext";

export const UserContext = createContext(defaultUserContext);

interface Props {
  children?: React.ReactNode;
}

const UserContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  const [user, setUser] = useState(defaultUserContext.user);
  const { authStatus, attrInfo } = useContext(AuthContext);

  useEffect(() => {
    if (attrInfo && authStatus === AuthStatus.SignedIn) {
      const roles = attrInfo?.roles;
      if (roles && (roles[0] === "Admins" || roles[0] === "SuperAdmins")) {
        getAdminByCognitoId(attrInfo.sub).then((res) => {
          setUser(res);
        });
      } else {
        console.log("logOut");
      }
    }
  }, [authStatus, attrInfo]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserContextProvider.defaultProps = {
  children: undefined,
};

export default UserContextProvider;
