import { createMapper, mapFrom } from "@automapper/core";
import { classes } from "@automapper/classes";
import { Tenant, TenantForm } from "@commr/admin/models/tenant";

const TenantToTenantForm = createMapper({
  name: "TenantToTenantForm",
  pluginInitializer: classes,
});

TenantToTenantForm.createMap(Tenant, TenantForm)
  .forMember(
    (destination) => destination.name,
    mapFrom((source) => source.name)
  )
  .forMember(
    (destination) => destination.credentials,
    mapFrom((source) => source.credentials)
  )
  .forMember(
    (destination) => destination.creationDate,
    mapFrom((source) => source.creationDate)
  );

export default TenantToTenantForm;
