import React, { useState } from "react";
import i18n from "i18next";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Amplify from "aws-amplify";
import Toast from "@commr/admin/components/common/toasts/toast";
import ContextProviders from "@commr/admin/context/contextProviders";
import MainRoutes from "@commr/admin/pages/routes/mainRoutes";
import "../styles/app.scss";
import AuthProvider, {
  AuthIsNotSignedIn,
  AuthIsSignedIn,
} from "@commr/admin/context/authContext";
import SignInRoutes from "@commr/admin/pages/routes/signInRoutes";
import "@commr/admin/i18n";
import { LicenseInfo } from "@mui/x-license-pro";
import { environment } from "../environments/environment";
import appRoutesData from "../routes/appRoutesData";

LicenseInfo.setLicenseKey(
  "9141d70c6e98e7d1bc83b61003d3f1fbTz02Mjg4OCxFPTE3MTE1NjA4OTc5MDcsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const region = environment.aws_region;
const myAppConfig = {
  aws_project_region: region,
  aws_cognito_identity_pool_id: environment.aws_cognito_identity_pool_id,
  aws_cognito_region: region,
  aws_user_pools_id: environment.aws_user_pools_id,
  aws_user_pools_web_client_id: environment.aws_user_pools_web_client_id,
  oauth: {},
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      "REQUIRES_LOWERCASE",
      "REQUIRES_UPPERCASE",
      "REQUIRES_NUMBERS",
      "REQUIRES_SYMBOLS",
    ],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_appsync_graphqlEndpoint: environment.aws_appsync_graphql_end_point,
  aws_appsync_region: region,
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",

  aws_cloud_logic_custom: [
    {
      name: "CustomAdminQueries",
      endpoint: environment.aws_api_custom_admin_queries_end_point,
      region,
    },
    {
      name: "liviaapi",
      endpoint: environment.aws_api_custom_livia_end_point,
      region,
    },
  ],
  aws_user_files_s3_bucket: environment.aws_s3_bucket,
  aws_user_files_s3_bucket_region: region,
};

Amplify.configure(myAppConfig);

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  i18n.on("initialized", () => {
    setIsLoading(false);
  });

  const theme = createTheme({
    palette: {
      primary: {
        main: "#776dfe",
        dark: "#08016b",
      },
      background: {
        default: "#776dfe15",
        paper: "#f0f5f7",
      },
      success: {
        main: "#22fa06c0",
      },
      error: {
        main: "#fa0606b9",
      },
      warning: {
        main: "#fac106b9",
      },
    },
  });

  if (isLoading) return null;
  return (
    <div className="app">
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <ContextProviders environment={environment}>
              <Toast />
              <AuthIsSignedIn>
                <MainRoutes appRoutes={appRoutesData} />
              </AuthIsSignedIn>
              <AuthIsNotSignedIn>
                <SignInRoutes />
              </AuthIsNotSignedIn>
            </ContextProviders>
          </ThemeProvider>
        </LocalizationProvider>
      </AuthProvider>
    </div>
  );
};

export default App;
