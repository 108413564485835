import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const ns = [
  "common",
  "dashboard",
  "familyMember",
  "feeds",
  "forms",
  "login",
  "mail",
  "messages",
  "profile",
  "registrationPage",
  "residency",
  "residents",
  "residentStatus",
  "route",
  "sideMenu",
  "statistics",
  "surveys",
  "userForm",
  "professionals",
  "circleMembers",
];
const languages = ["en", "fr"];

const getResources = async () => {
  const resources: { [k: string]: any } = {};
  return Promise.all(
    languages.map(async (language) => {
      resources[language] = {};
      return Promise.all(
        ns.map(async (n) => {
          await import(`./${language}/${n}.json`).then((imp) => {
            resources[language][n] = imp;
          });
        })
      );
    })
  ).then(() => resources);
};

const initI18N = async () => {
  const resources = await getResources();
  i18n.use(initReactI18next).init({
    resources,
    lng: "fr",
    fallbackLng: "en",
    ns,
    interpolation: { escapeValue: false },
    saveMissing: true,
    debug: false,
  });
};

initI18N();
