import { ToastMessage } from "@commr/admin/models/common/iToastMessage";

const emptySnackbarMessage: ToastMessage = {
  message: "",
  onClose: () => ({}),
  open: false,
  severity: "info",
};

export default emptySnackbarMessage;
