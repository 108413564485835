import { Box, Button, Tooltip } from "@mui/material";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { AppContext } from "@commr/admin/context/appContext";
import { UserContext } from "@commr/admin/context/userContext";
import { adminSideMenuData, superAdminSideMenuData } from "./data/sideMenuData";

const SideMenu: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation();
  const { isSideMenuFold, setIsSideMenuFold, environment } =
    useContext(AppContext);
  const { user } = useContext(UserContext);
  const sideMenuData = user.isSuper
    ? superAdminSideMenuData
    : adminSideMenuData(user.tenantID, user.residencyID!);

  return (
    <Box
      sx={{ flexShrink: 10, alignSelf: "flex-end", bottom: 0 }}
      className={`side-menu-container${isSideMenuFold ? " isFolded" : ""}`}
    >
      <div className="side-menu-appTitle">
        <h1 data-testid="side-menu-appName">{environment.appName}</h1>
        <span data-testid="side-menu-subtitle" className="side-menu-subTitle">
          {t("common:adminPanel")}
        </span>
        <span
          className="side-menu-subTitle"
          data-testid="side-menu-build-version"
        >
          {environment.buildVersion}
        </span>
      </div>
      {sideMenuData.map((section, i) => (
        <div key={`${section.sectionName}`} className="side-menu-section">
          <div className="side-menu-section-name">
            {t("sideMenu:management.sectionName")}
          </div>
          <div className="side-menu-links-container">
            {section.links.map((link) => {
              if (isSideMenuFold) {
                return (
                  <Tooltip title={t(link.linkName)} placement="right">
                    <Link
                      key={`sidemenu_link_${link.linkName}`}
                      to={link.path}
                      className="side-menu-link"
                    >
                      <link.icon />
                    </Link>
                  </Tooltip>
                );
              }
              return (
                <Link
                  key={`sidemenu_link_${link.linkName}`}
                  to={link.path}
                  className="side-menu-link"
                >
                  <link.icon />
                  {t(link.linkName)}
                </Link>
              );
            })}
          </div>
        </div>
      ))}

      <Box
        sx={{
          flex: 1,
          flexShrink: 1,
          alignSelf: "flex-end",
        }}
      >
        <Button
          data-testid="fold-side-menu-button"
          color="primary"
          onClick={() => setIsSideMenuFold(!isSideMenuFold)}
        >
          {isSideMenuFold ? (
            <ArrowForwardIos data-testid="arrow-forward-icon" />
          ) : (
            <ArrowBackIosNew data-testid="arrow-back-icon" />
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default SideMenu;
