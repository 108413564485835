import { Divider, Grid, Paper, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import ControlledInput from "../../common/formFields/controlledInput";

interface Props {
  control: Control<any, any>;
  isUpdate: boolean;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserConnectionInfos: React.FC<React.PropsWithChildren<Props>> = ({
  control,
  isUpdate,
  setIsDirty,
}) => {
  const { t } = useTranslation();

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

  const handleChange = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    initialValue: string
  ) => {
    if (setIsDirty && e.target.value !== initialValue) setIsDirty(true);
  };

  return (
    <Item>
      <Grid item xs={12} sx={{ paddingBottom: 2 }}>
        <Typography variant="h5">{t("userForm:loginInfo")}</Typography>
        <Divider variant="fullWidth" />
      </Grid>

      <Grid item container spacing={1}>
        <Grid item xs={5}>
          <ControlledInput
            controllerProps={{
              name: "email",
              control,
              rules: {
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: `${t("forms:errorMessages.invalidEmail")}`,
                },

                required: {
                  value: !isUpdate,
                  message: `${t("forms:errorMessages.required")}`,
                },
              },
            }}
            name="email"
            label={t("common:email")}
            isDisabled={isUpdate}
            handleChange={handleChange}
          />
        </Grid>
        {!isUpdate && (
          <Grid item xs={5}>
            <ControlledInput
              name="password"
              controllerProps={{
                name: "password",
                defaultValue: "Test1234!",
                control,
                rules: {
                  required: {
                    value: !isUpdate,
                    message: `${t("forms:errorMessages.required")}`,
                  },
                  minLength: {
                    value: 6,
                    message: `${t("forms:errorMessages.minLength", {
                      value: 6,
                    })}`,
                  },
                },
              }}
              label={t("common:password")}
              type="password"
              handleChange={handleChange}
            />
          </Grid>
        )}
      </Grid>
    </Item>
  );
};

UserConnectionInfos.defaultProps = {
  setIsDirty: undefined,
};
export default UserConnectionInfos;
