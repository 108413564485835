import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import {
  LiviaResident,
  ResidentForm,
} from "@commr/admin/models/user/resident/resident";
import { useParams } from "react-router";
import { UseFormSetValue } from "react-hook-form";
import dayjs from "dayjs";
import { Sex } from "@commr/admin/models/user/user";
import {
  AppSyncSearchQuery,
  AppSyncSearchResult,
} from "@commr/admin/models/common/appSyncSearch";
import { getResidents } from "@commr/admin/services/residentService";
import { AppContext } from "@commr/admin/context/appContext";
import LiviaResidentsHits from "./liviaResidentsHits";
import { getLiviaResidentsByResidencyId } from "../../../services/liviaService";

interface Props {
  handleClose: () => void;
  openLiviaResidentGrid: boolean;
  setValue: UseFormSetValue<ResidentForm>;
}

const AddLiviaResident: React.FC<React.PropsWithChildren<Props>> = ({
  openLiviaResidentGrid,
  handleClose,
  setValue,
}) => {
  const { t } = useTranslation();
  const [residentsHits, setResidentsHits] = React.useState<LiviaResident[]>();
  const [currentLiviaResident, setCurrentLiviaResident] =
    useState<LiviaResident>(new LiviaResident());
  const { tenantId, residencyId } = useParams<{
    tenantId: string;
    residencyId: string;
  }>();
  const { showToast } = useContext(AppContext);

  useEffect(() => {
    if (residencyId && tenantId && openLiviaResidentGrid)
      getLiviaResidentsByResidencyId(tenantId, residencyId)
        .then((liviaResidents: LiviaResident[]) => {
          const searchQuery = new AppSyncSearchQuery();
          searchQuery.limit = liviaResidents.length;
          getResidents(searchQuery, residencyId, tenantId).then(
            (residents: AppSyncSearchResult) => {
              liviaResidents = liviaResidents.filter(
                (liviaResident) =>
                  !residents.items?.find(
                    (resident) =>
                      resident.externalId === liviaResident.externalID
                  )
              );

              setResidentsHits(liviaResidents);
            }
          );
        })
        .catch((e) => {
          console.log("e", e);
          showToast(t("forms:errorMessages.apiConnection"), "error");
          handleClose();
          setResidentsHits([]);
        });
  }, [residencyId, openLiviaResidentGrid, tenantId]);

  interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }

  const fillResidentForm = () => {
    setValue("externalId", currentLiviaResident.externalID);
    setValue("lastName", currentLiviaResident.lastName);
    setValue("firstName", currentLiviaResident.firstName);
    setValue("email", currentLiviaResident.email);
    setValue("sex", currentLiviaResident.sex as Sex);
    setValue("birthDate", dayjs(currentLiviaResident.birthDate));
    setValue("address", currentLiviaResident.address);
    handleClose();
  };

  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      fullWidth
      aria-labelledby="customized-dialog-title"
      open={openLiviaResidentGrid}
      maxWidth="lg"
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Liste des résident Livia
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <LiviaResidentsHits
          setCurrentLiviaResident={setCurrentLiviaResident}
          liviaResidents={residentsHits}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={fillResidentForm}>
          Sélectionner ce résident
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddLiviaResident;
