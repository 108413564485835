import { createMapper, mapFrom } from "@automapper/core";
import { classes } from "@automapper/classes";
import dayjs from "dayjs";
import {
  Resident,
  ResidentForm,
} from "@commr/admin/models/user/resident/resident";
import { User, UserForm } from "@commr/admin/models/user/user";

const ResidentFormToResident = createMapper({
  name: "ResidentFormToResident",
  pluginInitializer: classes,
});

ResidentFormToResident.createMap(User, Resident);
ResidentFormToResident.createMap(UserForm, User, {
  extends: [ResidentFormToResident.getMapping(User, Resident)],
})
  .forMember(
    (destination) => destination.address,
    mapFrom((source) => source.address)
  )
  .forMember(
    (destination) => destination.sex,
    mapFrom((source) => source.sex)
  )
  .forMember(
    (destination) => destination.password,
    mapFrom((source) => source.password)
  );

ResidentFormToResident.createMap(ResidentForm, Resident, {
  extends: [ResidentFormToResident.getMapping(UserForm, User)],
})
  .forMember(
    (destination) => destination.birthDate,
    mapFrom((source) => dayjs(source.birthDate, "DD-MM-YYYY"))
  )
  .forMember(
    (destination) => destination.cognitoId,
    mapFrom((source) => source.cognitoId)
  )
  .forMember(
    (destination) => destination.address,
    mapFrom((source) => source.address)
  )
  .forMember(
    (destination) => destination.sex,
    mapFrom((source) => source.sex)
  )
  .forMember(
    (destination) => destination.profilePicture,
    mapFrom((source) => source.profilePicture)
  )
  .forMember(
    (destination) => destination.password,
    mapFrom((source) => source.password)
  );

export default ResidentFormToResident;
