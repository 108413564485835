import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  GridActionsCellItem,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import {
  Residency,
  ResidencyRow,
} from "@commr/admin/models/residency/residency";
import { ResidencyContext } from "@commr/admin/context/residencyContext";
import dayjs from "dayjs";
import CustomDataGrid from "../common/customDataGrid";

interface Props {
  tenantId: string;
  hits?: Residency[];
}

const ResidenciesHit: React.FC<React.PropsWithChildren<Props>> = ({
  hits,
  tenantId,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { setResidency } = useContext(ResidencyContext);
  const [residencyRows, setResidencyRows] = useState<ResidencyRow[]>([]);
  const [pageSize, setPageSize] = React.useState(10);

  const getGridActionsCellItem = (params: any) => (
    <GridActionsCellItem
      label={t("common:actions.edit")}
      onClick={() => {
        const currentResidency: Residency = {
          name: params.row.name,
          tenantId,
          externalId: params.row.externalId,
          address: params.row.address,
          email: "",
          creationDate: dayjs(),
          isActive: true,
        };
        setResidency(currentResidency);
        history.push(`/tenant/${tenantId}/residency/${params.id}`);
      }}
      showInMenu
    />
  );

  const residenciesColumns: any = [
    {
      field: "externalId",
      headerName: t("common:Id"),
      flex: 0.2,
    },
    {
      field: "name",
      headerName: t("common:name"),
      flex: 0.5,
    },
    {
      field: "email",
      headerName: t("common:email"),
      flex: 1,
    },
    {
      field: "address",
      headerName: t("common:address"),
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      getActions: (params: any) => [getGridActionsCellItem(params)],
    },
  ];

  useEffect(() => {
    hits?.map(async (hit) => {
      const residency = {
        externalId: hit.externalId,
        name: hit.name,
        email: hit.email,
        address: hit.address,
        selected: false,
        creationDate: hit.creationDate,
      };
      setResidencyRows((dataGridRows) => [...dataGridRows, residency]);
    });
  }, [hits]);

  const gridProps = {
    pagination: true,
    pageSize,
    slots: {
      toolbar: GridToolbarFilterButton,
    },
    loading: !hits,
    onPageSizeChange: (newPageSize: number) => setPageSize(newPageSize),
    getRowId: (row: any) => row.externalId,
    rows: residencyRows,
    columns: residenciesColumns,
    disableDensitySelector: true,
    pageSizeOptions: [],
    initialState: {
      pagination: { paginationModel: { pageSize: 20 } },
      sorting: {
        sortModel: [{ field: "id", sort: "asc" }],
      },
    },
  };

  return (
    <div style={{ height: 750, width: "100%" }}>
      <CustomDataGrid attribute={gridProps} />
    </div>
  );
};

export default ResidenciesHit;

ResidenciesHit.defaultProps = {
  hits: undefined,
};
