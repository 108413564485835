import { Admin } from "@commr/admin/models/user/admin/admin";
import { setUserPicture } from "./userUtils";

const getProfilePicture = async (avatar: string) => {
  const profilePicture = await setUserPicture(avatar);

  return profilePicture;
};

export const fillAdminData = async (adminResult: any) => {
  const admin = new Admin();
  if (adminResult) {
    admin.id = adminResult.id;
    admin.firstName = adminResult.firstName;
    admin.lastName = adminResult.lastName;
    admin.email = adminResult.email;
    admin.profilePicture = await getProfilePicture(adminResult.profilePicture);
    admin.tenantID = adminResult.tenantID;
    admin.residencyID = adminResult.residencyID;
    admin.createdAt = adminResult.createdAt;
    admin.isSuper = adminResult.isSuper ?? false;
    admin.cognitoID = adminResult.cognitoID ?? undefined;
  }

  return admin;
};

export const fillAdminsData = (adminsResult: any) => {
  const admins: Admin[] = [];

  if (adminsResult) {
    adminsResult.forEach(async (item: any) => {
      admins.push(await fillAdminData(item));
    });
  }
  return admins;
};

export const createAdmins = /* GraphQL */ `
  mutation CreateAdmins(
    $input: CreateAdminsInput!
    $condition: ModelAdminsConditionInput
  ) {
    createAdmins(input: $input, condition: $condition) {
      id
      cognitoID
      firstName
      lastName
      email
      profilePicture
      isSuper
      residencyID
      tenantID
      createdAt
      updatedAt
    }
  }
`;

export const updateAdmins = /* GraphQL */ `
  mutation UpdateAdmins(
    $input: UpdateAdminsInput!
    $condition: ModelAdminsConditionInput
  ) {
    updateAdmins(input: $input, condition: $condition) {
      id
      cognitoID
      firstName
      lastName
      email
      profilePicture
      isSuper
      residencyID
      tenantID
      createdAt
      updatedAt
    }
  }
`;

export const searchAdmins = /* GraphQL */ `
  query SearchAdmins(
    $filter: SearchableAdminsFilterInput
    $sort: [SearchableAdminsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAdminsAggregationInput]
  ) {
    searchAdmins(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        cognitoID
        firstName
        lastName
        email
        profilePicture
        isSuper
        residencyID
        tenantID
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const getAdmins = /* GraphQL */ `
  query GetAdmins($id: ID!) {
    getAdmins(id: $id) {
      id
      cognitoID
      firstName
      lastName
      email
      profilePicture
      isSuper
      residencyID
      tenantID
      createdAt
      updatedAt
    }
  }
`;

export const adminByCognitoId = /* GraphQL */ `
  query AdminByCognitoId(
    $cognitoID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminByCognitoId(
      cognitoID: $cognitoID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoID
        firstName
        lastName
        email
        profilePicture
        isSuper
        residencyID
        tenantID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
