import React, { useContext, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Media, PathType } from "@commr/admin/models/common/media";
import { AdminForm, Admin } from "@commr/admin/models/user/admin/admin";
import tabProps from "@commr/admin/utils/tabUtils";
import { setUserProfilePicture } from "@commr/admin/utils/userUtils";
import { AppContext } from "@commr/admin/context/appContext";
import { createAdmin, updateAdmin } from "@commr/admin/services/adminService";
import TabPanel from "../common/tabs/tabPanel";
import FormFooterActions from "../common/formFooterActions";
import AdminToAdminForm from "./mappers/adminToAdminForm";
import UserConnectionInfos from "../users/forms/userConnectionInfos";
import AdminGeneralInfos from "./forms/adminGeneralInfos";
import AdminFormToAdmin from "./mappers/adminFormToAdmin";

interface Props {
  admin: Admin;
  isSuper: boolean;
}

const AdminDataContainer: React.FC<React.PropsWithChildren<Props>> = ({
  admin,
  isSuper,
}) => {
  const { showToast } = useContext(AppContext);
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const { tenantId, residencyId } = useParams<{
    tenantId: string;
    residencyId: string;
  }>();
  const { t } = useTranslation();
  const [profilePicture, setProfilePicture] = useState(
    admin ? admin.profilePicture : ""
  );
  const defaultValues = admin
    ? AdminToAdminForm.map(admin, AdminForm, Admin)
    : new AdminForm();
  const { control, handleSubmit, reset, register } = useForm<AdminForm>({
    defaultValues,
  });

  const isUpdate = admin.id !== "";
  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    event.isDefaultPrevented();
    setCurrentTabIndex(tabIndex);
  };

  const handleCancel = () => {
    reset(defaultValues);
    setProfilePicture(admin ? admin.profilePicture : "");
  };

  const onSubmit = (adminForm: AdminForm) => {
    setIsSaving(true);
    const newAdmin = AdminFormToAdmin.map(adminForm, Admin, AdminForm);

    const currentProfilePicture: Media | undefined = setUserProfilePicture(
      newAdmin.profilePicture,
      profilePicture,
      tenantId,
      residencyId,
      newAdmin.id,
      PathType.Admins,
      isSuper
    );

    newAdmin.tenantID = tenantId;
    newAdmin.residencyID = residencyId;

    newAdmin.profilePicture = profilePicture;

    newAdmin.isSuper = isSuper ?? false;

    let submitAction;

    if (admin.id) {
      newAdmin.id = admin.id;
      submitAction = updateAdmin;
    } else {
      submitAction = createAdmin;
    }

    submitAction(newAdmin, currentProfilePicture)
      .then(() => {
        showToast(t("forms:successMessages.adminSaved"), "success");
        setTimeout(() => {
          setIsSaving(false);
          history.push(
            !isSuper
              ? `/tenant/${newAdmin.tenantID}/residency/${newAdmin.residencyID}/1`
              : `/superAdmins/true`
          );
        }, 2500);
      })
      .catch((error) => {
        console.log("error", error);
        const errorCode = error;
        let errorMessageTranslation;
        switch (errorCode) {
          case "UsernameExistsException":
            errorMessageTranslation = "emailAlReadyExists";
            break;
          default:
            errorMessageTranslation = "saveFailed";
            break;
        }

        showToast(t(`forms:errorMessages.${errorMessageTranslation}`), "error");
        setIsSaving(false);
      });
  };

  return (
    <Box
      sx={{ width: "100%", flexGrow: 1 }}
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      autoComplete="no"
      noValidate
    >
      <Box
        sx={{
          borderBottom: 0,
          borderColor: "divider",
          width: "100%",
        }}
      >
        <Tabs
          sx={{ width: "100%" }}
          className="tab-styles"
          value={currentTabIndex}
          onChange={handleChange}
          aria-label="admins tabs"
        >
          <Tab {...tabProps(0, t("common:profile"))} />
        </Tabs>
      </Box>
      <Grid container sx={{ display: "block" }}>
        <TabPanel value={currentTabIndex} index={0}>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12}>
              <AdminGeneralInfos
                control={control}
                profilePicture={profilePicture}
                setProfilePicture={setProfilePicture}
                register={register}
              />
            </Grid>
            <Grid item xs={6}>
              <UserConnectionInfos control={control} isUpdate={isUpdate} />
            </Grid>
          </Grid>
        </TabPanel>
        <FormFooterActions isSaving={isSaving} handleCancel={handleCancel} />
      </Grid>
    </Box>
  );
};

export default AdminDataContainer;
