import RoutesData from "@commr/admin/models/common/iRoutesData";
import ProfilePage from "../../users/profilePage";
import SettingsPage from "../../users/settingsPage";
import FeedsPage from "../../feeds/feedsPage";

const commonRoutesData: RoutesData[] = [
  {
    path: "/profile",
    component: ProfilePage,
    title: "route:profile",
  },
  {
    path: "/settings",
    component: SettingsPage,
    title: "route:settings",
  },
  {
    path: "/tenant/:tenantId/residency/:residencyId/feeds",
    component: FeedsPage,
    title: "route:feeds",
  },
];

export default commonRoutesData;
