import { createMapper, mapFrom } from "@automapper/core";
import { classes } from "@automapper/classes";
import dayjs from "dayjs";
import { Feed, FeedForm } from "@commr/admin/models/feed/feed";
import { User, UserForm } from "@commr/admin/models/user/user";

const FeedToFeedForm = createMapper({
  name: "FeedToFeedForm",
  pluginInitializer: classes,
});

FeedToFeedForm.createMap(Feed, User);
FeedToFeedForm.createMap(User, UserForm, {
  extends: [FeedToFeedForm.getMapping(Feed, User)],
})
  .forMember(
    (destination) => destination.address,
    mapFrom((source) => source.address)
  )
  .forMember(
    (destination) => destination.profilePicture,
    mapFrom((source) => source.profilePicture)
  );

FeedToFeedForm.createMap(Feed, FeedForm, {
  extends: [FeedToFeedForm.getMapping(User, UserForm)],
})
  .forMember(
    (destination) => destination.id,
    mapFrom((source) => source.id)
  )
  .forMember(
    (destination) => destination.title,
    mapFrom((source) => source.title)
  )
  .forMember(
    (destination) => destination.content,
    mapFrom((source) => source.content)
  )
  .forMember(
    (destination) => destination.picture,
    mapFrom((source) => source.picture)
  )
  .forMember(
    (destination) => destination.creationDate,
    mapFrom((source) => dayjs(source.creationDate, "DD-MM-YYYY"))
  );

export default FeedToFeedForm;
