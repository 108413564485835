import { API, Auth, Cache } from "aws-amplify";
import dayjs from "dayjs";

export const apiGet = async (path: string, init: any, api: string) =>
  API.get(api, path, init).then((response) => response?.data);

export const setCachedData = (
  userId: string,
  key: string,
  value: string,
  options: any
) => Cache.setItem(`${userId}-${key}`, value, options);

export const getCachedData = <T>(userId: string, key: string) =>
  JSON.parse(Cache.getItem(`${userId}-${key}`)) as T;

export const getExpirationTimestamp = <T>(
  value: number,
  type: "minute" | "day" | "year" | "second"
) => dayjs().add(value, type).toDate().getTime();

export const getAuthorizationHeader = async (
  body?: any,
  queryStringParameters?: any
) => {
  try {
    const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    return {
      "Content-Type": "application/json",
      response: true,
      headers: {
        Authorization: jwtToken,
      },
      body,
      queryStringParameters,
    };
  } catch (error) {
    console.error("Error getting authorization header:", error);
    throw error;
  }
};
