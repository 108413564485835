import React from "react";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress, Grid } from "@mui/material";

interface Props {
  isSaving: boolean;
  isDirty?: boolean;
  handleCancel: () => void;
}

const FormFooterActions: React.FC<React.PropsWithChildren<Props>> = ({
  isSaving,
  isDirty,
  handleCancel,
}) => {
  const { t } = useTranslation();

  return (
    <Grid item container xs={12} spacing={1} justifyContent="flex-end">
      <Grid item xs={6}>
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={6}>
            <Button
              data-testid="submit-button"
              name="submit-button"
              fullWidth
              variant="contained"
              type="submit"
              disabled={isSaving || (isDirty !== undefined && !isDirty)}
            >
              {isSaving ? (
                <CircularProgress
                  data-testid="circular-progress"
                  size={25}
                  color="inherit"
                />
              ) : (
                t("common:save")
              )}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              data-testid="cancel-button"
              name="cancel-button"
              fullWidth
              variant="contained"
              type="button"
              onClick={handleCancel}
              color="error"
              disabled={isSaving || (isDirty !== undefined && !isDirty)}
            >
              {t("common:cancel")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
FormFooterActions.defaultProps = {
  isDirty: undefined,
};
export default FormFooterActions;
