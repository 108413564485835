// @ts-check
import { initSchema } from "@aws-amplify/datastore";
import schema from "./schema";

const CallStatus = {
  INITIATED: "initiated",
  ONGOING: "ongoing",
  REJECTED: "rejected",
  BUSY: "busy",
  UNANSWERED: "unanswered",
  CANCELLED: "cancelled",
  ENDED: "ended",
};

const Relation = {
  UNKNOWN: "Unknown",
  HUSBAND: "Husband",
  WIFE: "Wife",
  SPOUSE: "Spouse",
  SON: "Son",
  DAUGHTER: "Daughter",
  BROTHER: "Brother",
  SISTER: "Sister",
  COUSIN: "Cousin",
  UNCLE: "Uncle",
  AUNT: "Aunt",
  FATHER: "Father",
  MOTHER: "Mother",
  SON_IN_LAW: "SonInLaw",
  DAUGHTER_IN_LAW: "DaughterInLaw",
  GRANDSON: "Grandson",
  GRANDDAUGHTER: "Granddaughter",
  FRIEND: "Friend",
};

const UserType = {
  PROFESSIONAL: "Professional",
  RESIDENT: "Resident",
  CIRCLE_MEMBER: "CircleMember",
};

const {
  Message,
  Conversation,
  Call,
  UserConversations,
  UserConversationsConnection,
  Residency,
  Resident,
  ResidentsProfessionals,
  Professional,
  ResidentCircleMemberRelation,
  CircleMember,
  Tenant,
  CallHistory,
  UserHistory,
} = initSchema(schema);

export {
  Message,
  Conversation,
  Call,
  UserConversations,
  UserConversationsConnection,
  Residency,
  Resident,
  ResidentsProfessionals,
  Professional,
  ResidentCircleMemberRelation,
  CircleMember,
  Tenant,
  CallStatus,
  Relation,
  UserType,
  CallHistory,
  UserHistory,
};
