import { Media, MediaType, PathType } from "@commr/admin/models/common/media";
import { Feed } from "@commr/admin/models/feed/feed";
import { getFileFromBase64 } from "./mediaUtils";

export const fillFeedData = (feedResult: any) => {
  const feed = new Feed();
  if (feedResult) {
    feed.id = feedResult.id;
    feed.title = feedResult.title;
    feed.content = feedResult.content;
    feed.picture = feedResult.picture;
    feed.creationDate = feedResult.createdAt;
  }

  return feed;
};

export const fillFeedsData = async (feedsResult: any) => {
  const feeds: Feed[] = [];
  const items = feedsResult?.searchNews?.items;

  if (items) {
    items.forEach((item: any) => {
      feeds.push(fillFeedData(item));
    });
  }
  return feeds;
};

export const setFeedPicture = (
  defaultPicture: string,
  profilePicture: string,
  tenantId: string,
  residencyId: string,
  feedId: string,
  pathType: PathType
) => {
  if (defaultPicture !== profilePicture && profilePicture !== "") {
    const media: Media = {
      media: getFileFromBase64(profilePicture, "profilePicture"),
      userType: pathType,
      fileName: feedId,
      mediaType: MediaType.ProfilePicture,
      path: `tenants/${tenantId}/residencies/${residencyId}/${pathType}/Feeds/${feedId}/FeedPicture`,
    };

    return media;
  }
  return undefined;
};

export const createNews = /* GraphQL */ `
  mutation CreateNews(
    $input: CreateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    createNews(input: $input, condition: $condition) {
      id
      residency {
        externalID
        tenantID
        tenant {
          id
          name
          createdAt
          updatedAt
        }
        residents {
          nextToken
        }
        name
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        createdAt
        updatedAt
      }
      residencyID
      tenantID
      authorID
      title
      content
      picture
      createdAt
      updatedAt
    }
  }
`;

export const updateNews = /* GraphQL */ `
  mutation UpdateNews(
    $input: UpdateNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    updateNews(input: $input, condition: $condition) {
      id
      residency {
        externalID
        tenantID
        tenant {
          id
          name
          createdAt
          updatedAt
        }
        residents {
          nextToken
        }
        name
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        createdAt
        updatedAt
      }
      residencyID
      tenantID
      authorID
      title
      content
      picture
      createdAt
      updatedAt
    }
  }
`;

export const deleteNews = /* GraphQL */ `
  mutation DeleteNews(
    $input: DeleteNewsInput!
    $condition: ModelNewsConditionInput
  ) {
    deleteNews(input: $input, condition: $condition) {
      id
      residency {
        externalID
        tenantID
        tenant {
          id
          name
          createdAt
          updatedAt
        }
        residents {
          nextToken
        }
        name
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        createdAt
        updatedAt
      }
      residencyID
      tenantID
      authorID
      title
      content
      picture
      createdAt
      updatedAt
    }
  }
`;

export const searchNews = /* GraphQL */ `
  query SearchNews(
    $filter: SearchableNewsFilterInput
    $sort: [SearchableNewsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableNewsAggregationInput]
  ) {
    searchNews(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        residency {
          externalID
          tenantID
          name
          createdAt
          updatedAt
        }
        residencyID
        tenantID
        authorID
        title
        content
        picture
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const getNews = /* GraphQL */ `
  query GetNews($id: ID!, $residencyID: ID!, $tenantID: ID!) {
    getNews(id: $id, residencyID: $residencyID, tenantID: $tenantID) {
      id
      residency {
        externalID
        tenantID
        tenant {
          id
          name
          createdAt
          updatedAt
        }
        residents {
          nextToken
        }
        name
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        createdAt
        updatedAt
      }
      residencyID
      tenantID
      authorID
      title
      content
      picture
      createdAt
      updatedAt
    }
  }
`;
