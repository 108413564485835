import { CircleMemberRow } from "@commr/admin/models/user/circleMember/circleMember";
import { Resident } from "@commr/admin/models/user/resident/resident";
import { removeResidentCircleMemberRelation } from "@commr/admin/services/circleMemberService";
import { AccountCircle } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import {
  GridActionsCellItem,
  GridRenderCellParams,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { setUserPicture } from "@commr/admin/utils/userUtils";
import CustomDataGrid from "../common/customDataGrid";
import "./styles/residentCircleMembers.scss";
import AddExistingCircleMembers from "./modals/addExistingCircleMembers";

interface Props {
  resident: Resident;
}

const ResidentCircleMembers: React.FC<React.PropsWithChildren<Props>> = ({
  resident,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [residentCircleMemberRows, setResidentCircleMemberRows] = useState<
    CircleMemberRow[]
  >([]);
  const [selectedCircleMemberId, setSelectedCircleMemberId] =
    useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (resident) {
      setResidentCircleMemberRows([]);
      resident.circleMembers?.map(async (hit) => {
        const avatar = await setUserPicture(hit.profilePicture);
        const circleMember: any = {
          id: hit.id,
          avatar,
          lastName: hit.lastName,
          firstName: hit.firstName,
          email: hit.email,
          role: "",
          relation: hit.relation,
        };
        setResidentCircleMemberRows((dataGridRows) => [
          ...dataGridRows,
          circleMember,
        ]);
      });
    }
  }, [resident]);

  const handleClose = () => {
    setSelectedCircleMemberId("");
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

  const handleDeleteCircleMember = (memberID: string) => {
    removeResidentCircleMemberRelation(
      memberID,
      resident.externalId,
      resident.residencyId,
      resident.tenantId
    ).then(() => {
      setResidentCircleMemberRows((rows) =>
        rows.filter(
          (residentCircleMemberlRow) => memberID !== residentCircleMemberlRow.id
        )
      );
      handleClose();
      window.location.reload();
    });
  };

  const handleAddCircleMember = () => {
    const attribute = "";

    console.log("atribute", attribute);
    history.push(
      `/tenant/${resident.tenantId}/residency/${resident.residencyId}/resident/${resident.externalId}/circleMembers/add`
    );
  };

  const handleAddExistingCircleMember = () => {
    setIsModalOpen(true);
  };

  const setAvatar = (params: GridRenderCellParams) => (
    <Avatar src={params.value?.toString()} />
  );

  const residentCircleMemberColumns: any = [
    {
      field: "avatar",
      headerName: "",
      flex: 0.1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<any>) => setAvatar(params),
      renderHeader: () => <AccountCircle />,
    },
    {
      field: "lastName",
      headerName: t("residents:filter.lastName"),
      flex: 1,
    },
    {
      field: "firstName",
      headerName: t("residents:filter.firstName"),
      flex: 1,
    },
    {
      field: "relation",
      headerName: t("residents:circle.relation"),
      renderCell: (params: GridRenderCellParams<any>) =>
        t(`familyMember:relation.${params.value?.toLocaleLowerCase()}`),
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.1,
      getActions: (params: any) => [
        <GridActionsCellItem
          label={t("residents:circle.profile")}
          onClick={() => {
            history.push(
              `/tenant/${resident.tenantId}/residency/${resident.residencyId}/resident/${resident.externalId}/circleMember/${params.id}`
            );
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label={t(`residents:removeCircleMember`)}
          onClick={() => handleDeleteCircleMember(params.id.toString())}
          showInMenu
        />,
      ],
    },
  ];

  const gridProps = {
    pagination: true,
    pageSizeOptions: [],
    slots: {
      toolbar: GridToolbarFilterButton,
    },
    disableColumnMenu: true,
    disableSelectionOnClick: true,
    disableColumnSelector: true,
    rows: residentCircleMemberRows,
    columns: residentCircleMemberColumns,
    initialState: {
      pagination: { paginationModel: { pageSize: 20 } },
    },
  };

  return (
    <Grid item container xs={12} spacing={1}>
      <Grid item xs={12}>
        <Item>
          <Grid item xs={12} sx={{ paddingBottom: 2 }}>
            <Typography variant="h5">
              {t("userForm:circleMemberListInfo")}
            </Typography>
            <Divider variant="fullWidth" />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ paddingBottom: 2 }}
            justifyContent="flex-end"
          >
            <Stack aria-label="button group" direction="row">
              <Button
                variant="contained"
                sx={{ mr: 2 }}
                onClick={handleAddCircleMember}
              >
                {t("residents:addCircleMember")}
              </Button>

              <Button
                variant="contained"
                onClick={handleAddExistingCircleMember}
                color="secondary"
              >
                {t("residents:addExistingCircleMember")}
              </Button>
            </Stack>
            <AddExistingCircleMembers
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              resident={resident}
            />
          </Grid>

          <Grid item xs={12}>
            <div style={{ height: 600, width: "100%" }}>
              <CustomDataGrid attribute={gridProps} />
            </div>
          </Grid>
        </Item>
      </Grid>
    </Grid>
  );
};
export default ResidentCircleMembers;
