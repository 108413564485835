import { Dayjs } from "dayjs";

export enum UserType {
  Admin = "Admin",
  Resident = "Resident",
  CircleMember = "CircleMember",
  Professional = "Professional",
}

export enum Sex {
  Male = "Male",
  Female = "Female",
  Unknown = "Unknown",
}

export enum Language {
  French,
  English,
}

export class Address {
  city = "";

  civicNumber = "";

  postalCode = "";

  street = "";

  province = "";

  country = "";
}

export class User {
  id = "";

  firstName = "";

  lastName = "";

  email = "";

  address: Address = new Address();

  isActive = false;

  profilePicture = "";

  language: Language = Language.French;

  password?: string;

  sex = "Unknown";

  conversationsConnectionId = "";

  userType: UserType = UserType.CircleMember;

  tenantId = "";

  cognitoId = "";
}

export class UserForm extends User {
  city = "";

  civicNumber = "";

  postalCode = "";

  street = "";

  province = "";

  photo: FileList | null = null;
}

export interface SearchedUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export class ResetPasswordForm {
  email = "";

  password?: string = "";

  confirmation?: string = "";
}

export class UserLoginForm {
  email = "";

  password = "";
}

export interface UserRow {
  selected?: boolean;

  id: string;

  avatar: string;

  lastName: string;

  firstName: string;

  email: string;

  creationDate?: Dayjs;
}
