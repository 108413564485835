import { createMapper, mapFrom } from "@automapper/core";
import { classes } from "@automapper/classes";
import { User } from "@commr/admin/models/user/user";
import {
  CircleMemberForm,
  CircleMember,
} from "@commr/admin/models/user/circleMember/circleMember";

const CircleMemberFormToCircleMember = createMapper({
  name: "CircleMemberFormToCircleMember",
  pluginInitializer: classes,
});

CircleMemberFormToCircleMember.createMap(CircleMember, User);
CircleMemberFormToCircleMember.createMap(CircleMemberForm, CircleMember, {
  extends: [CircleMemberFormToCircleMember.getMapping(CircleMember, User)],
})
  .forMember(
    (destination) => destination.relation,
    mapFrom((source) => source.relation)
  )
  .forMember(
    (destination) => destination.address,
    mapFrom((source) => source.address)
  )
  .forMember(
    (destination) => destination.password,
    mapFrom((source) => source.password)
  )
  .forMember(
    (destination) => destination.relation,
    mapFrom((source) => source.relation)
  )
  .forMember(
    (destination) => destination.sex,
    mapFrom((source) => source.sex)
  );
export default CircleMemberFormToCircleMember;
