import { Divider, Grid, Paper, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Control, UseFormRegister } from "react-hook-form";
import { Sex } from "@commr/admin/models/user/user";
import ControlledInput from "../../common/formFields/controlledInput";
import ControlledSelect from "../../common/formFields/controlledSelect";
import PhotoAvatarInput from "../../common/formFields/photoAvatarInput";

interface Props {
  control: Control<any, any>;
  profilePicture: string;
  setProfilePicture: React.Dispatch<React.SetStateAction<string>>;
  register: UseFormRegister<any>;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserGeneralInfos: React.FC<React.PropsWithChildren<Props>> = ({
  control,
  profilePicture,
  setProfilePicture,
  register,
  setIsDirty,
}) => {
  const { t } = useTranslation();

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

  const sexOptions = Object.values(Sex).map((sex) => ({
    value: sex,
    name: t(`common:sexList.${sex.toLocaleLowerCase()}`),
    isDisabled: false,
  }));

  const handleChange = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    initialValue: string
  ) => {
    if (setIsDirty && e.target.value !== initialValue) setIsDirty(true);
  };

  const handleSelectChange = () => {
    if (setIsDirty) setIsDirty(true);
  };

  return (
    <Item>
      <Grid item sx={{ paddingBottom: 2 }}>
        <Typography variant="h5" data-testid="general-info-label">
          {t("userForm:generalInfo")}
        </Typography>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={6}>
          <Grid
            container
            direction="row"
            spacing={1}
            style={{
              justifyContent: "center",
              display: "flex",
              alignContent: "center",
            }}
          >
            <PhotoAvatarInput
              register={register}
              profilePicture={profilePicture}
              setProfilePicture={setProfilePicture}
              name="photo"
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={6}>
              <ControlledInput
                name="lastName"
                controllerProps={{
                  name: "lastName",
                  control,
                  rules: {
                    maxLength: {
                      value: 50,
                      message: `${t("forms:errorMessages.maxLength", {
                        value: 50,
                      })}`,
                    },
                    minLength: {
                      value: 3,
                      message: `${t("forms:errorMessages.minLength", {
                        value: 3,
                      })}`,
                    },
                    required: {
                      value: true,
                      message: `${t("forms:errorMessages.required")}`,
                    },
                  },
                }}
                handleChange={handleChange}
                label={t("common:lastName")}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledInput
                name="firstName"
                controllerProps={{
                  name: "firstName",
                  control,
                  rules: {
                    maxLength: {
                      value: 50,
                      message: `${t("forms:errorMessages.maxLength", {
                        value: 50,
                      })}`,
                    },
                    minLength: {
                      value: 3,
                      message: `${t("forms:errorMessages.minLength", {
                        value: 3,
                      })}`,
                    },
                    required: {
                      value: true,
                      message: `${t("forms:errorMessages.required")}`,
                    },
                  },
                }}
                label={t("common:firstName")}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={3}>
              <ControlledSelect
                controllerProps={{
                  name: "sex",
                  control,
                }}
                label={t("common:sex")}
                options={sexOptions}
                handleChange={handleSelectChange}
              />
            </Grid>
            <Grid item xs={9} />
            <Grid item xs={3}>
              <ControlledInput
                name="civicNumber"
                controllerProps={{
                  name: "address.civicNumber",
                  control,
                }}
                label={t("common:civicNumber")}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={9}>
              <ControlledInput
                name="street"
                controllerProps={{
                  name: "address.street",
                  control,
                }}
                label={t("common:street")}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledInput
                name="city"
                controllerProps={{
                  name: "address.city",
                  control,
                }}
                label={t("common:city")}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={3}>
              <ControlledInput
                name="postalCode"
                controllerProps={{
                  name: "address.postalCode",
                  control,
                }}
                label={t("common:postalCode")}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={3}>
              <ControlledInput
                name="province"
                controllerProps={{
                  name: "address.province",
                  control,
                }}
                label={t("common:province")}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Item>
  );
};

UserGeneralInfos.defaultProps = {
  setIsDirty: undefined,
};
export default UserGeneralInfos;
