import { SxProps } from "@mui/material";

const tabProps = (
  index: number,
  label: string,
  disabled = false,
  sx?: SxProps
) => ({
  id: `simple-tab-${index}`,
  "aria-controls": `simple-tabpanel-${index}`,
  label,
  sx: sx ?? {
    border: "1px solid #e9e9e9",
    background: "#f7f7f7",
    opacity: 1,
  },
  disabled,
});

export default tabProps;
