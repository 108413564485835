import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Feed from "@commr/admin/models/feed/feed";
import FeedDataContainer from "@commr/admin/components/feeds/feedDataContainer";
import { getFeedById } from "@commr/admin/services/feedsService";
import { setUserPicture } from "@commr/admin/utils/userUtils";

const FeedsInfoPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [feed, setFeed] = useState<Feed>();
  const { tenantId, residencyId, feedId } = useParams<{
    tenantId: string;
    residencyId: string;
    feedId: string;
  }>();

  useEffect(() => {
    if (feedId && residencyId && tenantId)
      getFeedById(feedId, residencyId, tenantId).then((feedResponse) => {
        setUserPicture(feedResponse.picture).then((picture) => {
          feedResponse.picture = picture;
          setFeed(feedResponse);
        });
      });
  }, [feedId, residencyId, tenantId]);

  if (!feed) return <CircularProgress size={60} />;
  return <FeedDataContainer feed={feed} />;
};

export default FeedsInfoPage;
