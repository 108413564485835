import RoutesData from "@commr/admin/models/common/iRoutesData";
import AddResidencyPage from "../../residency/addResidencyPage";

const residencyRoutesData: RoutesData[] = [
  {
    path: "/tenant/:tenantId/residency/:residencyId/:tab?",
    component: AddResidencyPage,
    title: "route:Residency",
  },
  {
    path: "/Residency/AddResidency",
    component: AddResidencyPage,
    title: "route:Residency",
  },
];

export default residencyRoutesData;
