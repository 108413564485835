import { Logout } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Divider,
  ListItemIcon,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext } from "@commr/admin/context/userContext";
import { AuthContext } from "@commr/admin/context/authContext";

interface Props {
  title: string;
}

const TopMenu: React.FC<React.PropsWithChildren<Props>> = ({ title }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const authContext = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = async () => {
    authContext.signOut().then();
  };

  return (
    <div className="top-menu-container">
      <div className="top-menu-foldSide">
        {true && (
          <button
            data-testid="arrow-back-button"
            type="button"
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon data-testid="arrow-back-icon" />
          </button>
        )}
      </div>
      <div className="top-menu-pageTitle">
        <h3 data-testid="page-title">{t(title)}</h3>
      </div>
      <div>
        <IconButton data-testid="profile-picture-button" onClick={handleClick}>
          {user.profilePicture.length > 0 ? (
            <Avatar
              data-testid="avatar"
              src={user.profilePicture}
              alt={user.firstName}
            />
          ) : (
            <Avatar
              data-testid="avatar-initials"
              className="avatar-initials avatar-small"
            >
              {`${user?.firstName?.substr(0, 1)}
                                ${user?.lastName?.substr(0, 1)}`}
            </Avatar>
          )}
        </IconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        onClick={handleClose}
        className="top-menu-profile-menu"
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Link to="/profile">
          <MenuItem>
            {user.profilePicture.length > 0 ? (
              <Avatar src={user.profilePicture} alt={user.firstName} />
            ) : (
              <Avatar className="avatar-initials avatar-small">
                {`${user?.firstName?.substr(0, 1)}
                                ${user?.lastName?.substr(0, 1)}`}
              </Avatar>
            )}{" "}
            {t("route:myProfile")}
          </MenuItem>
        </Link>
        <Divider className="top-menu-devider" />
        {/* <Link to="/settings">
                    <MenuItem>
                        <ListItemIcon>
                            <Settings fontSize="small" />
                        </ListItemIcon>
                        {t("route:settings")}
                    </MenuItem>
                </Link> */}
        <MenuItem onClick={() => handleLogOut()}>
          <ListItemIcon>
            <Logout data-testid="logout-button" fontSize="small" />
          </ListItemIcon>
          {t("login:logout")}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TopMenu;
