import RoutesData from "@commr/admin/models/common/iRoutesData";
import AddCircleMemberPage from "../../circleMember/addCircleMemberPage";
import UpdateCircleMemberPage from "../../circleMember/updateCircleMemberPage";

const circleMembersRoutesData: RoutesData[] = [
  {
    path: "/tenant/:tenantId/residency/:residencyId/resident/:residentId/circleMembers/add",
    component: AddCircleMemberPage,
    title: "route:addCircleMember",
  },
  {
    path: "/tenant/:tenantId/residency/:residencyId/resident/:residentId/circleMember/:circleMemberId",
    component: UpdateCircleMemberPage,
    title: "route:circleMemberProfile",
  },
];

export default circleMembersRoutesData;
