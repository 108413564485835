import { API, graphqlOperation } from "aws-amplify";
import {
  AppSyncSearchQuery,
  AppSyncSearchResult,
} from "@commr/admin/models/common/appSyncSearch";
import { Tenant } from "@commr/admin/models/tenant";
import {
  createTenantGql,
  searchTenants,
  fillTenantsData,
  fillTenantData,
  updateTenantGql,
  getTenant,
} from "@commr/admin/utils/tenantUtils";

export const createTenant = async (tenant: Tenant) => {
  await API.graphql(
    graphqlOperation(createTenantGql, {
      input: {
        credentials: {
          dns: tenant.credentials.dns,
          login: tenant.credentials.login,
          password: tenant.credentials.password,
        },
        name: tenant.name,
      },
    })
  );
};

export const getTenants = async (searchQuery: AppSyncSearchQuery) => {
  const { query, limit, from } = searchQuery;
  const filter =
    query.length > 0 || ""
      ? {
          or: [
            { name: { matchPhrasePrefix: query } },

            { id: { matchPhrasePrefix: query } },
          ],
        }
      : {};

  const allTenants = (await API.graphql(
    graphqlOperation(searchTenants, {
      filter,
      sort: { direction: "desc", field: "updatedAt" },
      limit,
      from,
    })
  )) as {
    data: any;
  };

  const searchResult = allTenants?.data?.searchTenants;
  let result = new AppSyncSearchResult([], 0, 0);

  if (searchResult) {
    result = new AppSyncSearchResult(
      await fillTenantsData(searchResult.items),
      searchResult.total ?? 0,
      0
    );
  }

  return result;
};

export const updateTenant = async (tenant: Tenant) => {
  const updatedTenant = await API.graphql(
    graphqlOperation(updateTenantGql, {
      input: {
        id: tenant.id,
        credentials: {
          dns: tenant.credentials.dns,
          login: tenant.credentials.login,
          password: tenant.credentials.password,
        },
        name: tenant.name,
      },
    })
  );
  return updatedTenant;
};

export const getTenantById = async (id: string) => {
  const tenant = (await API.graphql(graphqlOperation(getTenant, { id }))) as {
    data: any;
  };

  const tenantData = fillTenantData(tenant?.data?.getTenant);

  return tenantData as Tenant;
};
