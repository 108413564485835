import { createMapper, mapFrom } from "@automapper/core";
import { classes } from "@automapper/classes";
import { Admin, AdminForm } from "@commr/admin/models/user/admin/admin";

const AdminToAdminForm = createMapper({
  name: "AdminToAdminForm",
  pluginInitializer: classes,
});

AdminToAdminForm.createMap(Admin, AdminForm)
  .forMember(
    (destination) => destination.firstName,
    mapFrom((source) => source.firstName)
  )
  .forMember(
    (destination) => destination.lastName,
    mapFrom((source) => source.lastName)
  )
  .forMember(
    (destination) => destination.profilePicture,
    mapFrom((source) => source.profilePicture)
  );

export default AdminToAdminForm;
