import { CircleMember } from "@commr/admin/models/user/circleMember/circleMember";
import { FillUserData } from "./userUtils";

export const fillCircleMemberData = (
  circleMemberResult: any,
  residentId?: string
) => {
  let circleMember = new CircleMember();
  const relation = circleMemberResult.residents?.items?.find(
    (resident: any) => resident.externalID === residentId
  )?.relation;
  if (circleMemberResult) {
    circleMember = FillUserData(circleMemberResult) as CircleMember;
    circleMember.role = circleMemberResult.role;
    circleMember.relation = relation;
    circleMember.conversationsConnectionId =
      circleMemberResult.conversationsConnectionId;
  }

  return circleMember;
};

export const fillCircleMembersData = (circleMembersResult: any) => {
  const circleMembers: CircleMember[] = [];
  const items = circleMembersResult;

  if (items) {
    items.forEach((item: any) => {
      circleMembers.push(fillCircleMemberData(item));
    });
  }
  return circleMembers;
};

export const searchResidentCircleMemberRelations = /* GraphQL */ `
  query SearchResidentCircleMemberRelations(
    $filter: SearchableResidentCircleMemberRelationFilterInput
    $sort: [SearchableResidentCircleMemberRelationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableResidentCircleMemberRelationAggregationInput]
  ) {
    searchResidentCircleMemberRelations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        circleMember {
          id
          cognitoID
          firstName
          lastName
          email
          profilePicture
          sex
          birthDate
          conversationsConnectionID
          createdAt
          updatedAt
        }
        circleMemberID
        relation
        role {
          id
          role
          createdAt
          updatedAt
        }
        resident {
          cognitoID
          externalID
          firstName
          lastName
          email
          profilePicture
          conversationsConnectionID
          residencyID
          tenantID
          sex
          birthDate
          createdAt
          updatedAt
        }
        externalID
        residencyID
        tenantID
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const createCircleMemberGql = /* GraphQL */ `
  mutation CreateCircleMember(
    $input: CreateCircleMemberInput!
    $condition: ModelCircleMemberConditionInput
  ) {
    createCircleMember(input: $input, condition: $condition) {
      id
      cognitoID
      firstName
      lastName
      email
      profilePicture
      sex
      birthDate
      address {
        civicNumber
        street
        postalCode
        province
        city
        country
      }
      conversationsConnectionID
      conversations {
        nextToken
        userConversations {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      residents {
        items {
          circleMemberID
          relation
          externalID
          residencyID
          tenantID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const createResidentCircleMemberRelation = /* GraphQL */ `
  mutation CreateResidentCircleMemberRelation(
    $input: CreateResidentCircleMemberRelationInput!
    $condition: ModelResidentCircleMemberRelationConditionInput
  ) {
    createResidentCircleMemberRelation(input: $input, condition: $condition) {
      circleMember {
        id
        cognitoID
        firstName
        lastName
        email
        profilePicture
        sex
        birthDate
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        conversationsConnectionID
        conversations {
          nextToken
          id
          createdAt
          updatedAt
        }
        residents {
          nextToken
        }
        createdAt
        updatedAt
      }
      circleMemberID
      relation
      role {
        id
        role
        createdAt
        updatedAt
      }
      resident {
        cognitoID
        externalID
        firstName
        lastName
        email
        profilePicture
        conversationsConnectionID
        conversations {
          nextToken
          id
          createdAt
          updatedAt
        }
        residency {
          externalID
          tenantID
          name
          createdAt
          updatedAt
        }
        residencyID
        tenantID
        sex
        birthDate
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        circleMembers {
          nextToken
        }
        createdAt
        updatedAt
      }
      externalID
      residencyID
      tenantID
      createdAt
      updatedAt
    }
  }
`;

export const updateCircleMemberGql = /* GraphQL */ `
  mutation UpdateCircleMember(
    $input: UpdateCircleMemberInput!
    $condition: ModelCircleMemberConditionInput
  ) {
    updateCircleMember(input: $input, condition: $condition) {
      id
      cognitoID
      firstName
      lastName
      email
      profilePicture
      sex
      birthDate
      address {
        civicNumber
        street
        postalCode
        province
        city
        country
      }
      conversationsConnectionID
      conversations {
        nextToken
        userConversations {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      residents {
        items {
          circleMemberID
          relation
          externalID
          residencyID
          tenantID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const updateResidentCircleMemberRelation = /* GraphQL */ `
  mutation UpdateResidentCircleMemberRelation(
    $input: UpdateResidentCircleMemberRelationInput!
    $condition: ModelResidentCircleMemberRelationConditionInput
  ) {
    updateResidentCircleMemberRelation(input: $input, condition: $condition) {
      circleMember {
        id
        cognitoID
        firstName
        lastName
        email
        profilePicture
        sex
        birthDate
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        conversationsConnectionID
        conversations {
          nextToken
          id
          createdAt
          updatedAt
        }
        residents {
          nextToken
        }
        createdAt
        updatedAt
      }
      circleMemberID
      relation
      role {
        id
        role
        createdAt
        updatedAt
      }
      resident {
        cognitoID
        externalID
        firstName
        lastName
        email
        profilePicture
        conversationsConnectionID
        conversations {
          nextToken
          id
          createdAt
          updatedAt
        }
        residency {
          externalID
          tenantID
          name
          createdAt
          updatedAt
        }
        residencyID
        tenantID
        sex
        birthDate
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        circleMembers {
          nextToken
        }
        createdAt
        updatedAt
      }
      externalID
      residencyID
      tenantID
      createdAt
      updatedAt
    }
  }
`;

export const deleteResidentCircleMemberRelation = /* GraphQL */ `
  mutation DeleteResidentCircleMemberRelation(
    $input: DeleteResidentCircleMemberRelationInput!
    $condition: ModelResidentCircleMemberRelationConditionInput
  ) {
    deleteResidentCircleMemberRelation(input: $input, condition: $condition) {
      circleMember {
        id
        cognitoID
        firstName
        lastName
        email
        profilePicture
        sex
        birthDate
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        conversationsConnectionID
        conversations {
          nextToken
          id
          createdAt
          updatedAt
        }
        residents {
          nextToken
        }
        createdAt
        updatedAt
      }
      circleMemberID
      relation
      role {
        id
        role
        createdAt
        updatedAt
      }
      resident {
        cognitoID
        externalID
        firstName
        lastName
        email
        profilePicture
        conversationsConnectionID
        conversations {
          nextToken
          id
          createdAt
          updatedAt
        }
        residency {
          externalID
          tenantID
          name
          createdAt
          updatedAt
        }
        residencyID
        tenantID
        sex
        birthDate
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        circleMembers {
          nextToken
        }
        createdAt
        updatedAt
      }
      externalID
      residencyID
      tenantID
      createdAt
      updatedAt
    }
  }
`;

export const getCircleMember = /* GraphQL */ `
  query GetCircleMember($id: ID!) {
    getCircleMember(id: $id) {
      id
      cognitoID
      firstName
      lastName
      email
      profilePicture
      sex
      birthDate
      address {
        civicNumber
        street
        postalCode
        province
        city
        country
      }
      conversationsConnectionID
      conversations {
        nextToken
        userConversations {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      residents {
        items {
          circleMemberID
          relation
          externalID
          residencyID
          tenantID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
