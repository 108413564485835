import menu1 from "@commr/admin/assets/images/menu1.png";
import menu2 from "@commr/admin/assets/images/menu2.png";
import menu3 from "@commr/admin/assets/images/menu3.png";
import menu4 from "@commr/admin/assets/images/menu4.png";
import menu5 from "@commr/admin/assets/images/menu5.png";
import menu6 from "@commr/admin/assets/images/menu6.png";
import menu7 from "@commr/admin/assets/images/menu7.png";
import menu8 from "@commr/admin/assets/images/menu8.png";

const floatImgs = [
  { src: menu1, className: "login__speechBubble" },
  { src: menu2, className: "login__photos" },
  { src: menu3, className: "login__plus" },
  { src: menu4, className: "login__time" },
  { src: menu5, className: "login__bookmark" },
  { src: menu6, className: "login__games" },
  { src: menu7, className: "login__people" },
  { src: menu8, className: "login__video" },
];

export default floatImgs;
