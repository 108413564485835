import React from "react";
import AdminsContainer from "@commr/admin/components/admins/adminsContainer";
import { Residency } from "@commr/admin/models/residency/residency";

const SuperAdminsPage: React.FC<React.PropsWithChildren<unknown>> = () => (
  <AdminsContainer
    residency={new Residency()}
    addPath="/superAdmins/add"
    isSuper
  />
);

export default SuperAdminsPage;
