import RoutesData from "@commr/admin/models/common/iRoutesData";
import TenantsPage from "../../tenants/tenantsPage";

const tenantsRoutesData: RoutesData[] = [
  {
    path: "/tenants",
    component: TenantsPage,
    title: "route:tenants",
  },
];

export default tenantsRoutesData;
