import { Divider, Grid, Paper, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./feedGeneralInfos.scss";
import { FeedForm } from "@commr/admin/models/feed/feed";
import ControlledInput from "../../common/formFields/controlledInput";
import PhotoAvatarInput from "../../common/formFields/photoAvatarInput";

interface Props {
  control: Control<any, any>;
  profilePicture: string;
  setProfilePicture: React.Dispatch<React.SetStateAction<string>>;
  register: UseFormRegister<any>;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: UseFormSetValue<FeedForm>;
  getValues: UseFormGetValues<FeedForm>;
}

const FeedGeneralInfos: React.FC<React.PropsWithChildren<Props>> = ({
  control,
  profilePicture,
  setProfilePicture,
  register,
  setIsDirty,
  setValue,
  getValues,
}) => {
  const { t } = useTranslation();

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

  const QuillModules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const QuillFormats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "background",
    "align",
  ];

  const handleChange = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    initialValue: string
  ) => {
    if (setIsDirty && e.target.value !== initialValue) setIsDirty(true);
  };

  const handleContent = (html: string) => {
    setValue("content", html);
  };

  return (
    <Item>
      <link
        rel="stylesheet"
        href="https://unpkg.com/react-quill@1.3.3/dist/quill.snow.css"
      />
      <Grid item sx={{ paddingBottom: 2 }}>
        <Typography variant="h5">{t("userForm:generalInfo")}</Typography>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            spacing={1}
            style={{
              justifyContent: "center",
              display: "flex",
              alignContent: "center",
            }}
          >
            <PhotoAvatarInput
              register={register}
              profilePicture={profilePicture}
              setProfilePicture={setProfilePicture}
              name="photo"
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={6}>
              <ControlledInput
                controllerProps={{
                  name: "title",
                  control,
                  rules: {
                    maxLength: {
                      value: 50,
                      message: `${t("forms:errorMessages.maxLength", {
                        value: 50,
                      })}`,
                    },
                    minLength: {
                      value: 3,
                      message: `${t("forms:errorMessages.minLength", {
                        value: 3,
                      })}`,
                    },
                    required: {
                      value: true,
                      message: `${t("forms:errorMessages.required")}`,
                    },
                  },
                }}
                handleChange={handleChange}
                label={t("common:title")}
              />
            </Grid>
            <Grid item xs={9} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12} className="quill editor">
              <ReactQuill
                value={getValues("content")}
                onChange={handleContent}
                theme="snow"
                modules={QuillModules}
                formats={QuillFormats}
                placeholder={t("common:content")}
                className="quillClasses"
              />
            </Grid>
            <Grid item xs={9} />
          </Grid>
        </Grid>
      </Grid>
    </Item>
  );
};

FeedGeneralInfos.defaultProps = {
  setIsDirty: undefined,
};
export default FeedGeneralInfos;
