import React, { useContext, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tenant, TenantForm } from "@commr/admin/models/tenant";
import tabProps from "@commr/admin/utils/tabUtils";
import {
  createTenant,
  updateTenant,
} from "@commr/admin/services/tenantsService";
import { AppContext } from "@commr/admin/context/appContext";
import FormFooterActions from "@commr/admin/components/common/formFooterActions";
import TabPanel from "@commr/admin/components/common/tabs/tabPanel";
import TenantProfileInfosForm from "@commr/admin/components/tenants/forms/tenantInfosForm";
import TenantToTenantForm from "@commr/admin/components/tenants/mappers/tenantToTenantForm";
import TenantFormToTenant from "@commr/admin/components/tenants/mappers/tenantFormToTenant";
import ResidenciesContainer from "../residencies/residenciesContainer";

interface Props {
  tenant: Tenant;
}

const TenantDataContainer: React.FC<React.PropsWithChildren<Props>> = ({
  tenant,
}) => {
  const { showToast } = useContext(AppContext);
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const { t } = useTranslation();
  const defaultValues = tenant
    ? TenantToTenantForm.map(tenant, TenantForm, Tenant)
    : new TenantForm();
  const { control, handleSubmit, reset } = useForm<TenantForm>({
    defaultValues,
  });

  const isUpdate = tenant.id !== "";

  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    event.isDefaultPrevented();
    setCurrentTabIndex(tabIndex);
  };

  const handleCancel = () => {
    reset(defaultValues);
  };

  const onSubmit = (tenantForm: TenantForm) => {
    setIsSaving(true);
    const newTenant = TenantFormToTenant.map(tenantForm, Tenant, TenantForm);

    let submitAction;

    if (tenant.id) {
      newTenant.id = tenant.id;
      submitAction = updateTenant;
    } else {
      submitAction = createTenant;
    }

    submitAction(newTenant)
      .then(() => {
        showToast(t("forms:successMessages.tenantSaved"), "success");

        setTimeout(() => {
          setIsSaving(false);
          history.push("/tenants");
        }, 2500);
      })
      .catch((error) => {
        const errorCode = error;
        let errorMessageTranslation;
        console.log(errorCode);
        switch (errorCode) {
          case "UsernameExistsException":
            errorMessageTranslation = "emailAlReadyExists";
            break;
          default:
            errorMessageTranslation = "saveFailed";
            break;
        }

        showToast(t(`forms:errorMessages.${errorMessageTranslation}`), "error");
        setIsSaving(false);
      });
  };

  return (
    <Box
      sx={{ width: "100%", flexGrow: 1 }}
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      autoComplete="no"
      noValidate
    >
      <Box
        sx={{
          borderBottom: 0,
          borderColor: "divider",
          width: "100%",
        }}
      >
        <Tabs
          sx={{ width: "100%" }}
          className="tab-styles"
          value={currentTabIndex}
          onChange={handleChange}
          aria-label="tenants tabs"
        >
          <Tab {...tabProps(0, t("common:profile"))} />
          {isUpdate && <Tab {...tabProps(1, t("userForm:residencies"))} />}
        </Tabs>
      </Box>
      <Grid container sx={{ display: "block" }}>
        <TabPanel value={currentTabIndex} index={0}>
          <TenantProfileInfosForm isUpdate={isUpdate} control={control} />
        </TabPanel>
        {isUpdate && (
          <TabPanel value={currentTabIndex} index={1}>
            <ResidenciesContainer tenant={tenant} />
          </TabPanel>
        )}
        <FormFooterActions isSaving={isSaving} handleCancel={handleCancel} />
      </Grid>
    </Box>
  );
};

export default TenantDataContainer;
