import RoutesData from "@commr/admin/models/common/iRoutesData";
import ResidentsPage from "../../residents/residentsPage";
import AddFeedsPage from "../../feeds/addFeedPage";
import FeedsInfoPage from "../../feeds/feedsInfoPage";

const residentsRoutesData: RoutesData[] = [
  {
    path: "/tenant/:tenantId/residency/:residencyId/feeds/addFeed",
    component: AddFeedsPage,
    title: "route:addFeed",
  },
  {
    path: "/tenant/:tenantId/residency/:residencyId/feed/:feedId",
    component: FeedsInfoPage,
    title: "route:editFeed",
  },
  {
    path: "/tenant/:tenantId/residency/:residencyId/residents",
    component: ResidentsPage,
    title: "route:residents",
  },
];

export default residentsRoutesData;
