import RoutesData from "@commr/admin/models/common/iRoutesData";
import RoleUpdatePage from "../../roles/roleUpdatePage";
import AddRolePage from "../../roles/addRole";
import RolesPage from "../../roles/rolesPage";

const rolesRoutesData: RoutesData[] = [
  {
    path: "/roles/addRole",
    component: AddRolePage,
    title: "route:addRole",
  },
  {
    path: "/roles/:roleId",
    component: RoleUpdatePage,
    title: "route:updateRole",
  },
  {
    path: "/roles",
    component: RolesPage,
    title: "route:roles",
  },
];

export default rolesRoutesData;
