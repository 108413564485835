import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";
import { Grid, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  AppSyncSearchQuery,
  AppSyncSearchResult,
} from "@commr/admin/models/common/appSyncSearch";
import { getResidents } from "@commr/admin/services/residentService";
import { Residency } from "@commr/admin/models/residency/residency";
import CustomSearchHeader from "../common/customSearchHeader/customSearchHeader";
import SearchedUserResult from "../common/search/searchResult";
import ResidentHit from "./residentHit";

interface Props {
  residency: Residency;
}

const ResidentsContainer: React.FC<React.PropsWithChildren<Props>> = ({
  residency,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [searchResult, setSearchResult] = useState<AppSyncSearchResult>();
  const [searchQuery, setSearchQuery] = useState<AppSyncSearchQuery>(
    new AppSyncSearchQuery()
  );

  useEffect(() => {
    if (searchQuery)
      getResidents(searchQuery, residency.externalId, residency.tenantId).then(
        (result: AppSyncSearchResult) => {
          setSearchResult(result);
        }
      );
  }, [searchQuery]);

  const onAddClicked = () => {
    history.push(
      `/tenant/${residency.tenantId}/residency/${residency.externalId}/residents/addResident`
    );
  };

  return (
    <div className="residents-container">
      <CustomSearchHeader setSearchQuery={setSearchQuery} />
      <Grid item xs={2} sx={{ paddingTop: 1, paddingBottom: 1 }}>
        <Button
          type="button"
          variant="contained"
          size="medium"
          color="primary"
          onClick={onAddClicked}
        >
          <Add />
          {t("residents:add")}
        </Button>
      </Grid>
      <SearchedUserResult
        hitComponent={
          <ResidentHit
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            searchResult={searchResult}
          />
        }
      />
    </div>
  );
};

export default ResidentsContainer;
