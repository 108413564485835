import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { AppContext } from "@commr/admin/context/appContext";
import { UserContext } from "@commr/admin/context/userContext";
import { Admin, AdminForm } from "@commr/admin/models/user/admin/admin";
import { setUserProfilePicture } from "@commr/admin/utils/userUtils";
import { Media, PathType } from "@commr/admin/models/common/media";
import { updateAdmin } from "@commr/admin/services/adminService";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./styles/profileInfo.scss";
import AdminFormToAdmin from "../admins/mappers/adminFormToAdmin";
import AdminToAdminForm from "../admins/mappers/adminToAdminForm";
import ControlledInput from "../common/formFields/controlledInput";
import FormFooterActions from "../common/formFooterActions";
import PhotoAvatarInput from "../common/formFields/photoAvatarInput";

const ProfileInfos: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { showToast } = useContext(AppContext);
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [profilePicture, setProfilePicture] = useState(user.profilePicture);
  const [isSaving, setIsSaving] = useState(false);
  const defaultValues = user
    ? AdminToAdminForm.map(user, AdminForm, Admin)
    : new AdminForm();
  const { control, handleSubmit, reset, register } = useForm<AdminForm>({
    defaultValues,
  });

  const handleCancel = () => {
    reset(defaultValues);
    setProfilePicture(user ? user.profilePicture : "");
  };

  const onSubmit = (adminForm: AdminForm) => {
    setIsSaving(true);
    const submitAction = updateAdmin;
    const updatedUser = AdminFormToAdmin.map(adminForm, Admin, AdminForm);
    const currentProfilePicture: Media | undefined = setUserProfilePicture(
      updatedUser.profilePicture,
      profilePicture,
      user.tenantID,
      user.residencyID ?? "",
      updatedUser.id,
      PathType.Admins,
      user.isSuper
    );
    updatedUser.tenantID = user.tenantID;
    updatedUser.residencyID = user.residencyID;
    updatedUser.profilePicture = profilePicture;

    submitAction(updatedUser, currentProfilePicture)
      .then(() => {
        showToast(t("forms:successMessages.adminSaved"), "success");
        setTimeout(() => {
          setIsSaving(false);
          history.push("/profile");
        }, 2500);
      })
      .catch((error) => {
        console.log("error", error);
        const errorCode = error;
        let errorMessageTranslation;
        switch (errorCode) {
          default:
            errorMessageTranslation = "saveFailed";
            break;
        }

        showToast(t(`forms:errorMessages.${errorMessageTranslation}`), "error");
        setIsSaving(false);
      });
  };

  return (
    <Paper className="user-profile-general-infos">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <div className="profile-back-btn">
                  <Typography
                    variant="h5"
                    className="paper-title"
                    data-testid="profile-title-label"
                  >
                    {t("profile:profileTitle")}
                  </Typography>
                </div>
                <Divider variant="middle" />
              </Grid>
              <Grid item alignSelf="center">
                {user.profilePicture.length > 0 ? (
                  <PhotoAvatarInput
                    name="profilePicture"
                    register={register}
                    profilePicture={profilePicture}
                    setProfilePicture={setProfilePicture}
                  />
                ) : (
                  <Avatar
                    className="avatar-initials"
                    sx={{ height: 200, width: 200 }}
                  >
                    {`${user.firstName?.substring(0, 1)}
                                    ${user.lastName?.substring(0, 1)}`}
                  </Avatar>
                )}
              </Grid>
              <Grid item alignSelf="center">
                <label htmlFor="contained-button-file">
                  <Button
                    className="profile-picture-button"
                    data-testid="profile-picture-button"
                    variant="contained"
                    component="span"
                  >
                    <Edit data-testid="EditIcon" />
                    {t("common:profilePic")}
                  </Button>
                </label>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="grid-align-center">
            <ControlledInput
              controllerProps={{
                name: "firstName",
                control,
                rules: {
                  maxLength: {
                    value: 50,
                    message: `${t("forms:errorMessages.maxLength", {
                      value: 50,
                    })}`,
                  },
                  minLength: {
                    value: 3,
                    message: `${t("forms:errorMessages.minLength", {
                      value: 3,
                    })}`,
                  },
                  required: {
                    value: true,
                    message: `${t("forms:errorMessages.required")}`,
                  },
                },
              }}
              name="firstName"
              label={t("common:firstName")}
            />
          </Grid>
          <Grid item xs={12} className="grid-align-center">
            <ControlledInput
              controllerProps={{
                name: "lastName",
                control,
                rules: {
                  maxLength: {
                    value: 50,
                    message: `${t("forms:errorMessages.maxLength", {
                      value: 50,
                    })}`,
                  },
                  minLength: {
                    value: 3,
                    message: `${t("forms:errorMessages.minLength", {
                      value: 3,
                    })}`,
                  },
                  required: {
                    value: true,
                    message: `${t("forms:errorMessages.required")}`,
                  },
                },
              }}
              name="lastName"
              label={t("common:lastName")}
            />
          </Grid>
          <Grid item xs={12} className="grid-align-center">
            <ControlledInput
              controllerProps={{
                name: "email",
                control,
                rules: {
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: `${t("forms:errorMessages.invalidEmail")}`,
                  },
                },
              }}
              name="email"
              label={t("common:email")}
            />
          </Grid>
          <FormFooterActions isSaving={isSaving} handleCancel={handleCancel} />
        </Grid>
      </form>
    </Paper>
  );
};

export default ProfileInfos;
