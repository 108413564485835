import { Divider, Grid, Paper, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import { Relation } from "@commr/admin/models/appsync";
import { CircleMemberForm } from "@commr/admin/models/user/circleMember/circleMember";
import ControlledSelect from "../../common/formFields/controlledSelect";

interface Props {
  control: Control<CircleMemberForm, any>;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CircleMemberRelationInfos: React.FC<React.PropsWithChildren<Props>> = ({
  control,
  setIsDirty,
}) => {
  const { t } = useTranslation();

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

  const relationOptions = Object.values(Relation).map((relation) => {
    return {
      value: relation,
      name: t(`familyMember:relation.${relation.toLowerCase()}`),
      isDisabled: false,
    };
  });

  const handleSelectChange = () => {
    if (setIsDirty) setIsDirty(true);
  };

  return (
    <Item>
      <Grid item xs={12} sx={{ paddingBottom: 2 }}>
        <Typography variant="h5">{t("userForm:RelationInfo")}</Typography>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={6}>
          <ControlledSelect
            handleChange={handleSelectChange}
            controllerProps={{
              name: "relation",
              control,
            }}
            label={t("residents:circle.relation")}
            options={relationOptions}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <ControlledSelect
            controllerProps={{
              name: "role",
              control,
            }}
            label={t("residents:circle.role")}
            options={roleOptions}
          />
        </Grid> */}
      </Grid>
    </Item>
  );
};
CircleMemberRelationInfos.defaultProps = {
  setIsDirty: undefined,
};

export default CircleMemberRelationInfos;
