import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { AppSyncSearchQuery } from "@commr/admin/models/common/appSyncSearch";

interface Props {
  inputPlaceholder: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<AppSyncSearchQuery>>;
}

const SearchBox: React.FC<React.PropsWithChildren<Props>> = ({
  inputPlaceholder,
  setSearchQuery,
}) => {
  const [timerId, setTimerId] = useState<any>();

  const onChangeDebounced = (event: any) => {
    const currentValue = event.currentTarget.value;

    clearTimeout(timerId);
    setTimerId(
      setTimeout(
        () =>
          setSearchQuery((old) => ({
            ...old,
            query: currentValue,
          })),
        250
      )
    );
  };

  return (
    <Paper
      component="form"
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        data-testid="search-input"
        placeholder={inputPlaceholder}
        inputProps={{ "aria-label": "search google maps" }}
        onChange={onChangeDebounced}
      />
      <IconButton
        data-testid="search-button"
        type="button"
        sx={{ p: "10px" }}
        aria-label="search"
      >
        <SearchIcon data-testid="search-icon" />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
    </Paper>
  );
};
export default SearchBox;
