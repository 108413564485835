import { Admin } from "@commr/admin/models/user/admin/admin";
import { API, graphqlOperation } from "aws-amplify";
import { Media } from "@commr/admin/models/common/media";
import {
  AppSyncSearchQuery,
  AppSyncSearchResult,
} from "@commr/admin/models/common/appSyncSearch";
import {
  adminByCognitoId,
  createAdmins,
  fillAdminData,
  fillAdminsData,
  getAdmins,
  searchAdmins,
  updateAdmins,
} from "@commr/admin/utils/adminUtils";
import { createUser, uploadUserMedia } from "./userService";

export const createAdmin = async (admin: Admin, media?: Media) =>
  createUser(
    admin.email,
    admin.password!,
    admin.isSuper ? "SuperAdmins" : "Admins",
    true
  )
    .then(async (user) => {
      const input: any = {
        cognitoID: user.Attributes[0]?.Value,
        firstName: admin.firstName,
        lastName: admin.lastName,
        email: admin.email,
        isSuper: admin.isSuper,
        residencyID: admin.residencyID,
        tenantID: admin.tenantID,
      };

      if (media) input["profilePicture"] = await uploadUserMedia(media);

      await API.graphql(
        graphqlOperation(createAdmins, {
          input,
        })
      );
    })
    .catch((e) => {
      throw new Error(e);
    });

export const updateAdmin = async (admin: Admin, media?: Media) => {
  const input: any = {
    id: admin.id,
    firstName: admin.firstName,
    lastName: admin.lastName,
  };
  if (media) input["profilePicture"] = await uploadUserMedia(media);

  const updatedAdmin = await API.graphql(
    graphqlOperation(updateAdmins, {
      input,
    })
  );
  return updatedAdmin;
};

export const getAdminsByParameters = async (
  searchQuery: AppSyncSearchQuery,
  residencyId: string,
  isSuper: boolean
) => {
  const { query, limit, from } = searchQuery;

  const orFilter = [
    { firstName: { matchPhrasePrefix: query } },

    { lastName: { matchPhrasePrefix: query } },

    { id: { matchPhrasePrefix: query } },

    { email: { matchPhrasePrefix: query } },
  ];

  const superAdminFilter =
    query.length > 0 || ""
      ? {
          isSuper: { eq: isSuper },
          or: orFilter,
        }
      : { isSuper: { eq: isSuper } };

  const adminFilter =
    query.length > 0 || ""
      ? {
          residencyID: { eq: residencyId },
          isSuper: { eq: isSuper },
          or: orFilter,
        }
      : { residencyID: { eq: residencyId }, isSuper: { eq: isSuper } };

  const searchAdminsResult = (await API.graphql(
    graphqlOperation(searchAdmins, {
      filter: isSuper ? superAdminFilter : adminFilter,
      limit,
      from,
      sort: { direction: "desc", field: "createdAt" },
    })
  )) as {
    data: any;
  };
  const searchResult = searchAdminsResult?.data?.searchAdmins;
  let result = new AppSyncSearchResult([], 0, 0);

  if (searchResult) {
    result = new AppSyncSearchResult(
      fillAdminsData(searchResult.items),
      searchResult.total ?? 0,
      0
    );
  }

  return result;
};

export const getAdminById = async (id: string) => {
  const admin = (await API.graphql(graphqlOperation(getAdmins, { id }))) as {
    data: any;
  };
  return fillAdminData(admin?.data?.getAdmins);
};

export const getAdminByCognitoId = async (cognitoID: string) => {
  const admin = (await API.graphql(
    graphqlOperation(adminByCognitoId, { cognitoID })
  )) as {
    data: any;
  };

  return fillAdminData(admin?.data?.adminByCognitoId?.items[0]);
};
