import React, { createContext, useState } from "react";
import { defaultAppContext } from "@commr/admin/utils/contextUtils";

interface Props {
  children?: React.ReactNode;
  environment: any;
}

export const AppContext = createContext(defaultAppContext);
const AppContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  environment,
}) => {
  const [isSideMenuFold, setIsSideMenuFold] = useState(
    defaultAppContext.isSideMenuFold
  );

  const [toast, setToast] = useState(defaultAppContext.toast);

  const showToast = (
    message: string,
    severity: "error" | "success" | "info" | "warning"
  ) => {
    setToast({
      message,
      open: true,
      severity,
      onClose: () => {
        setToast({ ...toast, open: false, severity });
      },
    });
  };

  return (
    <AppContext.Provider
      value={{
        isSideMenuFold,
        toast,
        setIsSideMenuFold,
        showToast,
        environment,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.defaultProps = {
  children: undefined,
};

export default AppContextProvider;
