import React, { createContext, useState } from "react";
import { defaultResidencyContext } from "@commr/admin/utils/contextUtils";

export const ResidencyContext = createContext(defaultResidencyContext);

interface Props {
  children?: React.ReactNode;
}

const ResidencyContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  const [residency, setResidency] = useState(defaultResidencyContext.residency);

  return (
    <ResidencyContext.Provider
      value={{
        residency,
        setResidency,
      }}
    >
      {children}
    </ResidencyContext.Provider>
  );
};

ResidencyContextProvider.defaultProps = {
  children: undefined,
};

export default ResidencyContextProvider;
