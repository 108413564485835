import React, { useContext, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Role, RoleForm } from "@commr/admin/models/role/role";
import tabProps from "@commr/admin/utils/tabUtils";
import { AppContext } from "@commr/admin/context/appContext";
import { createRole, updateRole } from "@commr/admin/services/roleService";
import TabPanel from "../common/tabs/tabPanel";
import FormFooterActions from "../common/formFooterActions";
import RoleGeneralInfos from "./forms/roleGeneralInfos";

interface Props {
  role: Role;
}

const RoleDataContainer: React.FC<React.PropsWithChildren<Props>> = ({
  role,
}) => {
  const { showToast } = useContext(AppContext);
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const { t } = useTranslation();
  const defaultValues = role.id ? role : new Role();
  const { control, handleSubmit, reset } = useForm<RoleForm>({
    defaultValues,
  });

  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    event.isDefaultPrevented();
    setCurrentTabIndex(tabIndex);
  };

  const handleCancel = () => {
    reset(defaultValues);
  };

  const onSubmit = (roleForm: RoleForm) => {
    setIsSaving(true);
    const newRole = new Role();
    newRole.role = roleForm.role;

    let submitAction;

    if (role.id) {
      newRole.id = role.id;
      submitAction = updateRole;
    } else {
      submitAction = createRole;
    }

    submitAction(newRole)
      .then(() => {
        showToast(t("forms:successMessages.roleSaved"), "success");
        setTimeout(() => {
          setIsSaving(false);
          history.push("/roles");
        }, 2500);
      })
      .catch((error) => {
        const errorCode = error;
        console.log("error", error);
        let errorMessageTranslation;
        switch (errorCode) {
          case "UsernameExistsException":
            errorMessageTranslation = "roleAlreadyExists";
            break;
          default:
            errorMessageTranslation = "saveFailed";
            break;
        }

        showToast(t(`forms:errorMessages.${errorMessageTranslation}`), "error");
        setIsSaving(false);
      });
  };

  return (
    <Box
      sx={{ width: "100%", flexGrow: 1 }}
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      autoComplete="no"
      noValidate
    >
      <Box
        sx={{
          borderBottom: 0,
          borderColor: "divider",
          width: "100%",
        }}
      >
        <Tabs
          sx={{ width: "100%" }}
          className="tab-styles"
          value={currentTabIndex}
          onChange={handleChange}
          aria-label="roles tabs"
        >
          <Tab {...tabProps(0, t("common:profile"))} />
        </Tabs>
      </Box>
      <Grid container sx={{ display: "block" }}>
        <TabPanel value={currentTabIndex} index={0}>
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12}>
              <RoleGeneralInfos control={control} />
            </Grid>
          </Grid>
        </TabPanel>
        <FormFooterActions isSaving={isSaving} handleCancel={handleCancel} />
      </Grid>
    </Box>
  );
};

export default RoleDataContainer;
