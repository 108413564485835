import {
  AppSyncSearchQuery,
  AppSyncSearchResult,
} from "@commr/admin/models/common/appSyncSearch";
import { Media } from "@commr/admin/models/common/media";
import { CircleMember } from "@commr/admin/models/user/circleMember/circleMember";
import {
  createCircleMemberGql,
  createResidentCircleMemberRelation,
  deleteResidentCircleMemberRelation,
  fillCircleMemberData,
  getCircleMember,
  updateCircleMemberGql,
  updateResidentCircleMemberRelation,
} from "@commr/admin/utils/circleMemberUtils";
import { searchCircleMembers } from "@commr/livia/backend/graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import {
  createUser,
  createUserConversationsConnection,
  uploadUserMedia,
} from "./userService";

export const getAllCircleMembersNotRelated = async (
  searchQuery: AppSyncSearchQuery,
  existingCircleMembers: string[]
) => {
  const { query, limit, from } = searchQuery;

  const excludedIds = existingCircleMembers.map((id) => ({
    id: { ne: id },
  }));
  const filter =
    query.length > 0 || ""
      ? {
          or: [
            { firstName: { matchPhrasePrefix: query } },
            { lastName: { matchPhrasePrefix: query } },
            { id: { matchPhrasePrefix: query } },
            { email: { matchPhrasePrefix: query } },
          ],
          and: excludedIds,
        }
      : {
          and: excludedIds,
        };

  const response = (await API.graphql(
    graphqlOperation(searchCircleMembers, {
      filter,
      limit,
      from,
      sort: { direction: "desc", field: "createdAt" },
    })
  )) as {
    data: any;
  };

  const searchResult = response?.data?.searchCircleMembers;
  const circleMembers = searchResult.items;
  const result = new AppSyncSearchResult(
    circleMembers,
    searchResult.total ?? 0,
    0
  );

  return result;
};

export const createCircleMember = async (
  circleMember: CircleMember,
  externalID: string,
  residencyID: string,
  tenantID: string,
  media?: Media
): Promise<void> => {
  let user;
  try {
    // Créer un utilisateur
    user = await createUser(
      circleMember.email,
      circleMember.password!,
      "Members",
      true
    );
  } catch (error: any) {
    console.warn(` createUser error : ${error.message}`);
  }

  try {
    // Créer une connexion de conversation pour l'utilisateur
    const conversationConnection = await createUserConversationsConnection();
    if (!conversationConnection) {
      throw new Error("createUserConversationsConnection error");
    }

    const input: any = {
      id: circleMember.id,
      firstName: circleMember.firstName,
      lastName: circleMember.lastName,
      email: circleMember.email,
      sex: circleMember.sex,
      conversationsConnectionID: conversationConnection.id,
      cognitoID: user.Attributes[0]?.Value,
      address: {
        city: circleMember.address.city,
        civicNumber: circleMember.address.civicNumber,
        country: circleMember.address.civicNumber,
        postalCode: circleMember.address.postalCode,
        province: circleMember.address.province,
        street: circleMember.address.street,
      },
    };

    // Téléverser les médias de l'utilisateur, s'il y en a
    if (media) input["profilePicture"] = await uploadUserMedia(media);

    // Créer un nouveau CircleMember dans la base de données avec les données JSON remplies
    const newCircleMember = (await API.graphql(
      graphqlOperation(createCircleMemberGql, {
        input,
      })
    )) as { data: any };

    await API.graphql(
      graphqlOperation(createResidentCircleMemberRelation, {
        input: {
          circleMemberID: newCircleMember.data?.createCircleMember?.id,
          relation: circleMember.relation,
          externalID,
          residencyID,
          tenantID,
        },
      })
    );
  } catch (error: any) {
    throw new Error(`createCircleMember error : ${error.message}`);
  }
};

export const updateCircleMember = async (
  circleMember: CircleMember,
  externalID: string,
  residencyID: string,
  tenantID: string,
  media?: Media
) => {
  const input: any = {
    id: circleMember.id,
    firstName: circleMember.firstName,
    lastName: circleMember.lastName,
    email: circleMember.email,
    sex: circleMember.sex,
    address: {
      city: circleMember.address.city,
      civicNumber: circleMember.address.civicNumber,
      country: circleMember.address.civicNumber,
      postalCode: circleMember.address.postalCode,
      province: circleMember.address.province,
      street: circleMember.address.street,
    },
  };

  if (media) input["profilePicture"] = await uploadUserMedia(media);

  const updatedCircleMember = await API.graphql(
    graphqlOperation(updateCircleMemberGql, {
      input,
    })
  );

  await API.graphql(
    graphqlOperation(updateResidentCircleMemberRelation, {
      input: {
        circleMemberID: circleMember.id,
        relation: circleMember.relation,
        externalID,
        residencyID,
        tenantID,
      },
    })
  );

  return updatedCircleMember;
};

export const getCircleMemberById = async (id: string, residentId: string) => {
  const circleMember = (await API.graphql(
    graphqlOperation(getCircleMember, { id })
  )) as {
    data: any;
  };
  const circleMemberData = fillCircleMemberData(
    circleMember?.data?.getCircleMember,
    residentId
  );

  return circleMemberData as unknown as CircleMember;
};

export const removeResidentCircleMemberRelation = async (
  circleMemberID: string,
  externalID: string,
  residencyID: string,
  tenantID: string
) => {
  const removeResidentCircleMemberData = {
    circleMemberID,
    externalID,
    residencyID,
    tenantID,
  };

  const removedResidentCircleMember = await API.graphql(
    graphqlOperation(deleteResidentCircleMemberRelation, {
      input: removeResidentCircleMemberData,
    })
  );

  return removedResidentCircleMember;
};
