import RoutesData from "@commr/admin/models/common/iRoutesData";
import SuperAdminProfilePage from "../../superAdmins/superAdminProfilePage";
import AddSuperAdminPage from "../../superAdmins/addSuperAdminPage";
import SuperAdminsPage from "../../superAdmins/superAdminsPage";

const adminsRoutesData: RoutesData[] = [
  {
    path: "/superAdmins/add",
    component: AddSuperAdminPage,
    title: "route:addSuperAdmin",
  },
  {
    path: "/superAdmin/:superAdminsId",
    component: SuperAdminProfilePage,
    title: "route:superAdminProfile",
  },
  {
    path: "/superAdmins/:refresh?",
    component: SuperAdminsPage,
    title: "route:superAdmins",
  },
];

export default adminsRoutesData;
