import { CircularProgress } from "@mui/material";
import React from "react";

const LoadingScreen: React.FC<React.PropsWithChildren<unknown>> = () => (
  <div className="loading-screen">
    <CircularProgress data-testid="circular-progress" size={60} />
  </div>
);

export default LoadingScreen;
