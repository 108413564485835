import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";
import { Grid, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  AppSyncSearchQuery,
  AppSyncSearchResult,
} from "@commr/admin/models/common/appSyncSearch";
import { getFeedsByResidencyId } from "@commr/admin/services/feedsService";
import CustomSearchHeader from "../common/customSearchHeader/customSearchHeader";
import SearchedUserResult from "../common/search/searchResult";
import FeedHit from "./feedHit";

interface Props {
  residencyId: string;
  tenantId: string;
}

const FeedsContainer: React.FC<Props> = ({ residencyId, tenantId }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [searchResult, setSearchResult] = useState<AppSyncSearchResult>();
  const [searchQuery, setSearchQuery] = useState<AppSyncSearchQuery>(
    new AppSyncSearchQuery()
  );

  useEffect(() => {
    if (searchQuery && residencyId) {
      getFeedsByResidencyId(searchQuery, residencyId).then(
        (feeds: AppSyncSearchResult) => {
          setSearchResult(feeds);
        }
      );
    }
  }, [searchQuery, residencyId]);

  const onAddClicked = () => {
    history.push(`/tenant/${tenantId}/residency/${residencyId}/feeds/addFeed`);
  };

  return (
    <div className="feeds-container">
      <CustomSearchHeader setSearchQuery={setSearchQuery} />
      <Grid item xs={2} sx={{ paddingTop: 1, paddingBottom: 1 }}>
        <Button
          type="button"
          variant="contained"
          size="medium"
          color="primary"
          onClick={onAddClicked}
        >
          <Add />
          {t("common:add")}
        </Button>
      </Grid>
      <SearchedUserResult
        hitComponent={
          <FeedHit
            searchResult={searchResult}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        }
      />
    </div>
  );
};

export default FeedsContainer;
