import { createMapper, mapFrom } from "@automapper/core";
import { classes } from "@automapper/classes";
import {
  Resident,
  ResidentForm,
} from "@commr/admin/models/user/resident/resident";
import { User, UserForm } from "@commr/admin/models/user/user";

const ResidentToResidentForm = createMapper({
  name: "ResidentToResidentForm",
  pluginInitializer: classes,
});

ResidentToResidentForm.createMap(Resident, User);
ResidentToResidentForm.createMap(User, UserForm, {
  extends: [ResidentToResidentForm.getMapping(Resident, User)],
})
  .forMember(
    (destination) => destination.address,
    mapFrom((source) => source.address)
  )
  .forMember(
    (destination) => destination.profilePicture,
    mapFrom((source) => source.profilePicture)
  )
  .forMember(
    (destination) => destination.sex,
    mapFrom((source) => source.sex)
  );

ResidentToResidentForm.createMap(Resident, ResidentForm, {
  extends: [ResidentToResidentForm.getMapping(User, UserForm)],
})
  .forMember(
    (destination) => destination.birthDate,
    mapFrom((source) => source.birthDate)
  )
  .forMember(
    (destination) => destination.profilePicture,
    mapFrom((source) => source.profilePicture)
  )
  .forMember(
    (destination) => destination.address,
    mapFrom((source) => source.address)
  )
  .forMember(
    (destination) => destination.address,
    mapFrom((source) => source.address)
  )
  .forMember(
    (destination) => destination.sex,
    mapFrom((source) => source.sex)
  );

export default ResidentToResidentForm;
