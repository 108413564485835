import React from "react";
import { Avatar, styled } from "@mui/material";
import { UseFormRegister } from "react-hook-form";
import { Edit } from "@mui/icons-material";
import { convertFileToBase64 } from "@commr/admin/utils/userUtils";

interface Props {
  profilePicture?: string;
  setProfilePicture: React.Dispatch<React.SetStateAction<string>>;
  register: UseFormRegister<any>;
  name: string;
}

const PhotoAvatarInput: React.FC<React.PropsWithChildren<Props>> = ({
  profilePicture,
  setProfilePicture,
  register,
  name,
}) => {
  const Input = styled("input")({
    display: "none",
  });

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label htmlFor="contained-button-file">
      <Input
        {...register(name, {
          onChange: (e) => {
            convertFileToBase64(
              e.target?.files ? e.target.files[0] : null
            ).then((data) => {
              if (typeof data === "string") setProfilePicture(data);
            });
          },
        })}
        data-testid="profile-picture-input"
        accept="image/*"
        id="contained-button-file"
        type="file"
      />
      <Avatar
        src={profilePicture}
        sx={{
          height: 200,
          width: 200,
        }}
      >
        <Edit color="action" />
      </Avatar>
    </label>
  );
};

PhotoAvatarInput.defaultProps = {
  profilePicture: undefined,
};

export default PhotoAvatarInput;
