import { CircularProgress } from "@mui/material";
import AdminDataContainer from "@commr/admin/components/admins/adminDataContainer";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAdminById } from "@commr/admin/services/adminService";
import { Admin } from "@commr/admin/models/user/admin/admin";
import { setUserPicture } from "@commr/admin/utils/userUtils";

const SuperAdminProfilePage: React.FC = () => {
  const [suoerAdmin, setSuperAdmin] = useState<Admin>();
  const { superAdminsId } = useParams<{ superAdminsId: string }>();

  useEffect(() => {
    console.log("ici");
    getAdminById(superAdminsId!).then((adminResponse) => {
      setUserPicture(adminResponse.profilePicture).then((picture) => {
        adminResponse.profilePicture = picture;
        setSuperAdmin(adminResponse);
      });
    });
  }, [superAdminsId]);

  if (!suoerAdmin) return <CircularProgress size={60} />;
  return <AdminDataContainer admin={suoerAdmin} isSuper />;
};

export default SuperAdminProfilePage;
