import LoadingScreen from "@commr/admin/components/common/layout/loadingScreen";
import { Tenant } from "@commr/admin/models/tenant";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTenantById } from "@commr/admin/services/tenantsService";
import TenantDataContainer from "../../components/tenants/tenantDataContainer";

const AddTenantPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [tenant, setTenant] = useState<Tenant>();
  const { tenantId } = useParams<{ tenantId: string }>();

  useEffect(() => {
    if (tenantId) {
      getTenantById(tenantId).then((user) => {
        setTenant(user);
      });
    } else {
      setTenant(new Tenant());
    }
  }, [tenantId]);

  if (!tenant) return <LoadingScreen />;
  return <TenantDataContainer tenant={tenant} />;
};

export default AddTenantPage;
