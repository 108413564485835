import React from "react";
import { TextField } from "@mui/material";
import { useController, UseControllerProps } from "react-hook-form";

interface Props {
  name?: string;
  controllerProps: UseControllerProps<any>;
  label?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  multiline?: boolean;
  rows?: string | number;
  type?: React.HTMLInputTypeAttribute;
  handleChange?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    initialValue: string
  ) => void;
}

const ControlledInput: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  controllerProps,
  label,
  isDisabled = false,
  isReadOnly,
  multiline = false,
  rows,
  type,
  handleChange,
}) => {
  const { field, fieldState } = useController(controllerProps);
  let initialValue = "";

  return (
    <TextField
      size="small"
      variant="outlined"
      data-testid={name}
      label={label}
      fullWidth
      required={!!controllerProps.rules?.required}
      disabled={isDisabled}
      multiline={multiline}
      error={!!fieldState.error}
      helperText={fieldState.error?.message}
      rows={rows}
      type={type}
      {...field}
      value={field.value}
      onFocus={(e) => {
        initialValue = e.target.value;
      }}
      onBlur={(e) => {
        if (handleChange) handleChange(e, initialValue);
      }}
      inputProps={{
        // autoComplete: "new-password",
        readOnly: isReadOnly ?? false,
      }}
    />
  );
};

ControlledInput.defaultProps = {
  label: undefined,
  isDisabled: undefined,
  isReadOnly: undefined,
  multiline: undefined,
  rows: undefined,
  type: undefined,
  handleChange: undefined,
  name: undefined,
};

export default ControlledInput;
