import React from "react";
import AppContextProvider from "./appContext";
import ResidencyContextProvider from "./residencyContext";
import TranslationContextProvider from "./translationContext";
import UserContextProvider from "./userContext";

interface Props {
  environment: any;
  children?: React.ReactNode;
}

const ContextProviders: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  environment,
}) => (
  <AppContextProvider environment={environment}>
    <ResidencyContextProvider>
      <TranslationContextProvider>
        <UserContextProvider>{children}</UserContextProvider>
      </TranslationContextProvider>
    </ResidencyContextProvider>
  </AppContextProvider>
);

ContextProviders.defaultProps = {
  children: undefined,
};

export default ContextProviders;
