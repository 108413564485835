import ResidencyDataContainer from "@commr/admin/components/residencies/residencyDataContainer";
import { Residency } from "@commr/admin/models/residency/residency";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  createResidency,
  getResidencyByExternalId,
} from "@commr/admin/services/residencyService";
import { ResidencyContext } from "@commr/admin/context/residencyContext";
import LoadingScreen from "@commr/admin/components/common/layout/loadingScreen";

const AddResidencyPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [currentResidency, setResidency] = useState<Residency>();
  const { residency } = useContext(ResidencyContext);

  const { tenantId, residencyId: liviaResidencyId } = useParams<{
    tenantId: string;
    residencyId: string;
  }>();

  useEffect(() => {
    if (liviaResidencyId && tenantId) {
      getResidencyByExternalId(tenantId, liviaResidencyId).then(
        (tenantResidency) => {
          if (tenantResidency) {
            setResidency(tenantResidency);
          } else if (residency.externalId !== "") {
            createResidency(residency).then((newResidency) => {
              setResidency(newResidency);
            });
          } else {
            // go back
          }
        }
      );
    }
  }, [residency, liviaResidencyId, tenantId]);

  if (!currentResidency) return <LoadingScreen />;
  return <ResidencyDataContainer residency={currentResidency} />;
};

export default AddResidencyPage;
