import { createMapper, mapFrom } from "@automapper/core";
import { classes } from "@automapper/classes";
import { Tenant, TenantForm } from "@commr/admin/models/tenant";

const TenantFormToTenant = createMapper({
  name: "TenantFormToTenant",
  pluginInitializer: classes,
});

TenantFormToTenant.createMap(TenantForm, Tenant)
  .forMember(
    (destination) => destination.name,
    mapFrom((source) => source.name)
  )
  .forMember(
    (destination) => destination.creationDate,
    mapFrom((source) => source.creationDate)
  )
  .forMember(
    (destination) => destination.credentials,
    mapFrom((source) => source.credentials)
  );

export default TenantFormToTenant;
