import RoutesData from "@commr/admin/models/common/iRoutesData";
import AddResidentPage from "../pages/residents/addResidentPage";
import ResidentProfilePage from "../pages/residents/residentProfilePage";
import AddTenantPage from "../pages/tenants/addTenantPage";
import ResidenciesPage from "../pages/residencies/residenciesPage";

const appRoutesData: RoutesData[] = [
  {
    path: "/tenant/:tenantId/residency/:residencyId/residents/addResident",
    component: AddResidentPage,
    title: "route:addResident",
  },
  {
    path: "/tenant/:tenantId/residency/:residencyId/resident/:residentId/:tab?",
    component: ResidentProfilePage,
    title: "route:residentProfile",
  },

  {
    path: "/tenants/addTenant",
    component: AddTenantPage,
    title: "route:tenants",
  },
  {
    path: "/tenant/:tenantId",
    component: AddTenantPage,
    title: "route:tenantProfile",
  },
  {
    path: "/Residencies",
    component: ResidenciesPage,
    title: "route:Residency",
  },
];

export default appRoutesData;
