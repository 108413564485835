import React from "react";
import { Grid, Paper } from "@mui/material";

interface Props {
  hitComponent: JSX.Element;
}

const SearchedUserResult: React.FC<React.PropsWithChildren<Props>> = ({
  hitComponent,
}) => (
  <Paper className="searched-user-container">
    <Grid container direction="column" className="searched-table">
      {hitComponent}
    </Grid>
  </Paper>
);

export default SearchedUserResult;
