import { UserType } from "../user/user";

export enum MediaType {
  ProfilePicture = "ProfilePicture",
  Album = "Album",
}

export enum PathType {
  Admins = "Admins",
  Residents = "Residents",
  CircleMembers = "CircleMembers",
  Professionals = "Professionals",
  Tenants = "Tenants",
  Feeds = "Feeds",
}

export interface Media {
  media: File;
  userType: PathType;
  fileName: string;
  mediaType: MediaType;
  path: string;
}
