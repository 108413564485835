import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { GridActionsCellItem, GridPaginationModel } from "@mui/x-data-grid-pro";
import {
  AppSyncSearchQuery,
  AppSyncSearchResult,
} from "@commr/admin/models/common/appSyncSearch";
import { RoleRow } from "@commr/admin/models/role/role";
import CustomDataGrid from "../common/customDataGrid";

interface Props {
  searchResult: AppSyncSearchResult;
  searchQuery: AppSyncSearchQuery;
  setSearchQuery: Dispatch<SetStateAction<AppSyncSearchQuery>>;
}

const RoleHit: React.FC<React.PropsWithChildren<Props>> = ({
  searchResult,
  searchQuery,
  setSearchQuery,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [RoleRows, setRoleRows] = useState<RoleRow[]>([]);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: searchQuery.limit,
  });

  const rolesColumns: any = [
    {
      field: "role",
      headerName: t("common:roleName"),
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      flex: 0.3,
      align: "center",
      headerAlign: "center",
      getActions: (params: any) => [
        <GridActionsCellItem
          label={t("common:profile")}
          onClick={() => history.push(`/roles/${params.id}`)}
          showInMenu
        />,
      ],
    },
  ];

  useEffect(() => {
    setRoleRows([]);
    searchResult.items?.map(async (hit) => {
      const role: RoleRow = {
        id: hit.id,
        role: hit.role,
      };
      setRoleRows((dataGridRows) => [...dataGridRows, role]);
    });
  }, [searchResult]);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
    setSearchQuery((old) => ({
      ...old,
      from: newPaginationModel.page * searchQuery.limit!,
    }));
  };

  const gridProps = {
    pagination: true,
    rowCount: searchResult.total,
    rows: RoleRows,
    loading: !searchResult.items,
    columns: rolesColumns,
    paginationMode: "server",
    onPaginationModelChange: handlePaginationModelChange,
    paginationModel,
    disableColumnMenu: true,
    disableSelectionOnClick: true,
    disableColumnSelector: true,
    initialState: {
      sorting: {
        sortModel: [{ field: "creationDate", sort: "asc" }],
      },
    },
  };

  return (
    <div style={{ height: 700, width: "100%" }}>
      <CustomDataGrid attribute={gridProps} />
    </div>
  );
};

export default RoleHit;
