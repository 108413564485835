import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";
import { AppSyncSearchQuery } from "@commr/admin/models/common/appSyncSearch";
import CustomSearchBox from "../search/customSearchBar";
import "./customSearchHeader.scss";

interface Props {
  setSearchQuery: Dispatch<SetStateAction<AppSyncSearchQuery>>;
}

const CustomSearchHeader: React.FC<Props> = ({ setSearchQuery }) => {
  const { t } = useTranslation();

  return (
    <Paper className="custom-header-container">
      <div className="custom-header-searchbar">
        <CustomSearchBox
          setSearchQuery={setSearchQuery}
          inputPlaceholder={t("common:search")}
        />
      </div>
    </Paper>
  );
};

export default CustomSearchHeader;
