export class AppSyncSearchQuery {
  query = "";

  from = 0;

  limit? = 10;
}

export class AppSyncSearchResult {
  constructor(items: any[], total: number, from: number) {
    this.items = items;
    this.total = total;
    this.from = from;
  }

  items?: any[] = undefined;

  total = 0;

  from = 0;
}
