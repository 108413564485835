import { API, graphqlOperation } from "aws-amplify";
import { Media } from "@commr/admin/models/common/media";
import {
  createConversationGql,
  createUserConversationsConnectionGql,
  createUserConversationsGql,
  deleteUserConversationsGql,
  getUserConversationsConnection,
  updateConversationGql,
} from "@commr/admin/utils/userUtils";
import { adminCreateUser } from "./cognitoService";
import { uploadMedia } from "./mediaService";

export const uploadUserMedia = async (media: Media) => {
  const { key } = await uploadMedia(media);

  return key;
};

export const uploadProfilePicture = (userId: string, photo: Media) => {
  uploadUserMedia(photo)
    .then((key) => console.log("key", key))
    .catch((error) => {
      console.log("error", error);
    });
  console.log("first", userId, photo);
};

export const saveProfilePicture = (userId: string, profilePicture: string) => {
  console.log("first", userId, profilePicture);
};

export const createUser = (
  email: string,
  password: string,
  groupname: string,
  isConfirmed: boolean
) => adminCreateUser(email, password, groupname, isConfirmed);

export const createUserConversationsConnection = async () => {
  const createdConversationsConnection = (await API.graphql({
    query: createUserConversationsConnectionGql,
    variables: {
      input: {},
    },
  })) as { data: any };
  return createdConversationsConnection.data.createUserConversationsConnection;
};

export const createUserConversation = async (
  userId: string,
  connectionId: string,
  conversationId: string,
  isFamilyConversation: boolean
) => {
  const createdConversations = (await API.graphql({
    query: createUserConversationsGql,
    variables: {
      input: {
        userId,
        userConversationsConnectionUserConversationsId: connectionId,
        conversationId,
        isFamilyConversation,
      },
    },
  })) as { data: any };
  return createdConversations.data;
};

export const deleteUserConversation = async (
  userId: string,
  conversationId: string
) => {
  const deleteUserConversations = (await API.graphql({
    query: deleteUserConversationsGql,
    variables: {
      input: {
        userId,
        conversationId,
      },
    },
  })) as { data: any };
  return deleteUserConversations.data;
};

export const createConversation = async (userId: string) => {
  const createdConversation = (await API.graphql({
    query: createConversationGql,
    variables: {
      input: {
        participants: [userId],
      },
    },
  })) as { data: any };
  return createdConversation.data;
};

export const updateConversation = async (
  conversationId: string,
  participants: string[]
) => {
  const updatedConversation = (await API.graphql({
    query: updateConversationGql,
    variables: {
      input: {
        id: conversationId,
        participants,
      },
    },
  })) as { data: any };
  return updatedConversation.data;
};

export const getUserConversationsConnectionById = async (id: string) => {
  const userConversationConnection = (await API.graphql(
    graphqlOperation(getUserConversationsConnection, { id })
  )) as {
    data: any;
  };

  return userConversationConnection.data;
};
