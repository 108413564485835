import React from "react";
import { Grid } from "@mui/material";
import { Control, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { ResidentForm } from "@commr/admin/models/user/resident/resident";
import UserConnectionInfos from "@commr/admin/components/users/forms/userConnectionInfos";
import ResidentGeneralInfos from "@commr/admin/components/residents/forms/residentGeneralInfos";
import ResidentResidencyInfos from "./residentResidencyInfos";

interface Props {
  profilePicture: string;
  control: Control<ResidentForm, any>;
  setProfilePicture: React.Dispatch<React.SetStateAction<string>>;
  setValue: UseFormSetValue<ResidentForm>;
  register: UseFormRegister<ResidentForm>;
  isUpdate: boolean;
}

const ProfileInfosForm: React.FC<React.PropsWithChildren<Props>> = ({
  profilePicture,
  control,
  setProfilePicture,
  setValue,
  register,
  isUpdate,
}) => (
  <Grid item container xs={12} spacing={1}>
    <Grid item xs={12}>
      <ResidentGeneralInfos
        control={control}
        profilePicture={profilePicture}
        setProfilePicture={setProfilePicture}
        register={register}
      />
    </Grid>

    <Grid item xs={6}>
      <ResidentResidencyInfos
        setValue={setValue}
        control={control}
        isUpdate={isUpdate}
      />
    </Grid>
    <Grid item xs={6}>
      <UserConnectionInfos control={control} isUpdate={isUpdate} />
    </Grid>
  </Grid>
);

export default ProfileInfosForm;
