import { CircularProgress } from "@mui/material";
import React, { useContext } from "react";
import { UserContext } from "@commr/admin/context/userContext";
import ProfileInfos from "./profileInfos";

const ProfileContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { user } = useContext(UserContext);

  if (!user) return <CircularProgress size={60} />;
  return (
    <div className="resident-profile-container">
      <ProfileInfos />
    </div>
  );
};

export default ProfileContainer;
