import { createMapper, mapFrom } from "@automapper/core";
import { classes } from "@automapper/classes";
import { Feed, FeedForm } from "@commr/admin/models/feed/feed";

const FeedFormToFeed = createMapper({
  name: "FeedFormToFeed",
  pluginInitializer: classes,
});

FeedFormToFeed.createMap(FeedForm, Feed)
  .forMember(
    (destination) => destination.id,
    mapFrom((source) => source.id)
  )
  .forMember(
    (destination) => destination.picture,
    mapFrom((source) => source.picture)
  )
  .forMember(
    (destination) => destination.title,
    mapFrom((source) => source.title)
  )
  .forMember(
    (destination) => destination.content,
    mapFrom((source) => source.content)
  )
  .forMember(
    (destination) => destination.residencyId,
    mapFrom((source) => source.residencyId)
  )
  .forMember(
    (destination) => destination.authorId,
    mapFrom((source) => source.authorId)
  )
  .forMember(
    (destination) => destination.creationDate,
    mapFrom((source) => source.creationDate)
  );

export default FeedFormToFeed;
