import { CircularProgress } from "@mui/material";
import AdminDataContainer from "@commr/admin/components/admins/adminDataContainer";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAdminById } from "@commr/admin/services/adminService";
import { Admin } from "@commr/admin/models/user/admin/admin";
import { setUserPicture } from "@commr/admin/utils/userUtils";

const AdminProfilePage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [admin, setAdmin] = useState<Admin>();
  const { adminId } = useParams<{ adminId: string }>();

  useEffect(() => {
    if (adminId)
      getAdminById(adminId).then((adminResponse) => {
        setUserPicture(adminResponse.profilePicture).then((picture) => {
          adminResponse.profilePicture = picture;
          setAdmin(adminResponse);
        });
      });
  }, [adminId]);

  if (!admin) return <CircularProgress size={60} />;
  return <AdminDataContainer admin={admin} isSuper={false} />;
};

export default AdminProfilePage;
