import { Resident } from "@commr/admin/models/user/resident/resident";
import { CircleMember } from "@commr/admin/models/user/circleMember/circleMember";
import { ResidentCircleMemberRelation } from "@commr/admin/models/appsync";
import { FillUserData } from "./userUtils";

export const addCircleMembersToResident = (
  circleMembers: ResidentCircleMemberRelation[]
) => {
  const currentCircleMembers: CircleMember[] = [];
  let currentCircleMember: CircleMember = new CircleMember();
  circleMembers?.forEach((data: any) => {
    if (data?.circleMember)
      currentCircleMember = FillUserData(data.circleMember) as CircleMember;
    currentCircleMember.relation = data?.relation;
    currentCircleMembers.push(currentCircleMember);
  });

  return currentCircleMembers;
};

export const fillResidentData = (residentResult: any) => {
  let resident: Resident = new Resident();
  if (residentResult) {
    resident = FillUserData(residentResult) as Resident;
    resident.birthDate = residentResult.birthDate;
    resident.externalId = residentResult.externalID;
    resident.tenantId = residentResult.tenantID;
    resident.residencyId = residentResult.residencyID;
    resident.sex = residentResult.sex;
    resident.profilePicture = residentResult.profilePicture;
    resident.cognitoId = residentResult.cognitoID ?? undefined;
    resident.circleMembers = addCircleMembersToResident(
      residentResult?.circleMembers?.items
    );
  }

  return resident;
};

export const fillResidentsData = (residentsResult: any) => {
  const residents: Resident[] = [];

  if (residentsResult) {
    residentsResult.forEach((item: any) => {
      residents.push(fillResidentData(item));
    });
  }
  return residents;
};

export const createResidentGql = /* GraphQL */ `
  mutation CreateResident(
    $input: CreateResidentInput!
    $condition: ModelResidentConditionInput
  ) {
    createResident(input: $input, condition: $condition) {
      cognitoID
      externalID
      firstName
      lastName
      email
      profilePicture
      conversationsConnectionID
      conversations {
        nextToken
        userConversations {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      residency {
        externalID
        tenantID
        tenant {
          id
          name
          createdAt
          updatedAt
        }
        residents {
          nextToken
        }
        name
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        createdAt
        updatedAt
      }
      residencyID
      tenantID
      sex
      birthDate
      address {
        civicNumber
        street
        postalCode
        province
        city
        country
      }
      circleMembers {
        items {
          circleMemberID
          relation
          externalID
          residencyID
          tenantID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const updateResidentGql = /* GraphQL */ `
  mutation UpdateResident(
    $input: UpdateResidentInput!
    $condition: ModelResidentConditionInput
  ) {
    updateResident(input: $input, condition: $condition) {
      cognitoID
      externalID
      firstName
      lastName
      email
      profilePicture
      conversationsConnectionID
      conversations {
        nextToken
        userConversations {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      residency {
        externalID
        tenantID
        tenant {
          id
          name
          createdAt
          updatedAt
        }
        residents {
          nextToken
        }
        name
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        createdAt
        updatedAt
      }
      residencyID
      tenantID
      sex
      birthDate
      address {
        civicNumber
        street
        postalCode
        province
        city
        country
      }
      circleMembers {
        items {
          circleMemberID
          relation
          externalID
          residencyID
          tenantID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const searchResidents = /* GraphQL */ `
  query SearchResidents(
    $filter: SearchableResidentFilterInput
    $sort: [SearchableResidentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableResidentAggregationInput]
  ) {
    searchResidents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        cognitoID
        externalID
        firstName
        lastName
        email
        profilePicture
        conversationsConnectionID
        conversations {
          nextToken
          id
          createdAt
          updatedAt
        }
        residency {
          externalID
          tenantID
          name
          createdAt
          updatedAt
        }
        residencyID
        tenantID
        sex
        birthDate
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        circleMembers {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const searchUserConversations = /* GraphQL */ `
  query SearchUserConversations(
    $filter: SearchableUserConversationsFilterInput
    $sort: [SearchableUserConversationsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserConversationsAggregationInput]
  ) {
    searchUserConversations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        associated {
          nextToken
        }
        userID
        conversationID
        conversation {
          id
          participants
          createdAt
          updatedAt
        }
        isFamilyConversation
        createdAt
        updatedAt
        userConversationsAssociatedId
        userConversationsAssociatedConversationID
        userConversationsConnectionUserConversationsId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
