import {
  AppSyncSearchQuery,
  AppSyncSearchResult,
} from "@commr/admin/models/common/appSyncSearch";
import { Resident } from "@commr/admin/models/user/resident/resident";
import { getAllCircleMembersNotRelated } from "@commr/admin/services/circleMemberService";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Modal, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CircleMemberHit from "../../circleMembers/circleMembersHit";
import SearchedUserResult from "../../common/search/searchResult";
import CustomSearchHeader from "../../common/customSearchHeader/customSearchHeader";
import "../styles/addExistingCircleMembers.scss";

interface Props {
  isModalOpen: boolean;
  resident: Resident;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const AddExistingCircleMembers: React.FC<Props> = ({
  isModalOpen,
  resident,
  setIsModalOpen,
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<AppSyncSearchQuery>(
    new AppSyncSearchQuery()
  );
  const [searchResult, setSearchResult] = useState<AppSyncSearchResult>();
  const handleClose = () => setIsModalOpen(false);

  const existingCircleMembersIds = resident.circleMembers.map((cm) => cm.id);

  useEffect(() => {
    if (resident) {
      getAllCircleMembersNotRelated(searchQuery, existingCircleMembersIds).then(
        (result: AppSyncSearchResult) => setSearchResult(result)
      );
    }
  }, [resident, searchQuery]);

  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modal-container">
        <div className="modal-header">
          <IconButton
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            <CloseIcon className="close-button" />
          </IconButton>
          <Typography variant="h6" className="modal-title">
            {t("residents:addExistingCircleMember")}
          </Typography>
        </div>
        <CustomSearchHeader setSearchQuery={setSearchQuery} />
        <SearchedUserResult
          hitComponent={
            <CircleMemberHit
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              searchResult={searchResult}
            />
          }
        />
      </div>
    </Modal>
  );
};

export default AddExistingCircleMembers;
