import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useController, UseControllerProps } from "react-hook-form";

interface Props {
  controllerProps: UseControllerProps<any>;
  label: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  options: {
    value: string | number | readonly string[] | undefined;
    name: string;
    isDisabled: boolean;
  }[];
  handleChange?: () => void;
}

const ControlledSelect: React.FC<React.PropsWithChildren<Props>> = ({
  controllerProps,
  label,
  isDisabled = false,
  options,
  handleChange,
}) => {
  const { field, fieldState } = useController(controllerProps);

  return (
    <FormControl size="small" fullWidth>
      <InputLabel id={`controlled-select-label-${controllerProps.name}`}>
        {label}
      </InputLabel>
      <Select
        labelId={`controlled-select-label-${controllerProps.name}`}
        label={label}
        autoWidth={false}
        disabled={isDisabled}
        required={!!controllerProps.rules?.required}
        error={!!fieldState.error}
        {...field}
        onBlur={() => {
          if (handleChange) handleChange();
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={`controlled-select-option-${option.name}`}
            value={option.value}
            disabled={option.isDisabled}
          >
            {option.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error>{fieldState.error?.message}</FormHelperText>
    </FormControl>
  );
};

ControlledSelect.defaultProps = {
  isRequired: undefined,
  isDisabled: undefined,
  handleChange: undefined,
};

export default ControlledSelect;
