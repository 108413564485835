import { Media, MediaType, PathType } from "@commr/admin/models/common/media";
import { Dayjs } from "dayjs";
import { UseFormSetValue } from "react-hook-form";
import ArrayData from "@commr/admin/models/common/arrayData";
import { User } from "@commr/admin/models/user/user";
import { getFileFromBase64, getMedia } from "./mediaUtils";

/**
 *
 * @param value
 * @param obj
 * @param prop
 */
export function addValueToObjectByKey<
  Key extends keyof User,
  Value extends User[Key]
>(value: Value, obj: User, prop: Key) {
  let newObj = obj[prop];
  newObj = value;
  return newObj;
}

export const birthDateToAge = (birthYear: Dayjs) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const age = currentYear - birthYear.year();
  return age;
};

export const removeAccents = (val: string) => {
  if (val !== null && val !== " ") {
    const parsed = val?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return parsed;
  }
  return val;
};
export const isValidEmail = (search: string): boolean => {
  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
  return regexp.test(search);
};

export const convertToArrayData = (stringArray: string[]) => {
  const newArrayData: ArrayData[] = [];
  if (stringArray !== undefined) {
    let index = 0;
    stringArray.map((element) => {
      newArrayData.push({ id: index.toString(), text: element });
      index += 1;
      return index;
    });
  }

  return newArrayData;
};

export const convertToArrayString = (arrayData: ArrayData[]) =>
  arrayData
    .filter((data) => data["text"]?.length > 0)
    .map((data) => data["text"]);

export const convertFileToBase64 = (file: File | null) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file) reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const copyObject = <T>(objectToCopy: T) =>
  JSON.parse(JSON.stringify(objectToCopy)) as T;

export const cleanUserData = (objectToJson: any) => {
  const json = JSON.parse(JSON.stringify(objectToJson));
  delete json.password;
  return json;
};

export const addToJson = (userJson: any, fields: any) => {
  const currentJson = {
    ...userJson,
    ...fields,
  };

  return currentJson;
};

export const FillUserJsonData = (user: any, connectionId?: string) => {
  let userJson: any = {
    cognitoID: user.cognitoId,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  };

  if (connectionId) {
    userJson = addToJson(userJson, { conversationsConnectionId: connectionId });
  }

  return userJson;
};

export const setUserProfilePicture = (
  defaultPicture: string,
  profilePicture: string,
  tenantId: string,
  residencyId: string,
  userId: string,
  pathType: PathType,
  isSuper?: boolean
) => {
  if (defaultPicture !== profilePicture && profilePicture !== "") {
    const media: Media = {
      media: getFileFromBase64(profilePicture, "profilePicture"),
      userType: pathType,
      fileName: "MediaType.ProfilePicture",
      mediaType: MediaType.ProfilePicture,
      path: !isSuper
        ? `tenants/${tenantId}/residencies/${residencyId}/${pathType}/${userId}/${MediaType.ProfilePicture}/profilePicture`
        : `superAdmins/${userId}/${MediaType.ProfilePicture}/profilePicture`,
    };

    return media;
  }
  return undefined;
};

export const setUserPicture = async (picture?: string) => {
  if (picture && picture !== "") {
    const media = await getMedia(picture);
    return media;
  }

  return " ";
};

export const fetchMedia = async (
  media: string,
  user: User,
  setValue: UseFormSetValue<any>,
  setProfilePicture: (value: React.SetStateAction<string>) => void
) => {
  if (user.profilePicture && user.profilePicture !== "") {
    setValue("profilePicture", media);
    setProfilePicture(media);
  }
};

export const FillUserData = (userResult: any) => {
  const currentUser = new User();

  if (!userResult) return currentUser;

  currentUser.id = userResult.id;
  currentUser.firstName = userResult.firstName;
  currentUser.lastName = userResult.lastName;
  currentUser.email = userResult.email;
  currentUser.profilePicture = userResult.profilePicture ?? "";
  currentUser.address = {
    city: userResult.address?.city ?? "",
    civicNumber: userResult.address?.civicNumber ?? "",
    country: userResult?.address?.civicNumber ?? "",
    postalCode: userResult?.address?.postalCode ?? "",
    province: userResult.address?.province ?? "",
    street: userResult.address?.street ?? "",
  };
  return currentUser;
};

export const createUserConversationsGql = /* GraphQL */ `
  mutation CreateUserConversations(
    $input: CreateUserConversationsInput!
    $condition: ModelUserConversationsConditionInput
  ) {
    createUserConversations(input: $input, condition: $condition) {
      associated {
        items {
          userID
          conversationID
          isFamilyConversation
          createdAt
          updatedAt
          userConversationsAssociatedId
          userConversationsAssociatedConversationID
          userConversationsConnectionUserConversationsId
        }
        nextToken
      }
      userID
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        calls {
          nextToken
        }
        participants
        userConversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      isFamilyConversation
      createdAt
      updatedAt
      userConversationsAssociatedId
      userConversationsAssociatedConversationID
      userConversationsConnectionUserConversationsId
    }
  }
`;

export const createUserConversationsConnectionGql = /* GraphQL */ `
  mutation CreateUserConversationsConnection(
    $input: CreateUserConversationsConnectionInput!
    $condition: ModelUserConversationsConnectionConditionInput
  ) {
    createUserConversationsConnection(input: $input, condition: $condition) {
      nextToken
      userConversations {
        items {
          userID
          conversationID
          isFamilyConversation
          createdAt
          updatedAt
          userConversationsAssociatedId
          userConversationsAssociatedConversationID
          userConversationsConnectionUserConversationsId
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;

export const createConversationGql = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      messages {
        items {
          authorID
          content
          id
          isSent
          conversationID
          createdAt
          updatedAt
        }
        nextToken
      }
      calls {
        items {
          authorID
          id
          isSent
          status
          roomID
          endedAt
          conversationID
          createdAt
          updatedAt
        }
        nextToken
      }
      participants
      userConversations {
        items {
          userID
          conversationID
          isFamilyConversation
          createdAt
          updatedAt
          userConversationsAssociatedId
          userConversationsAssociatedConversationID
          userConversationsConnectionUserConversationsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateConversationGql = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      messages {
        items {
          authorID
          content
          id
          isSent
          conversationID
          createdAt
          updatedAt
        }
        nextToken
      }
      calls {
        items {
          authorID
          id
          isSent
          status
          roomID
          endedAt
          conversationID
          createdAt
          updatedAt
        }
        nextToken
      }
      participants
      userConversations {
        items {
          userID
          conversationID
          isFamilyConversation
          createdAt
          updatedAt
          userConversationsAssociatedId
          userConversationsAssociatedConversationID
          userConversationsConnectionUserConversationsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteConversationGql = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      messages {
        items {
          authorID
          content
          id
          isSent
          conversationID
          createdAt
          updatedAt
        }
        nextToken
      }
      calls {
        items {
          authorID
          id
          isSent
          status
          roomID
          endedAt
          conversationID
          createdAt
          updatedAt
        }
        nextToken
      }
      participants
      userConversations {
        items {
          userID
          conversationID
          isFamilyConversation
          createdAt
          updatedAt
          userConversationsAssociatedId
          userConversationsAssociatedConversationID
          userConversationsConnectionUserConversationsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const deleteUserConversationsGql = /* GraphQL */ `
  mutation DeleteUserConversations(
    $input: DeleteUserConversationsInput!
    $condition: ModelUserConversationsConditionInput
  ) {
    deleteUserConversations(input: $input, condition: $condition) {
      associated {
        items {
          userID
          conversationID
          isFamilyConversation
          createdAt
          updatedAt
          userConversationsAssociatedId
          userConversationsAssociatedConversationID
          userConversationsConnectionUserConversationsId
        }
        nextToken
      }
      userID
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        calls {
          nextToken
        }
        participants
        userConversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      isFamilyConversation
      createdAt
      updatedAt
      userConversationsAssociatedId
      userConversationsAssociatedConversationID
      userConversationsConnectionUserConversationsId
    }
  }
`;

export const getUserConversationsConnection = /* GraphQL */ `
  query GetUserConversationsConnection($id: ID!) {
    getUserConversationsConnection(id: $id) {
      nextToken
      userConversations {
        items {
          userID
          conversationID
          isFamilyConversation
          createdAt
          updatedAt
          userConversationsAssociatedId
          userConversationsAssociatedConversationID
          userConversationsConnectionUserConversationsId
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;
