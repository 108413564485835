/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const allMessageByConversationId = /* GraphQL */ `
  query AllMessageByConversationId(
    $after: String
    $conversationID: ID!
    $first: Int
  ) {
    allMessageByConversationId(
      after: $after
      conversationID: $conversationID
      first: $first
    ) {
      authorID
      content
      id
      isSent
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        calls {
          nextToken
        }
        participants
        userConversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const allMessageFrom = /* GraphQL */ `
  query AllMessageFrom(
    $after: String
    $conversationID: ID!
    $first: Int
    $sender: String!
  ) {
    allMessageFrom(
      after: $after
      conversationID: $conversationID
      first: $first
      sender: $sender
    ) {
      authorID
      content
      id
      isSent
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        calls {
          nextToken
        }
        participants
        userConversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const getUserConversationByUserId = /* GraphQL */ `
  query GetUserConversationByUserId($userID: ID!) {
    getUserConversationByUserId(userID: $userID) {
      associated {
        items {
          userID
          conversationID
          isFamilyConversation
          createdAt
          updatedAt
          userConversationsAssociatedId
          userConversationsAssociatedConversationID
          userConversationsConnectionUserConversationsId
        }
        nextToken
      }
      userID
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        calls {
          nextToken
        }
        participants
        userConversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      isFamilyConversation
      createdAt
      updatedAt
      userConversationsAssociatedId
      userConversationsAssociatedConversationID
      userConversationsConnectionUserConversationsId
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      messages {
        items {
          authorID
          content
          id
          isSent
          conversationID
          createdAt
          updatedAt
        }
        nextToken
      }
      calls {
        items {
          authorID
          id
          isSent
          status
          roomID
          endedAt
          conversationID
          createdAt
          updatedAt
        }
        nextToken
      }
      participants
      userConversations {
        items {
          userID
          conversationID
          isFamilyConversation
          createdAt
          updatedAt
          userConversationsAssociatedId
          userConversationsAssociatedConversationID
          userConversationsConnectionUserConversationsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messages {
          nextToken
        }
        calls {
          nextToken
        }
        participants
        userConversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchConversations = /* GraphQL */ `
  query SearchConversations(
    $filter: SearchableConversationFilterInput
    $sort: [SearchableConversationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableConversationAggregationInput]
  ) {
    searchConversations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        messages {
          nextToken
        }
        calls {
          nextToken
        }
        participants
        userConversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getUserConversations = /* GraphQL */ `
  query GetUserConversations($userID: ID!, $conversationID: ID!) {
    getUserConversations(userID: $userID, conversationID: $conversationID) {
      associated {
        items {
          userID
          conversationID
          isFamilyConversation
          createdAt
          updatedAt
          userConversationsAssociatedId
          userConversationsAssociatedConversationID
          userConversationsConnectionUserConversationsId
        }
        nextToken
      }
      userID
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        calls {
          nextToken
        }
        participants
        userConversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      isFamilyConversation
      createdAt
      updatedAt
      userConversationsAssociatedId
      userConversationsAssociatedConversationID
      userConversationsConnectionUserConversationsId
    }
  }
`;
export const listUserConversations = /* GraphQL */ `
  query ListUserConversations(
    $userID: ID
    $conversationID: ModelIDKeyConditionInput
    $filter: ModelUserConversationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserConversations(
      userID: $userID
      conversationID: $conversationID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        associated {
          nextToken
        }
        userID
        conversationID
        conversation {
          id
          participants
          createdAt
          updatedAt
        }
        isFamilyConversation
        createdAt
        updatedAt
        userConversationsAssociatedId
        userConversationsAssociatedConversationID
        userConversationsConnectionUserConversationsId
      }
      nextToken
    }
  }
`;
export const searchUserConversations = /* GraphQL */ `
  query SearchUserConversations(
    $filter: SearchableUserConversationsFilterInput
    $sort: [SearchableUserConversationsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserConversationsAggregationInput]
  ) {
    searchUserConversations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        associated {
          nextToken
        }
        userID
        conversationID
        conversation {
          id
          participants
          createdAt
          updatedAt
        }
        isFamilyConversation
        createdAt
        updatedAt
        userConversationsAssociatedId
        userConversationsAssociatedConversationID
        userConversationsConnectionUserConversationsId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getUserConversationsConnection = /* GraphQL */ `
  query GetUserConversationsConnection($id: ID!) {
    getUserConversationsConnection(id: $id) {
      nextToken
      userConversations {
        items {
          userID
          conversationID
          isFamilyConversation
          createdAt
          updatedAt
          userConversationsAssociatedId
          userConversationsAssociatedConversationID
          userConversationsConnectionUserConversationsId
        }
        nextToken
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const listUserConversationsConnections = /* GraphQL */ `
  query ListUserConversationsConnections(
    $filter: ModelUserConversationsConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserConversationsConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        nextToken
        userConversations {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      authorID
      content
      id
      isSent
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        calls {
          nextToken
        }
        participants
        userConversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        authorID
        content
        id
        isSent
        conversationID
        conversation {
          id
          participants
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByConversation = /* GraphQL */ `
  query MessagesByConversation(
    $conversationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByConversation(
      conversationID: $conversationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        authorID
        content
        id
        isSent
        conversationID
        conversation {
          id
          participants
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchMessages = /* GraphQL */ `
  query SearchMessages(
    $filter: SearchableMessageFilterInput
    $sort: [SearchableMessageSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMessageAggregationInput]
  ) {
    searchMessages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        authorID
        content
        id
        isSent
        conversationID
        conversation {
          id
          participants
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getCall = /* GraphQL */ `
  query GetCall($id: ID!) {
    getCall(id: $id) {
      authorID
      id
      isSent
      history {
        decliners {
          id
        }
        joiners {
          id
        }
      }
      status
      roomID
      endedAt
      conversationID
      conversation {
        id
        messages {
          nextToken
        }
        calls {
          nextToken
        }
        participants
        userConversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCalls = /* GraphQL */ `
  query ListCalls(
    $filter: ModelCallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        authorID
        id
        isSent
        status
        roomID
        endedAt
        conversationID
        conversation {
          id
          participants
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const callByRoomId = /* GraphQL */ `
  query CallByRoomId(
    $roomID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    callByRoomId(
      roomID: $roomID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        authorID
        id
        isSent
        status
        roomID
        endedAt
        conversationID
        conversation {
          id
          participants
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const callByConversation = /* GraphQL */ `
  query CallByConversation(
    $conversationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCallFilterInput
    $limit: Int
    $nextToken: String
  ) {
    callByConversation(
      conversationID: $conversationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        authorID
        id
        isSent
        status
        roomID
        endedAt
        conversationID
        conversation {
          id
          participants
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNews = /* GraphQL */ `
  query GetNews($id: ID!, $residencyID: ID!, $tenantID: ID!) {
    getNews(id: $id, residencyID: $residencyID, tenantID: $tenantID) {
      id
      residency {
        externalID
        tenantID
        tenant {
          id
          name
          createdAt
          updatedAt
        }
        residents {
          nextToken
        }
        name
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        createdAt
        updatedAt
      }
      residencyID
      tenantID
      authorID
      title
      content
      picture
      createdAt
      updatedAt
    }
  }
`;
export const listNews = /* GraphQL */ `
  query ListNews(
    $id: ID
    $residencyIDTenantID: ModelNewsPrimaryCompositeKeyConditionInput
    $filter: ModelNewsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNews(
      id: $id
      residencyIDTenantID: $residencyIDTenantID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        residency {
          externalID
          tenantID
          name
          createdAt
          updatedAt
        }
        residencyID
        tenantID
        authorID
        title
        content
        picture
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchNews = /* GraphQL */ `
  query SearchNews(
    $filter: SearchableNewsFilterInput
    $sort: [SearchableNewsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableNewsAggregationInput]
  ) {
    searchNews(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        residency {
          externalID
          tenantID
          name
          createdAt
          updatedAt
        }
        residencyID
        tenantID
        authorID
        title
        content
        picture
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getApp = /* GraphQL */ `
  query GetApp($id: ID!) {
    getApp(id: $id) {
      isMaintenance
      id
      createdAt
      updatedAt
    }
  }
`;
export const listApps = /* GraphQL */ `
  query ListApps(
    $filter: ModelAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        isMaintenance
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      Residencies {
        items {
          externalID
          tenantID
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      address {
        civicNumber
        street
        postalCode
        province
        city
        country
      }
      credentials {
        dns
        login
        password
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Residencies {
          nextToken
        }
        name
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        credentials {
          dns
          login
          password
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchTenants = /* GraphQL */ `
  query SearchTenants(
    $filter: SearchableTenantFilterInput
    $sort: [SearchableTenantSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTenantAggregationInput]
  ) {
    searchTenants(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        Residencies {
          nextToken
        }
        name
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        credentials {
          dns
          login
          password
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getResidency = /* GraphQL */ `
  query GetResidency($externalID: ID!, $tenantID: ID!) {
    getResidency(externalID: $externalID, tenantID: $tenantID) {
      externalID
      tenantID
      tenant {
        id
        Residencies {
          nextToken
        }
        name
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        credentials {
          dns
          login
          password
        }
        createdAt
        updatedAt
      }
      residents {
        items {
          cognitoID
          externalID
          firstName
          lastName
          email
          profilePicture
          conversationsConnectionID
          residencyID
          tenantID
          sex
          birthDate
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      address {
        civicNumber
        street
        postalCode
        province
        city
        country
      }
      createdAt
      updatedAt
    }
  }
`;
export const listResidencies = /* GraphQL */ `
  query ListResidencies(
    $externalID: ID
    $tenantID: ModelIDKeyConditionInput
    $filter: ModelResidencyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listResidencies(
      externalID: $externalID
      tenantID: $tenantID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        externalID
        tenantID
        tenant {
          id
          name
          createdAt
          updatedAt
        }
        residents {
          nextToken
        }
        name
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchResidencies = /* GraphQL */ `
  query SearchResidencies(
    $filter: SearchableResidencyFilterInput
    $sort: [SearchableResidencySortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableResidencyAggregationInput]
  ) {
    searchResidencies(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        externalID
        tenantID
        tenant {
          id
          name
          createdAt
          updatedAt
        }
        residents {
          nextToken
        }
        name
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getProfessional = /* GraphQL */ `
  query GetProfessional($id: ID!) {
    getProfessional(id: $id) {
      id
      cognitoID
      externalID
      firstName
      lastName
      email
      sex
      profilePicture
      conversationsConnectionID
      conversations {
        nextToken
        userConversations {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProfessionals = /* GraphQL */ `
  query ListProfessionals(
    $filter: ModelProfessionalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfessionals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoID
        externalID
        firstName
        lastName
        email
        sex
        profilePicture
        conversationsConnectionID
        conversations {
          nextToken
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const professionalByCognitoId = /* GraphQL */ `
  query ProfessionalByCognitoId(
    $cognitoID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProfessionalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    professionalByCognitoId(
      cognitoID: $cognitoID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoID
        externalID
        firstName
        lastName
        email
        sex
        profilePicture
        conversationsConnectionID
        conversations {
          nextToken
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchProfessionals = /* GraphQL */ `
  query SearchProfessionals(
    $filter: SearchableProfessionalFilterInput
    $sort: [SearchableProfessionalSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProfessionalAggregationInput]
  ) {
    searchProfessionals(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        cognitoID
        externalID
        firstName
        lastName
        email
        sex
        profilePicture
        conversationsConnectionID
        conversations {
          nextToken
          id
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getCircleMember = /* GraphQL */ `
  query GetCircleMember($id: ID!) {
    getCircleMember(id: $id) {
      id
      cognitoID
      firstName
      lastName
      email
      profilePicture
      sex
      birthDate
      address {
        civicNumber
        street
        postalCode
        province
        city
        country
      }
      conversationsConnectionID
      conversations {
        nextToken
        userConversations {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      residents {
        items {
          circleMemberID
          relation
          externalID
          residencyID
          tenantID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCircleMembers = /* GraphQL */ `
  query ListCircleMembers(
    $filter: ModelCircleMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCircleMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoID
        firstName
        lastName
        email
        profilePicture
        sex
        birthDate
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        conversationsConnectionID
        conversations {
          nextToken
          id
          createdAt
          updatedAt
        }
        residents {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const circleMemberByCognitoId = /* GraphQL */ `
  query CircleMemberByCognitoId(
    $cognitoID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCircleMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    circleMemberByCognitoId(
      cognitoID: $cognitoID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoID
        firstName
        lastName
        email
        profilePicture
        sex
        birthDate
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        conversationsConnectionID
        conversations {
          nextToken
          id
          createdAt
          updatedAt
        }
        residents {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchCircleMembers = /* GraphQL */ `
  query SearchCircleMembers(
    $filter: SearchableCircleMemberFilterInput
    $sort: [SearchableCircleMemberSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCircleMemberAggregationInput]
  ) {
    searchCircleMembers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        cognitoID
        firstName
        lastName
        email
        profilePicture
        sex
        birthDate
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        conversationsConnectionID
        conversations {
          nextToken
          id
          createdAt
          updatedAt
        }
        residents {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getResident = /* GraphQL */ `
  query GetResident($externalID: ID!, $residencyID: ID!, $tenantID: ID!) {
    getResident(
      externalID: $externalID
      residencyID: $residencyID
      tenantID: $tenantID
    ) {
      cognitoID
      externalID
      firstName
      lastName
      email
      profilePicture
      conversationsConnectionID
      conversations {
        nextToken
        userConversations {
          nextToken
        }
        id
        createdAt
        updatedAt
      }
      residency {
        externalID
        tenantID
        tenant {
          id
          name
          createdAt
          updatedAt
        }
        residents {
          nextToken
        }
        name
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        createdAt
        updatedAt
      }
      residencyID
      tenantID
      sex
      birthDate
      address {
        civicNumber
        street
        postalCode
        province
        city
        country
      }
      circleMembers {
        items {
          circleMemberID
          relation
          externalID
          residencyID
          tenantID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listResidents = /* GraphQL */ `
  query ListResidents(
    $externalID: ID
    $residencyIDTenantID: ModelResidentPrimaryCompositeKeyConditionInput
    $filter: ModelResidentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listResidents(
      externalID: $externalID
      residencyIDTenantID: $residencyIDTenantID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cognitoID
        externalID
        firstName
        lastName
        email
        profilePicture
        conversationsConnectionID
        conversations {
          nextToken
          id
          createdAt
          updatedAt
        }
        residency {
          externalID
          tenantID
          name
          createdAt
          updatedAt
        }
        residencyID
        tenantID
        sex
        birthDate
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        circleMembers {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const residentByCognitoId = /* GraphQL */ `
  query ResidentByCognitoId(
    $cognitoID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    residentByCognitoId(
      cognitoID: $cognitoID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        cognitoID
        externalID
        firstName
        lastName
        email
        profilePicture
        conversationsConnectionID
        conversations {
          nextToken
          id
          createdAt
          updatedAt
        }
        residency {
          externalID
          tenantID
          name
          createdAt
          updatedAt
        }
        residencyID
        tenantID
        sex
        birthDate
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        circleMembers {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchResidents = /* GraphQL */ `
  query SearchResidents(
    $filter: SearchableResidentFilterInput
    $sort: [SearchableResidentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableResidentAggregationInput]
  ) {
    searchResidents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        cognitoID
        externalID
        firstName
        lastName
        email
        profilePicture
        conversationsConnectionID
        conversations {
          nextToken
          id
          createdAt
          updatedAt
        }
        residency {
          externalID
          tenantID
          name
          createdAt
          updatedAt
        }
        residencyID
        tenantID
        sex
        birthDate
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        circleMembers {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getResidentCircleMemberRelation = /* GraphQL */ `
  query GetResidentCircleMemberRelation(
    $circleMemberID: ID!
    $residencyID: ID!
    $externalID: ID!
    $tenantID: ID!
  ) {
    getResidentCircleMemberRelation(
      circleMemberID: $circleMemberID
      residencyID: $residencyID
      externalID: $externalID
      tenantID: $tenantID
    ) {
      circleMember {
        id
        cognitoID
        firstName
        lastName
        email
        profilePicture
        sex
        birthDate
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        conversationsConnectionID
        conversations {
          nextToken
          id
          createdAt
          updatedAt
        }
        residents {
          nextToken
        }
        createdAt
        updatedAt
      }
      circleMemberID
      relation
      role {
        id
        role
        createdAt
        updatedAt
      }
      resident {
        cognitoID
        externalID
        firstName
        lastName
        email
        profilePicture
        conversationsConnectionID
        conversations {
          nextToken
          id
          createdAt
          updatedAt
        }
        residency {
          externalID
          tenantID
          name
          createdAt
          updatedAt
        }
        residencyID
        tenantID
        sex
        birthDate
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        circleMembers {
          nextToken
        }
        createdAt
        updatedAt
      }
      externalID
      residencyID
      tenantID
      createdAt
      updatedAt
    }
  }
`;
export const listResidentCircleMemberRelations = /* GraphQL */ `
  query ListResidentCircleMemberRelations(
    $circleMemberID: ID
    $residencyIDExternalIDTenantID: ModelResidentCircleMemberRelationPrimaryCompositeKeyConditionInput
    $filter: ModelResidentCircleMemberRelationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listResidentCircleMemberRelations(
      circleMemberID: $circleMemberID
      residencyIDExternalIDTenantID: $residencyIDExternalIDTenantID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        circleMember {
          id
          cognitoID
          firstName
          lastName
          email
          profilePicture
          sex
          birthDate
          conversationsConnectionID
          createdAt
          updatedAt
        }
        circleMemberID
        relation
        role {
          id
          role
          createdAt
          updatedAt
        }
        resident {
          cognitoID
          externalID
          firstName
          lastName
          email
          profilePicture
          conversationsConnectionID
          residencyID
          tenantID
          sex
          birthDate
          createdAt
          updatedAt
        }
        externalID
        residencyID
        tenantID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchResidentCircleMemberRelations = /* GraphQL */ `
  query SearchResidentCircleMemberRelations(
    $filter: SearchableResidentCircleMemberRelationFilterInput
    $sort: [SearchableResidentCircleMemberRelationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableResidentCircleMemberRelationAggregationInput]
  ) {
    searchResidentCircleMemberRelations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        circleMember {
          id
          cognitoID
          firstName
          lastName
          email
          profilePicture
          sex
          birthDate
          conversationsConnectionID
          createdAt
          updatedAt
        }
        circleMemberID
        relation
        role {
          id
          role
          createdAt
          updatedAt
        }
        resident {
          cognitoID
          externalID
          firstName
          lastName
          email
          profilePicture
          conversationsConnectionID
          residencyID
          tenantID
          sex
          birthDate
          createdAt
          updatedAt
        }
        externalID
        residencyID
        tenantID
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      role
      createdAt
      updatedAt
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        role
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchRoles = /* GraphQL */ `
  query SearchRoles(
    $filter: SearchableRoleFilterInput
    $sort: [SearchableRoleSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRoleAggregationInput]
  ) {
    searchRoles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        role
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getAdmins = /* GraphQL */ `
  query GetAdmins($id: ID!) {
    getAdmins(id: $id) {
      id
      cognitoID
      firstName
      lastName
      email
      profilePicture
      isSuper
      residencyID
      tenantID
      createdAt
      updatedAt
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins(
    $filter: ModelAdminsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoID
        firstName
        lastName
        email
        profilePicture
        isSuper
        residencyID
        tenantID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const adminByCognitoId = /* GraphQL */ `
  query AdminByCognitoId(
    $cognitoID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAdminsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    adminByCognitoId(
      cognitoID: $cognitoID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoID
        firstName
        lastName
        email
        profilePicture
        isSuper
        residencyID
        tenantID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchAdmins = /* GraphQL */ `
  query SearchAdmins(
    $filter: SearchableAdminsFilterInput
    $sort: [SearchableAdminsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAdminsAggregationInput]
  ) {
    searchAdmins(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        cognitoID
        firstName
        lastName
        email
        profilePicture
        isSuper
        residencyID
        tenantID
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
