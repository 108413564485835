import dayjs, { Dayjs } from "dayjs";
import { Address, User, UserForm, UserRow } from "../user";
import { CircleMember } from "../circleMember/circleMember";

export class ResidentForm extends UserForm {
  birthDate: Dayjs = dayjs();

  externalId = "";
}

export interface ResidentRow extends UserRow {
  externalId: string;

  isMember: string;

  residencyId: string;

  tenantId: string;
}

export class Resident extends User {
  circleMembers: CircleMember[] = [];

  birthDate: Dayjs = dayjs();

  externalId = "";

  residencyId = "";
}

export class ResidentCircleMember extends CircleMember {
  relationId = "";
}

export class LiviaResident {
  id = "";

  firstName = "";

  lastName = "";

  email = "";

  birthDate = "";

  externalID = "";

  sex = "unknown";

  address: Address = new Address();
}
