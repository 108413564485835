import { Construction } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";

const SettingsPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation();

  return (
    <div className="construction">
      <Construction fontSize="large" className="construction-icon" />
      {t("construction:page")}
      <Construction fontSize="large" className="construction-icon" />
    </div>
  );
};

export default SettingsPage;
