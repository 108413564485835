import { Media } from "@commr/admin/models/common/media";
import { Storage } from "aws-amplify";

export const deleteMedia = async (fileName: string) => {
  const storage = await Storage.remove(fileName);
  return storage;
};

export const uploadMedia = async (media: Media) => {
  const storage = await Storage.put(media.path, media.media);
  return storage;
};
