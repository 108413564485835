import React, { useContext, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import tabProps from "@commr/admin/utils/tabUtils";
import {
  Residency,
  ResidencyForm,
} from "@commr/admin/models/residency/residency";
import { useParams } from "react-router";
import TabPanel from "../common/tabs/tabPanel";
import "./residencyDataContainer.scss";
import ResidencyInfosForm from "./forms/residencyInfosForm";
import ResidentsContainer from "../residents/residentsContainer";
import ResidencyToResidencyForm from "./mappers/residencyToResidencyForm";
import FeedsContainer from "../feeds/feedsContainer";
import AdminsContainer from "../admins/adminsContainer";

interface Props {
  residency: Residency;
}

const ResidencyDataContainer: React.FC<React.PropsWithChildren<Props>> = ({
  residency,
}) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const { tab } = useParams<{
    tab: string;
  }>();
  const { t } = useTranslation();
  const defaultValues = residency
    ? ResidencyToResidencyForm.map(residency, ResidencyForm, Residency)
    : new ResidencyForm();
  const { control } = useForm<ResidencyForm>({
    defaultValues,
  });

  const isUpdate = residency.externalId !== "";

  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    event.isDefaultPrevented();
    setCurrentTabIndex(tabIndex);
  };

  useEffect(() => {
    if (tab) {
      setCurrentTabIndex(parseInt(tab, 10));
    }
  }, [tab]);

  return (
    <Box sx={{ width: "100%", flexGrow: 1 }}>
      <Box
        sx={{
          borderBottom: 0,
          borderColor: "divider",
          width: "100%",
        }}
      >
        <Tabs
          sx={{ width: "100%" }}
          value={currentTabIndex}
          onChange={handleChange}
          aria-label="residency tabs"
        >
          <Tab {...tabProps(0, t("common:profile"))} />
          {isUpdate && <Tab {...tabProps(1, t("common:admins"))} />}
          {isUpdate && <Tab {...tabProps(2, t("common:news"))} />}
          {isUpdate && <Tab {...tabProps(3, t("common:members"))} />}
        </Tabs>
      </Box>
      <Grid container sx={{ display: "block" }}>
        <TabPanel value={currentTabIndex} index={0}>
          <ResidencyInfosForm control={control} />
        </TabPanel>
        {isUpdate && (
          <TabPanel value={currentTabIndex} index={1}>
            <AdminsContainer
              residency={residency}
              addPath={`/tenant/${residency.tenantId}/residency/${residency.externalId}/admins/addAdmin`}
              isSuper={false}
            />
          </TabPanel>
        )}
        {isUpdate && (
          <TabPanel value={currentTabIndex} index={2}>
            <FeedsContainer
              residencyId={residency.externalId}
              tenantId={residency.tenantId}
            />
          </TabPanel>
        )}
        {isUpdate && (
          <TabPanel value={currentTabIndex} index={3}>
            <ResidentsContainer residency={residency} />
          </TabPanel>
        )}
      </Grid>
    </Box>
  );
};

export default ResidencyDataContainer;
