import React, { useContext, useEffect, useState } from "react";
import ResidentsContainer from "@commr/admin/components/residents/residentsContainer";
import { Residency } from "@commr/admin/models/residency/residency";
import LoadingScreen from "@commr/admin/components/common/layout/loadingScreen";
import { UserContext } from "@commr/admin/context/userContext";
import { getResidencyByExternalId } from "@commr/admin/services/residencyService";

const ResidentsPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [residency, setResidency] = useState<Residency>();
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user && user.residencyID)
      getResidencyByExternalId(user.tenantID, user.residencyID).then(
        (tenantResidency) => {
          setResidency(tenantResidency!);
        }
      );
  }, [user]);
  if (!residency) return <LoadingScreen />;
  return <ResidentsContainer residency={residency} />;
};

export default ResidentsPage;
