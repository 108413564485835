import React from "react";
import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import { Grid, Paper, styled, Typography, Divider } from "@mui/material";
import { ResidencyForm } from "@commr/admin/models/residency/residency";
import ControlledInput from "../../common/formFields/controlledInput";

interface Props {
  control: Control<ResidencyForm, any>;
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const ResidencyGeneralInfosForm: React.FC<React.PropsWithChildren<Props>> = ({
  control,
}) => {
  const { t } = useTranslation();

  return (
    <Item>
      <Grid item sx={{ paddingBottom: 2 }}>
        <Typography variant="h5">{t("userForm:generalInfo")}</Typography>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={6}>
              <ControlledInput
                controllerProps={{
                  name: "externalId",
                  control,
                }}
                isReadOnly
                label={t("residency:liviaId")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={6}>
              <ControlledInput
                controllerProps={{
                  name: "name",
                  control,
                }}
                isReadOnly
                label={t("residency:name")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={12}>
              <ControlledInput
                controllerProps={{
                  name: "address.street",
                  control,
                }}
                isReadOnly
                label={t("common:address")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Item>
  );
};

export default ResidencyGeneralInfosForm;
