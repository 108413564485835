import { API, graphqlOperation } from "aws-amplify";
import { CircleMember } from "@commr/admin/models/user/circleMember/circleMember";
import { Media } from "@commr/admin/models/common/media";
import {
  AppSyncSearchQuery,
  AppSyncSearchResult,
} from "@commr/admin/models/common/appSyncSearch";
import { Resident } from "@commr/admin/models/user/resident/resident";
import {
  createResidentGql,
  fillResidentData,
  fillResidentsData,
  searchResidents,
  searchUserConversations,
  updateResidentGql,
} from "@commr/admin/utils/residentUtils";
import { createResidentCircleMemberRelation } from "@commr/admin/utils/circleMemberUtils";
import {
  createUserConversationsConnection,
  createUser,
  createUserConversation,
  deleteUserConversation,
  updateConversation,
  uploadUserMedia,
} from "./userService";

export const createResident = async (resident: Resident, media?: Media) => {
  try {
    const { email, password } = resident;
    const user = await createUser(email, password!, "Residents", true).catch();

    const conversationConnection = await createUserConversationsConnection();
    if (!conversationConnection) {
      throw new Error("Failed to create conversation connection");
    }
    const connectionId = conversationConnection.id;

    const input: any = {
      conversationsConnectionID: connectionId,
      externalID: resident.externalId,
      firstName: resident.firstName,
      lastName: resident.lastName,
      residencyID: resident.residencyId,
      tenantID: resident.tenantId,
      cognitoID: user.Attributes[0]?.Value,
      email: resident.email,
      birthDate: resident.birthDate,
      sex: resident.sex,
      address: {
        city: resident.address.city,
        civicNumber: resident.address.civicNumber,
        country: resident.address.civicNumber,
        postalCode: resident.address.postalCode,
        province: resident.address.province,
        street: resident.address.street,
      },
    };

    if (media) input["profilePicture"] = await uploadUserMedia(media);

    // const conversation = await createConversation(newResidentData.id);
    // if (!conversation?.createConversation?.id) {
    //   throw new Error("Failed to create conversation");
    // }

    // await createUserConversation(
    //   newResidentData.id,
    //   connectionId,
    //   conversation.createConversation.id,
    //   true
    // );

    await API.graphql(
      graphqlOperation(createResidentGql, {
        input,
      })
    );
  } catch (e: any) {
    console.log("createResident error:", e);
    throw new Error(e);
  }
};

export const updateResident = async (resident: Resident, media?: Media) => {
  const input: any = {
    firstName: resident.firstName,
    lastName: resident.lastName,
    externalID: resident.externalId,
    residencyID: resident.residencyId,
    tenantID: resident.tenantId,
    email: resident.email,
    birthDate: resident.birthDate,
    sex: resident.sex,
    address: {
      city: resident.address.city,
      civicNumber: resident.address.civicNumber,
      country: resident.address.civicNumber,
      postalCode: resident.address.postalCode,
      province: resident.address.province,
      street: resident.address.street,
    },
  };

  if (media) input["profilePicture"] = await uploadUserMedia(media);

  const updatedResident = await API.graphql(
    graphqlOperation(updateResidentGql, {
      input,
    })
  );
  return updatedResident;
};

export const getResidents = async (
  searchQuery: AppSyncSearchQuery,
  residencyId: string,
  tenantId: string
) => {
  const { query, limit, from } = searchQuery;
  const filter =
    query.length > 0 || ""
      ? {
          residencyID: { eq: residencyId },
          tenantID: { eq: tenantId },
          or: [
            { firstName: { matchPhrasePrefix: query } },

            { lastName: { matchPhrasePrefix: query } },

            { externalID: { matchPhrasePrefix: query } },

            { email: { matchPhrasePrefix: query } },
          ],
        }
      : { residencyID: { eq: residencyId }, tenantID: { eq: tenantId } };

  const searchResidentsResult = (await API.graphql(
    graphqlOperation(searchResidents, {
      filter,
      limit,
      from,
      sort: { direction: "desc", field: "createdAt" },
    })
  )) as {
    data: any;
  };
  const searchResult = searchResidentsResult?.data?.searchResidents;

  let result = new AppSyncSearchResult([], 0, 0);

  if (searchResult) {
    result = new AppSyncSearchResult(
      await fillResidentsData(searchResult.items),
      searchResult.total ?? 0,
      0
    );
  }

  return result;
};

export const getResidentById = async (
  tenantID: string,
  residencyID: string,
  residentID: string
) => {
  const getResidentCustom = /* GraphQL */ `
    query GetResident($externalID: ID!, $residencyID: ID!, $tenantID: ID!) {
      getResident(
        externalID: $externalID
        residencyID: $residencyID
        tenantID: $tenantID
      ) {
        cognitoID
        externalID
        firstName
        lastName
        email
        profilePicture
        conversationsConnectionID
        conversations {
          nextToken
          userConversations {
            nextToken
          }
          id
          createdAt
          updatedAt
        }
        residency {
          externalID
          tenantID
          tenant {
            id
            name
            createdAt
            updatedAt
          }
          residents {
            nextToken
          }
          name
          address {
            civicNumber
            street
            postalCode
            province
            city
            country
          }
        }
        residencyID
        tenantID
        sex
        birthDate
        address {
          civicNumber
          street
          postalCode
          province
          city
          country
        }
        circleMembers {
          items {
            circleMember {
              profilePicture
              lastName
              id
              firstName
              email
            }
            circleMemberID
            relation
            externalID
            residencyID
            tenantID
          }
        }
      }
    }
  `;

  const resident = (await API.graphql(
    graphqlOperation(getResidentCustom, {
      tenantID,
      residencyID,
      externalID: residentID,
    })
  )) as {
    data: any;
  };
  return fillResidentData(resident?.data?.getResident);
};

export const getConversationFamilyByResidentId = async (residentId: string) => {
  const filter = {
    isFamilyConversation: { eq: true },
    userId: { eq: residentId },
  };

  const familyConversation = (await API.graphql(
    graphqlOperation(searchUserConversations, {
      filter,
    })
  )) as {
    data: any;
  };
  return familyConversation?.data?.searchUserConversations;
};

export const addCircleMemberToFamilyConversation = async (
  circleMember: CircleMember,
  residentID: string
) => {
  getConversationFamilyByResidentId(residentID).then((userConversations) => {
    if (userConversations) {
      const currentConversation = userConversations.items[0];
      if (currentConversation?.conversationID) {
        createUserConversation(
          circleMember.id,
          circleMember.conversationsConnectionId,
          currentConversation?.conversationID,
          false
        ).then(() => {
          const participants =
            currentConversation.conversation.participants ?? [];
          participants?.push(circleMember.id);
          updateConversation(currentConversation.conversationID, participants);
        });
      }
    }
  });
};

export const addCircleMemberToResident = async (
  externalID: string,
  residencyID: string,
  tenantID: string,
  circleMemberID: string
): Promise<void> => {
  try {
    // Créer une connexion de conversation pour l'utilisateur
    const conversationConnection = await createUserConversationsConnection();
    if (!conversationConnection) {
      throw new Error("createUserConversationsConnection error");
    }

    await API.graphql(
      graphqlOperation(createResidentCircleMemberRelation, {
        input: {
          circleMemberID,
          relation: "Unknown",
          externalID,
          residencyID,
          tenantID,
        },
      })
    );
  } catch (error: any) {
    throw new Error(`createCircleMember error : ${error.message}`);
  }
};

export const removeCircleMemberFromFamilyConversation = async (
  circleMemberID: string,
  residentID: string
) => {
  getConversationFamilyByResidentId(residentID).then((userConversations) => {
    if (userConversations) {
      const currentConversation = userConversations.items[0];

      if (currentConversation?.conversationID) {
        deleteUserConversation(
          circleMemberID,
          currentConversation?.conversationID
        ).then(() => {
          const participants =
            currentConversation?.conversation?.participants ?? [];
          const filteredParticipants = participants.filter(
            (id: string) => id !== circleMemberID
          );
          updateConversation(
            currentConversation.conversationID,
            filteredParticipants
          );
        });
      }
    }
  });
};
