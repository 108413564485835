import React from "react";
import { Box } from "@mui/material";

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = ({
  children,
  value,
  index,
  ...other
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box
        sx={{
          p: 2,
          border: "1px solid #d3c7c7",
          backgroundColor: "#f4f4f4",
          marginBottom: "10px",
        }}
      >
        <div>{children}</div>
      </Box>
    )}
  </div>
);
export default TabPanel;
