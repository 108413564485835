import React, { useContext } from "react";
import FeedsContainer from "@commr/admin/components/feeds/feedsContainer";
import LoadingScreen from "@commr/admin/components/common/layout/loadingScreen";
import { UserContext } from "@commr/admin/context/userContext";

const FeedsPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { user } = useContext(UserContext);

  if (!user || user.residencyID === undefined) return <LoadingScreen />;
  return (
    <FeedsContainer residencyId={user.residencyID} tenantId={user.tenantID} />
  );
};

export default FeedsPage;
