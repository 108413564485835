import dayjs, { Dayjs } from "dayjs";

export class Feed {
  id = "";

  picture = "";

  title = "";

  content = "";

  residencyId = "";

  tenantId = "";

  authorId = "";

  creationDate: Dayjs = dayjs();
}

export class FeedForm extends Feed {}

export interface FeedRow {
  id: string;

  title: string;

  content: string;

  picture: string;

  selected: boolean;
}

export default Feed;
