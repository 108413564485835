import { Relation } from "../../appsync";
import { User, UserForm, UserRow } from "../user";

export class CircleMember extends User {
  role = "";

  relation: undefined | Relation = Relation.UNKNOWN;
}

export interface CircleMemberRow extends UserRow {
  role: string;

  relation: string;
}

export class CircleMemberForm extends UserForm {
  role = "";

  isPrimaryContact = false;

  relation = "Unknown";
}
