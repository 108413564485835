import { Divider, Grid, Paper, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import { RoleForm } from "@commr/admin/models/role/role";
import ControlledInput from "../../common/formFields/controlledInput";

interface Props {
  control: Control<RoleForm, any>;
}

const RoleGeneralInfos: React.FC<React.PropsWithChildren<Props>> = ({
  control,
}) => {
  const { t } = useTranslation();

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

  return (
    <Item>
      <Grid item sx={{ paddingBottom: 2 }}>
        <Typography variant="h5">{t("userForm:generalInfo")}</Typography>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={6}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={6}>
              <ControlledInput
                controllerProps={{
                  name: "role",
                  control,
                  rules: {
                    maxLength: {
                      value: 50,
                      message: `${t("forms:errorMessages.maxLength", {
                        value: 50,
                      })}`,
                    },
                    minLength: {
                      value: 3,
                      message: `${t("forms:errorMessages.minLength", {
                        value: 3,
                      })}`,
                    },
                    required: {
                      value: true,
                      message: `${t("forms:errorMessages.required")}`,
                    },
                  },
                }}
                label={t("common:roleName")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Item>
  );
};

export default RoleGeneralInfos;
