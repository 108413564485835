import { Language, UserRow } from "../user";

export class Admin {
  id = "";

  firstName = "";

  lastName = "";

  email = "";

  profilePicture = "";

  residencyID?: string = undefined;

  tenantID = "";

  password?: string = "";

  isSuper = false;

  cognitoID?: string = undefined;

  language: Language = Language.French;

  createdAt = "";
}

export class AdminForm extends Admin {}

export interface AdminRow extends UserRow {
  id: string;

  email: string;

  tenantID: string;

  residencyID?: string;
  createdAt: string;
}
