import { Alert, Snackbar } from "@mui/material";
import React, { useContext } from "react";
import { AppContext } from "@commr/admin/context/appContext";

const Toast: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { toast } = useContext(AppContext);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={toast.open}
      autoHideDuration={5000}
      onClose={toast.onClose}
    >
      <Alert onClose={toast.onClose} severity={toast.severity}>
        {toast.message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
