import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Role } from "@commr/admin/models/role/role";
import RoleDataContainer from "@commr/admin/components/roles/roleDataContainer";
import { getRoleById } from "@commr/admin/services/roleService";

const RoleUpdatePage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [role, setRole] = useState<Role>();
  const { roleId } = useParams<{ roleId: string }>();

  useEffect(() => {
    if (roleId)
      getRoleById(roleId).then((roleInfo) => {
        setRole(roleInfo);
      });
  }, [roleId]);

  if (!role) return <CircularProgress size={60} />;
  return <RoleDataContainer role={role} />;
};

export default RoleUpdatePage;
