import React, { useState, useEffect, useContext } from "react";
import * as cognito from "@commr/admin/services/cognitoService";
import { Admin } from "@commr/admin/models/user/admin/admin";
import { UserContext } from "./userContext";

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface IAuth {
  sessionInfo?: {
    username?: string;
    email?: string;
    sub?: string;
    accessToken?: string;
    refreshToken?: string;
  };
  attrInfo?: any;
  authStatus?: AuthStatus;
  signInWithEmail?: any;
  signOut?: any;
  resetPasswordWithCode?: any;
  getSession?: any;
  forgotPassword?: any;
  changePassword?: any;
  getAttributes?: any;
  setAttribute?: any;
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
};

type Props = {
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode;
};

export const AuthContext = React.createContext(defaultState);

const getAttributes = async () =>
  new Promise((resolve) => {
    cognito.getAttributes().then((att) => resolve(att));
  }).catch((err) => {
    throw err;
  });

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext);

  return <div>{authStatus === AuthStatus.SignedIn ? children : null}</div>;
};

export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext);

  return <div>{authStatus === AuthStatus.SignedOut ? children : null}</div>;
};

const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const [sessionInfo, setSessionInfo] = useState({});
  const [attrInfo, setAttrInfo] = useState();
  const { setUser } = useContext(UserContext);

  const getSession = async () => {
    const session = await cognito.getSession();
    return session;
  };

  const setAttribute = async (attr: any) => {
    const resultPromise = Promise.resolve(cognito.setAttribute(attr));
    return resultPromise;
  };

  useEffect(() => {
    const getSessionInfo = async () => {
      try {
        await getSession()
          .then(async (session) => {
            if (session.isValid()) {
              const accessToken = session.getAccessToken();
              const refreshToken = session.getRefreshToken();
              setSessionInfo({
                accessToken,
                refreshToken,
              });
              await getAttributes().then((attr: any) => {
                setAttrInfo(attr);
                setAuthStatus(AuthStatus.SignedIn);
              });
            }
          })
          .catch(() => {
            setAuthStatus(AuthStatus.SignedOut);
          });
      } catch (err) {
        console.log("session error", err);
        setAuthStatus(AuthStatus.SignedOut);
      }
    };

    if (authStatus === AuthStatus.Loading) getSessionInfo();
  }, [authStatus]);

  const signInWithEmail = async (username: string, password: string) => {
    try {
      await cognito.signInWithEmail(username, password);
      setAuthStatus(AuthStatus.Loading);
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      throw err;
    }
  };

  const signOut = async () => {
    cognito.signOut().then(() => {
      setUser(new Admin());
      setAuthStatus(AuthStatus.SignedOut);
    });
  };

  const resetPasswordWithCode = async (
    username: string,
    new_password: string,
    code: string
  ) => {
    await cognito.verifyCode(username, new_password, code);
  };

  const forgotPassword = async (username: string) =>
    cognito.forgotPassword(username);

  const changePassword = async (oldPassword: string, newPassword: string) => {
    await cognito.changePassword(oldPassword, newPassword);
  };

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    signInWithEmail,
    signOut,
    resetPasswordWithCode,
    getSession,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

AuthProvider.defaultProps = {
  children: undefined,
};

export default AuthProvider;
