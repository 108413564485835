import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Avatar } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import {
  GridActionsCellItem,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowId,
} from "@mui/x-data-grid-pro";
import { AccountCircle } from "@mui/icons-material";
import Feed, { FeedRow } from "@commr/admin/models/feed/feed";
import { setUserPicture } from "@commr/admin/utils/userUtils";
import { deleteFeed } from "@commr/admin/services/feedsService";
import dayjs from "dayjs";
import {
  AppSyncSearchQuery,
  AppSyncSearchResult,
} from "@commr/admin/models/common/appSyncSearch";
import CustomDataGrid from "../common/customDataGrid";

interface Props {
  searchResult?: AppSyncSearchResult;
  searchQuery: AppSyncSearchQuery;
  setSearchQuery: Dispatch<SetStateAction<AppSyncSearchQuery>>;
}

const FeedHit: React.FC<React.PropsWithChildren<Props>> = ({
  searchResult,
  searchQuery,
  setSearchQuery,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [feedRows, setFeedRows] = useState<FeedRow[]>([]);
  const { tenantId, residencyId } = useParams<{
    tenantId: string;
    residencyId: string;
  }>();
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: searchQuery.limit,
  });

  const setAvatar = (params: GridRenderCellParams) => (
    <Avatar src={params.value?.toString()} />
  );

  const handleDeleteFeed = (id: GridRowId) => {
    deleteFeed(id.toString()).then(() => {
      setFeedRows((rows) => rows.filter((feedRow) => feedRow.id !== id));
    });
  };

  const feedsColumns: any = [
    {
      field: "picture",
      headerName: "",
      flex: 0.1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<any>) => setAvatar(params),
      renderHeader: () => <AccountCircle />,
    },
    {
      field: "creationDate",
      headerName: t("feeds:filter.creationDate"),
      flex: 0.2,
    },
    {
      field: "title",
      headerName: t("feeds:filter.title"),
      flex: 0.5,
    },
    {
      field: "content",
      headerName: t("feeds:filter.content"),
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      flex: 0.2,
      align: "center",
      headerAlign: "center",
      getActions: (params: any) => [
        <GridActionsCellItem
          label={t("common:actions.edit")}
          onClick={() =>
            history.push(
              `/tenant/${tenantId}/residency/${residencyId}/feed/${params.id}`
            )
          }
          showInMenu
        />,
        <GridActionsCellItem
          label={t("common:actions.delete")}
          onClick={() => handleDeleteFeed(params.id)}
          showInMenu
        />,
      ],
    },
  ];

  useEffect(() => {
    if (searchResult) {
      setFeedRows([]);
      searchResult?.items?.map(async (hit) => {
        const picture = await setUserPicture(hit.picture);
        const feed = {
          id: hit.id,
          picture,
          author: "Admin",
          title: hit.title,
          content: hit.content,
          creationDate: dayjs(hit.creationDate).format("DD-MM-YYYY à hh:mm"),
          selected: false,
        };
        setFeedRows((dataGridRows) => [...dataGridRows, feed]);
      });
    }
  }, [searchResult]);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
    setSearchQuery((old) => ({
      ...old,
      from: newPaginationModel.page * searchQuery.limit!,
    }));
  };

  const gridProps = {
    pagination: true,
    rowCount: searchResult?.total ?? 0,
    onPaginationModelChange: handlePaginationModelChange,
    paginationModel,
    pageSizeOptions: [],
    rows: feedRows,
    loading: !searchResult,
    columns: feedsColumns,
    paginationMode: "server",
    disableColumnMenu: true,
    disableSelectionOnClick: true,
    disableColumnSelector: true,
    initialState: {
      sorting: {
        sortModel: [{ field: "createdAt", sort: "asc" }],
      },
    },
  };

  return (
    <div style={{ height: 650, width: "100%" }}>
      <CustomDataGrid attribute={gridProps} />
    </div>
  );
};

export default FeedHit;

FeedHit.defaultProps = {
  searchResult: undefined,
};
