import { Role } from "@commr/admin/models/role/role";

export const fillRoleData = (roleResult: any) => {
  const role = new Role();

  if (roleResult) {
    role.id = roleResult.id;
    role.role = roleResult.role;
  }

  return role;
};

export const fillRolesData = async (rolesResult: any) => {
  const roles: Role[] = [];

  if (rolesResult) {
    rolesResult.forEach((item: any) => {
      roles.push(fillRoleData(item));
    });
  }

  return roles;
};

export const createRoleGql = /* GraphQL */ `
  mutation CreateRole(
    $input: CreateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    createRole(input: $input, condition: $condition) {
      id
      role
      createdAt
      updatedAt
    }
  }
`;
export const updateRoleGql = /* GraphQL */ `
  mutation UpdateRole(
    $input: UpdateRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    updateRole(input: $input, condition: $condition) {
      id
      role
      createdAt
      updatedAt
    }
  }
`;
export const deleteRoleGql = /* GraphQL */ `
  mutation DeleteRole(
    $input: DeleteRoleInput!
    $condition: ModelRoleConditionInput
  ) {
    deleteRole(input: $input, condition: $condition) {
      id
      role
      createdAt
      updatedAt
    }
  }
`;

export const searchRoles = /* GraphQL */ `
  query SearchRoles(
    $filter: SearchableRoleFilterInput
    $sort: [SearchableRoleSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableRoleAggregationInput]
  ) {
    searchRoles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        role
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      role
      createdAt
      updatedAt
    }
  }
`;
