import { Storage } from "aws-amplify";

export const getFileExtension = (filename: string) => filename.split(".").pop();

export const getFileFromBase64 = (string64: string, fileName: string) => {
  // Todo a revoir
  const trimmedString = string64
    .replace("data:image/png;base64,", "")
    .replace("data:image/jpeg;base64,", "")
    .replace("data:image/gif;base64,", "");
  const imageContent = atob(trimmedString);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  // eslint-disable-next-line no-plusplus
  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  const type = "image/jpeg";
  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, {
    lastModified: new Date().getTime(),
    type,
  });
};

export const getMedia = async (key: string) => {
  const storage = await Storage.get(key);
  return storage;
};
