import React from "react";
import { Route } from "react-router-dom";
import AppLayout from "@commr/admin/components/common/layout/appLayout";

interface PrivateRouteProps {
  component: React.ComponentType<any>;
  path: string;
  exact?: boolean;
  title: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  path,
  exact = true,
  component: Component,
  title,
  ...rest
}) => (
  <Route
    {...rest}
    exact
    render={() => (
      <AppLayout title={title}>
        <Component />
      </AppLayout>
    )}
  />
);

PrivateRoute.defaultProps = {
  exact: undefined,
};

export default PrivateRoute;
