import React, { useContext, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Media, PathType } from "@commr/admin/models/common/media";
import {
  Resident,
  ResidentForm,
} from "@commr/admin/models/user/resident/resident";
import tabProps from "@commr/admin/utils/tabUtils";
import { setUserProfilePicture } from "@commr/admin/utils/userUtils";
import {
  createResident,
  updateResident,
} from "@commr/admin/services/residentService";
import { AppContext } from "@commr/admin/context/appContext";
import ResidentCircleMembers from "@commr/admin/components/residents/residentCircleMembers";
import FormFooterActions from "@commr/admin/components/common/formFooterActions";
import TabPanel from "@commr/admin/components/common/tabs/tabPanel";
import ResidentToResidentForm from "@commr/admin/components/residents/mappers/residentToResidentForm";
import ResidentFormToResident from "@commr/admin/components/residents/mappers/residentFormToResident";
import ProfileInfosForm from "./forms/profileInfosForm";

interface Props {
  resident: Resident;
}

const ResidentDataContainer: React.FC<React.PropsWithChildren<Props>> = ({
  resident,
}) => {
  const { showToast } = useContext(AppContext);
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const { t } = useTranslation();
  const { tenantId, residencyId, tab } = useParams<{
    tenantId: string;
    residencyId: string;
    tab: string;
  }>();
  const [profilePicture, setProfilePicture] = useState(
    resident ? resident.profilePicture : ""
  );
  const defaultValues = resident
    ? ResidentToResidentForm.map(resident, ResidentForm, Resident)
    : new ResidentForm();
  const { control, handleSubmit, reset, register, setValue } =
    useForm<ResidentForm>({
      defaultValues,
    });

  const isUpdate = resident.externalId !== "";

  useEffect(() => {
    if (tab) {
      setCurrentTabIndex(parseInt(tab, 10));
    }
  }, [tab]);

  const handleChange = (event: React.SyntheticEvent, tabIndex: number) => {
    event.isDefaultPrevented();
    setCurrentTabIndex(tabIndex);
  };

  const handleCancel = () => {
    reset(defaultValues);
    setProfilePicture(resident ? resident.profilePicture : "");
  };

  const onSubmit = (residentForm: ResidentForm) => {
    setIsSaving(true);
    const newResident = ResidentFormToResident.map(
      residentForm,
      Resident,
      ResidentForm
    );

    const currentProfilePicture: Media | undefined = setUserProfilePicture(
      newResident.profilePicture,
      profilePicture,
      tenantId,
      residencyId,
      newResident.externalId,
      PathType.Residents
    );

    newResident.residencyId = residencyId;
    newResident.tenantId = tenantId;
    newResident.profilePicture = profilePicture;

    let submitAction;

    if (resident.externalId) {
      newResident.externalId = resident.externalId;
      submitAction = updateResident;
    } else {
      submitAction = createResident;
    }

    submitAction(newResident, currentProfilePicture)
      .then(() => {
        setTimeout(() => {
          showToast(t("forms:successMessages.residentSaved"), "success");
          setIsSaving(false);
          history.push(
            `/tenant/${newResident.tenantId}/residency/${newResident.residencyId}/3`
          );
        }, 2500);
      })
      .catch((error) => {
        const errorCode = error;
        let errorMessageTranslation;
        switch (errorCode) {
          case "UsernameExistsException":
            errorMessageTranslation = "emailAlReadyExists";
            break;
          default:
            errorMessageTranslation = "saveFailed";
            break;
        }

        showToast(t(`forms:errorMessages.${errorMessageTranslation}`), "error");
        setIsSaving(false);
      });
  };

  return (
    <Box
      sx={{ width: "100%", flexGrow: 1 }}
      onSubmit={handleSubmit(onSubmit)}
      component="form"
      autoComplete="no"
      noValidate
    >
      <Box
        sx={{
          borderBottom: 0,
          borderColor: "divider",
          width: "100%",
        }}
      >
        <Tabs
          sx={{ width: "100%" }}
          className="tab-styles"
          value={currentTabIndex}
          onChange={handleChange}
          aria-label="residents tabs"
        >
          <Tab {...tabProps(0, t("common:profile"))} />
          {isUpdate && <Tab {...tabProps(1, t("userForm:familyCircle"))} />}
        </Tabs>
      </Box>
      <Grid container sx={{ display: "block" }}>
        <TabPanel value={currentTabIndex} index={0}>
          <ProfileInfosForm
            profilePicture={profilePicture}
            control={control}
            setProfilePicture={setProfilePicture}
            register={register}
            isUpdate={isUpdate}
            setValue={setValue}
          />
        </TabPanel>
        {isUpdate && (
          <TabPanel value={currentTabIndex} index={1}>
            <ResidentCircleMembers resident={resident} />
          </TabPanel>
        )}

        {currentTabIndex === 0 && (
          <FormFooterActions isSaving={isSaving} handleCancel={handleCancel} />
        )}
      </Grid>
    </Box>
  );
};

export default ResidentDataContainer;
