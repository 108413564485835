import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import { UserContext } from "@commr/admin/context/userContext";
import LoadingScreen from "@commr/admin/components/common/layout/loadingScreen";
import RoutesData from "@commr/admin/models/common/iRoutesData";
import commonRoutesData from "./data/commonRoutesData";
import residentsRoutesData from "./data/residentRoutesData";
import residencyRoutesData from "./data/residencyRoutesData";
import circleMembersRoutesData from "./data/circleMembersRoutesData";
import PrivateRoute from "./privateRoute";
import adminsRoutesData from "./data/adminsRoutesData";
import superAdminsRoutesData from "./data/superAdminsRoutesData";
import rolesRoutesData from "./data/rolesRoutesData";
import tenantsRoutesData from "./data/tenantsRoutesData";

interface Props {
  appRoutes: RoutesData[];
}

const MainRoutes: React.FC<Props> = ({ appRoutes }) => {
  const { user } = useContext(UserContext);
  const [mainPath, setMainPath] = useState<string>();

  useEffect(() => {
    if (user.cognitoID) {
      setMainPath(
        user.isSuper
          ? "/tenants"
          : `tenant/${user.tenantID}/residency/${user.residencyID}/feeds`
      );
    }
  }, [user]);

  const adminRoutes = () =>
    appRoutes
      .concat(
        commonRoutesData,
        residentsRoutesData,
        residencyRoutesData,
        circleMembersRoutesData
      )
      .map((route) => (
        <PrivateRoute
          key={`adminRoutes_${route.path}`}
          exact
          path={route.path}
          component={route.component}
          title={route.title}
        />
      ));

  const superAdminRoutes = () =>
    appRoutes
      .concat(
        commonRoutesData,
        residentsRoutesData,
        residencyRoutesData,
        circleMembersRoutesData,
        adminsRoutesData,
        superAdminsRoutesData,
        rolesRoutesData,
        tenantsRoutesData
      )
      .map((route) => (
        <PrivateRoute
          key={`adminRoutes_${route.path}`}
          exact
          path={route.path}
          component={route.component}
          title={route.title}
        />
      ));

  if (!mainPath) return <LoadingScreen />;

  return (
    <BrowserRouter>
      <Switch>
        {user.isSuper ? superAdminRoutes() : adminRoutes()}
        <Redirect path="/login" to={mainPath} />
        <Redirect path="/" to={mainPath} />
      </Switch>
    </BrowserRouter>
  );
};

export default MainRoutes;
