import { Divider, Grid, Paper, styled, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Control, UseFormRegister } from "react-hook-form";
import { AdminForm } from "@commr/admin/models/user/admin/admin";
import ControlledInput from "../../common/formFields/controlledInput";
import PhotoAvatarInput from "../../common/formFields/photoAvatarInput";

interface Props {
  control: Control<AdminForm, any>;
  profilePicture: string;
  setProfilePicture: React.Dispatch<React.SetStateAction<string>>;
  register: UseFormRegister<AdminForm>;
}

const AdminGeneralInfos: React.FC<React.PropsWithChildren<Props>> = ({
  control,
  profilePicture,
  setProfilePicture,
  register,
}) => {
  const { t } = useTranslation();

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

  return (
    <Item>
      <Grid item sx={{ paddingBottom: 2 }}>
        <Typography variant="h5">{t("userForm:generalInfo")}</Typography>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={6}>
          <Grid
            container
            direction="row"
            spacing={1}
            style={{
              justifyContent: "center",
              display: "flex",
              alignContent: "center",
            }}
          >
            <PhotoAvatarInput
              name="profilePicture"
              register={register}
              profilePicture={profilePicture}
              setProfilePicture={setProfilePicture}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={6}>
              <ControlledInput
                controllerProps={{
                  name: "lastName",
                  control,
                  rules: {
                    maxLength: {
                      value: 50,
                      message: `${t("forms:errorMessages.maxLength", {
                        value: 50,
                      })}`,
                    },
                    minLength: {
                      value: 3,
                      message: `${t("forms:errorMessages.minLength", {
                        value: 3,
                      })}`,
                    },
                    required: {
                      value: true,
                      message: `${t("forms:errorMessages.required")}`,
                    },
                  },
                }}
                label={t("common:lastName")}
              />
            </Grid>
            <Grid item xs={6}>
              <ControlledInput
                controllerProps={{
                  name: "firstName",
                  control,
                  rules: {
                    maxLength: {
                      value: 50,
                      message: `${t("forms:errorMessages.maxLength", {
                        value: 50,
                      })}`,
                    },
                    minLength: {
                      value: 3,
                      message: `${t("forms:errorMessages.minLength", {
                        value: 3,
                      })}`,
                    },
                    required: {
                      value: true,
                      message: `${t("forms:errorMessages.required")}`,
                    },
                  },
                }}
                label={t("common:firstName")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Item>
  );
};

export default AdminGeneralInfos;
