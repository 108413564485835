import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import LoginIcon from "@mui/icons-material/Login";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { AppContext } from "@commr/admin/context/appContext";
import floatImgs from "./data/floatingImgData";

interface Props {
  onSubmit: (username: string, password: string) => void;
  loading: boolean;
}

const Login: React.FC<React.PropsWithChildren<Props>> = ({
  onSubmit,
  loading,
}) => {
  const { environment } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const { t } = useTranslation();

  const floatingImages = floatImgs?.map(({ src, className }) => (
    <img
      key={`menu_${className}`}
      src={src}
      className={`animationFloat ${className}`}
      alt=""
    />
  ));

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(email, password);
  };

  const CurrentVisibilityIcon = isRevealPwd
    ? VisibilityOffIcon
    : VisibilityIcon;

  return (
    <div className="login-page">
      <div className="login-bg" />
      <div className="login-content">
        <div className="login-logo-container">
          <h1 data-testid="login-app-name">{environment.appName}</h1>
          <h5>{t("login:communication")}</h5>
        </div>
        <div className="floaters-container" data-testid="login-floating-images">
          {floatingImages}
        </div>
        <div className="login-form-container">
          <h3 data-testid="login-label">{t("login:login")}</h3>
          <form onSubmit={handleSubmit}>
            <input
              data-testid="email-input"
              placeholder={t("login:email")}
              type="text"
              onChange={(e) => setEmail(e.target.value)}
            />

            <input
              data-testid="password-input"
              placeholder={t("login:password")}
              type={isRevealPwd ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span className="visibility">
              <CurrentVisibilityIcon
                data-testid="visibility-icon"
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            </span>
            <LoadingButton
              data-testid="loading-button"
              loading={loading}
              startIcon={<LoginIcon />}
              loadingPosition="start"
              variant="outlined"
              className="login-btn"
              type="submit"
              disabled={loading}
            >
              {t("login:connect")}
            </LoadingButton>
          </form>
          <div className="login-foot-logo">
            <span>
              <h4 data-testid="focus-label">{environment.appName}</h4>{" "}
              {t("login:focus")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
