import { API, graphqlOperation } from "aws-amplify";
import { Residency } from "@commr/admin/models/residency/residency";
import {
  createResidencyGql,
  fillResidenciesData,
  fillResidencyData,
  getResidency,
  searchResidencies,
} from "@commr/admin/utils/residencyUtils";
import {
  AppSyncSearchQuery,
  AppSyncSearchResult,
} from "@commr/admin/models/common/appSyncSearch";

export const createResidency = async (residency: Residency) => {
  const newResidencyData = {
    name: residency.name,
    tenantID: residency.tenantId,
    externalID: residency.externalId,
    address: { street: residency.address },
  };

  const newResidency = (await API.graphql(
    graphqlOperation(createResidencyGql, { input: newResidencyData })
  )) as {
    data: any;
  };

  return fillResidencyData(newResidency.data.createResidency);
};

export const getResidencyByExternalId = async (
  tenantId: string,
  externalId: string
) => {
  const residency = (await API.graphql(
    graphqlOperation(getResidency, {
      tenantID: tenantId,
      externalID: externalId,
    })
  )) as {
    data: any;
  };

  const residencies = residency?.data?.getResidency;

  if (residencies) {
    return fillResidencyData(residencies);
  }

  return null;
};

export const getResidenciesByTenantId = async (
  searchQuery: AppSyncSearchQuery,
  tenantId: string
) => {
  const { query, limit, from } = searchQuery;
  const filter =
    query.length > 0 || ""
      ? {
          tenantID: { eq: tenantId },
          or: [{ name: { matchPhrasePrefix: query } }],
        }
      : { tenantID: { eq: tenantId } };

  const searchResidenciesResult = (await API.graphql(
    graphqlOperation(searchResidencies, {
      filter,
      limit,
      from,
      sort: { direction: "desc", field: "createdAt" },
    })
  )) as {
    data: any;
  };

  const searchResult = searchResidenciesResult?.data?.searchResidencies;

  let result = new AppSyncSearchResult([], 0, 0);
  if (searchResult) {
    result = new AppSyncSearchResult(
      await fillResidenciesData(searchResult),
      searchResult.total ?? 0,
      0
    );
  }

  return result;
};
