import { createMapper, mapFrom } from "@automapper/core";
import { classes } from "@automapper/classes";
import { Admin, AdminForm } from "@commr/admin/models/user/admin/admin";

const AdminFormToAdmin = createMapper({
  name: "AdminFormToAdmin",
  pluginInitializer: classes,
});

AdminFormToAdmin.createMap(AdminForm, Admin)
  .forMember(
    (destination) => destination.firstName,
    mapFrom((source) => source.firstName)
  )
  .forMember(
    (destination) => destination.lastName,
    mapFrom((source) => source.lastName)
  )
  .forMember(
    (destination) => destination.profilePicture,
    mapFrom((source) => source.profilePicture)
  )
  .forMember(
    (destination) => destination.password,
    mapFrom((source) => source.password)
  );

export default AdminFormToAdmin;
