import React, { useContext, useState } from "react";
import { AuthContext } from "@commr/admin/context/authContext";
import Login from "./login";

const LoginContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (username: string, password: string) => {
    setLoading(true);
    authContext
      .signInWithEmail(username, password)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return <Login onSubmit={onSubmit} loading={loading} />;
};

export default LoginContainer;
