import {
  Button,
  Divider,
  Grid,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Control, UseFormSetValue } from "react-hook-form";
import { ResidentForm } from "@commr/admin/models/user/resident/resident";
import ControlledInput from "@commr/admin/components/common/formFields/controlledInput";
import AddLiviaResident from "./addLiviaResident";

interface Props {
  control: Control<ResidentForm, any>;
  isUpdate: boolean;
  setValue: UseFormSetValue<ResidentForm>;
}

const ResidentResidencyInfos: React.FC<React.PropsWithChildren<Props>> = ({
  control,
  isUpdate,
  setValue,
}) => {
  const { t } = useTranslation();
  const [openLiviaResidentGrid, setOpenLiviaResidentGrid] = useState(false);

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

  const handleClickOpen = () => {
    setOpenLiviaResidentGrid(true);
  };
  const handleClose = () => {
    setOpenLiviaResidentGrid(false);
  };

  return (
    <Item>
      <Grid item xs={12} sx={{ paddingBottom: 2 }}>
        <Typography variant="h5">{t("userForm:ResidencyInfo")}</Typography>
        <Divider variant="fullWidth" />
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={6}>
          <ControlledInput
            controllerProps={{
              name: "externalId",
              control,
              rules: {
                required: {
                  value: true,
                  message: `${t("forms:errorMessages.required")}`,
                },
              },
            }}
            isReadOnly
            label={t("residency:liviaId")}
          />
        </Grid>
        <Grid item xs={6}>
          {!isUpdate && (
            <Button
              data-testid="livia-residents-button"
              name="livia-residents-button"
              variant="contained"
              onClick={handleClickOpen}
            >
              {t("residents:addLiviaResident")}
            </Button>
          )}
        </Grid>
      </Grid>
      <AddLiviaResident
        handleClose={handleClose}
        openLiviaResidentGrid={openLiviaResidentGrid}
        setValue={setValue}
      />
    </Item>
  );
};

export default ResidentResidencyInfos;
