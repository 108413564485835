import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { Media, PathType } from "@commr/admin/models/common/media";
import {
  fetchMedia,
  setUserProfilePicture,
} from "@commr/admin/utils/userUtils";
import { getMedia } from "@commr/admin/utils/mediaUtils";
import {
  createCircleMember,
  updateCircleMember,
} from "@commr/admin/services/circleMemberService";
import {
  CircleMemberForm,
  CircleMember,
} from "@commr/admin/models/user/circleMember/circleMember";
import { AppContext } from "@commr/admin/context/appContext";
import UserConnectionInfos from "../users/forms/userConnectionInfos";
import FormFooterActions from "../common/formFooterActions";
import TabPanel from "../common/tabs/tabPanel";
import UserGeneralInfos from "../users/forms/userGeneralInfos";
import CircleMemberUserToCircleMemberForm from "./mappers/circleMemberUserToCircleMemberForm";
import CircleMemberRelationInfos from "./forms/circleMemberRelationInfos";
import CircleMemberFormToCircleMember from "./mappers/circleMemberFormToCircleMember";

interface Props {
  circleMember: CircleMember;
}

const CircleMemberDataContainer: React.FC<React.PropsWithChildren<Props>> = ({
  circleMember,
}) => {
  const history = useHistory();
  const { showToast } = useContext(AppContext);
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);
  const [isUpdate] = useState(
    circleMember?.id !== null && circleMember?.id !== ""
  );
  const [profilePicture, setProfilePicture] = useState(
    circleMember ? circleMember.profilePicture : ""
  );
  const [isDirty, setIsDirty] = useState(false);
  const { tenantId, residencyId, residentId } = useParams<{
    tenantId: string;
    residencyId: string;
    residentId: string;
  }>();

  const defaultValues = circleMember
    ? CircleMemberUserToCircleMemberForm.map(
        circleMember,
        CircleMemberForm,
        CircleMember
      )
    : new CircleMemberForm();
  const { control, register, handleSubmit, reset, setValue } =
    useForm<CircleMemberForm>({
      defaultValues,
    });

  const onSubmit: SubmitHandler<CircleMemberForm> = (circleMemberForm) => {
    setIsSaving(true);
    const newCircleMember = CircleMemberFormToCircleMember.map(
      circleMemberForm,
      CircleMember,
      CircleMemberForm
    );

    let submitAction;

    if (circleMember.id) {
      submitAction = updateCircleMember;
      newCircleMember.id = circleMember.id;
    } else {
      submitAction = createCircleMember;
      newCircleMember.id = uuidv4();
    }
    const currentProfilePicture: Media | undefined = setUserProfilePicture(
      newCircleMember.profilePicture,
      profilePicture,
      tenantId,
      residencyId,
      newCircleMember.id,
      PathType.CircleMembers
    );
    submitAction?.(
      newCircleMember,
      residentId,
      residencyId,
      tenantId,
      currentProfilePicture
    )
      .then(() => {
        showToast(t("forms:successMessages.circleMemberSaved"), "success");
        setIsSaving(false);
        history.push(
          `/tenant/${tenantId}/residency/${residencyId}/resident/${residentId}/1`
        );
      })
      .catch((error) => {
        console.log("error", error);
        showToast(t("forms:errorMessages.saveFailed"), "error");
        setIsSaving(false);
      });
  };

  const onCancel = () => {
    reset(defaultValues);
    setProfilePicture(circleMember ? circleMember.profilePicture : "");
    setIsDirty(false);
  };

  return (
    <form className="add-resident-form" onSubmit={handleSubmit(onSubmit)}>
      <TabPanel value={0} index={0}>
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12}>
            <UserGeneralInfos
              control={control}
              profilePicture={profilePicture}
              setProfilePicture={setProfilePicture}
              register={register}
              setIsDirty={setIsDirty}
            />
          </Grid>
          <Grid item xs={6}>
            <CircleMemberRelationInfos
              control={control}
              setIsDirty={setIsDirty}
            />
          </Grid>
          <Grid item xs={6}>
            <UserConnectionInfos
              control={control}
              isUpdate={isUpdate}
              setIsDirty={setIsDirty}
            />
          </Grid>
        </Grid>
      </TabPanel>
      <FormFooterActions
        isSaving={isSaving}
        handleCancel={onCancel}
        isDirty={isDirty}
      />
    </form>
  );
};

export default CircleMemberDataContainer;
