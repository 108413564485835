import React, { useContext, useState } from "react";
import { Grid } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "@commr/admin/context/appContext";
import Feed, { FeedForm } from "@commr/admin/models/feed/feed";
import { v4 as uuidv4 } from "uuid";
import { createFeed, updateFeed } from "@commr/admin/services/feedsService";
import { UserContext } from "@commr/admin/context/userContext";
import { Media, PathType } from "@commr/admin/models/common/media";
import { setFeedPicture } from "@commr/admin/utils/feedUtils";
import FormFooterActions from "../common/formFooterActions";
import TabPanel from "../common/tabs/tabPanel";
import FeedFormToFeed from "./mappers/feedFormtoFeed";
import FeedToFeedForm from "./mappers/feedToFeedForm";
import FeedGeneralInfos from "./forms/feedGeneralInfos";

interface Props {
  feed: Feed;
}

const FeedDataContainer: React.FC<React.PropsWithChildren<Props>> = ({
  feed,
}) => {
  const history = useHistory();
  const { showToast } = useContext(AppContext);
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);
  const [profilePicture, setProfilePicture] = useState(
    feed ? feed.picture : ""
  );
  const [isDirty, setIsDirty] = useState(true);
  const { user } = useContext(UserContext);
  const { tenantId, residencyId } = useParams<{
    tenantId: string;
    residencyId: string;
  }>();
  const defaultValues = feed
    ? FeedToFeedForm.map(feed, FeedForm, Feed)
    : new FeedForm();
  const { control, register, handleSubmit, reset, setValue, getValues } =
    useForm<FeedForm>({
      defaultValues,
    });

  const onSubmit: SubmitHandler<FeedForm> = (feedForm) => {
    setIsSaving(true);

    const newFeed = FeedFormToFeed.map(feedForm, Feed, FeedForm);

    newFeed.authorId = user.id!;
    newFeed.residencyId = residencyId;
    newFeed.tenantId = tenantId;
    newFeed.picture = profilePicture;

    let submitAction;

    if (feed.id) {
      newFeed.id = feed.id;
      submitAction = updateFeed;
    } else {
      newFeed.id = uuidv4();
      submitAction = createFeed;
    }

    const currentPicture: Media | undefined = setFeedPicture(
      feed.picture,
      profilePicture,
      tenantId,
      residencyId,
      newFeed.id,
      PathType.Feeds
    );

    submitAction(newFeed, currentPicture)
      .then(() => {
        showToast(t("forms:successMessages.feedSaved"), "success");
        setTimeout(() => {
          setIsSaving(false);
          history.push(`/tenant/${tenantId}/residency/${residencyId}/2`);
        }, 2500);
      })
      .catch((error) => {
        console.log("error", error);
        showToast(t("forms:errorMessages.saveFailed"), "error");
        setIsSaving(false);
      });
  };

  const onCancel = () => {
    reset(defaultValues);
    setProfilePicture(feed ? feed.picture : "");
    setIsDirty(false);
  };

  return (
    <form className="add-news-form" onSubmit={handleSubmit(onSubmit)}>
      <TabPanel value={0} index={0}>
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12}>
            <FeedGeneralInfos
              setValue={setValue}
              getValues={getValues}
              control={control}
              profilePicture={profilePicture}
              setProfilePicture={setProfilePicture}
              register={register}
              setIsDirty={setIsDirty}
            />
          </Grid>
          <Grid item xs={6} />
        </Grid>
      </TabPanel>
      <FormFooterActions
        isSaving={isSaving}
        isDirty={isDirty}
        handleCancel={onCancel}
      />
    </form>
  );
};

export default FeedDataContainer;
