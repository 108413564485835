// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  aws_region: "eu-west-3",
  aws_cognito_identity_pool_id:
    "eu-west-3:86f95db8-3e9f-479c-a70b-3b0ff2cf955c",
  aws_user_pools_id: "eu-west-3_GxzCOJMQ7",
  aws_user_pools_web_client_id: "5b7lui9o8phro6fpg289psce39",
  aws_appsync_graphql_end_point:
    "https://ropzxqgpvrg5xnc76rfwrwlbh4.appsync-api.eu-west-3.amazonaws.com/graphql",
  aws_s3_bucket: "liviastorage123721-dev",
  aws_api_custom_admin_queries_end_point:
    "https://jute96qnf1.execute-api.eu-west-3.amazonaws.com/dev",
  aws_api_custom_livia_end_point:
    "https://6mydzh0jv2.execute-api.eu-west-3.amazonaws.com/dev",
  buildVersion: "1.1.1",
  appName: "Livia-Admin",
};
