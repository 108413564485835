import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Add } from "@mui/icons-material";
import { Grid, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import {
  AppSyncSearchQuery,
  AppSyncSearchResult,
} from "@commr/admin/models/common/appSyncSearch";
import { getRoles } from "@commr/admin/services/roleService";
import { Residency } from "@commr/admin/models/residency/residency";
import CustomSearchHeader from "../common/customSearchHeader/customSearchHeader";
import SearchedUserResult from "../common/search/searchResult";
import RoleHit from "./roleHit";

interface Props {
  residency: Residency;
}

const AdminsContainer: React.FC<React.PropsWithChildren<Props>> = ({
  residency,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [searchResult, setSearchResult] = useState<AppSyncSearchResult>(
    new AppSyncSearchResult([], 0, 0)
  );
  const [searchQuery, setSearchQuery] = useState<AppSyncSearchQuery>(
    new AppSyncSearchQuery()
  );

  useEffect(() => {
    console.log("searchQuery", searchQuery);
    if (searchQuery)
      getRoles(searchQuery).then((result: AppSyncSearchResult) => {
        setSearchResult(result);
      });
  }, [searchQuery]);

  const onAddClicked = () => {
    history.push("/roles/addRole");
  };

  return (
    <div className="roles-container">
      <CustomSearchHeader setSearchQuery={setSearchQuery} />
      <Grid item xs={2} sx={{ paddingTop: 1, paddingBottom: 1 }}>
        <Button
          type="button"
          variant="contained"
          size="medium"
          color="primary"
          onClick={onAddClicked}
        >
          <Add />
          {t("common:add")}
        </Button>
      </Grid>
      <SearchedUserResult
        hitComponent={
          <RoleHit
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchResult={searchResult}
          />
        }
      />
    </div>
  );
};

export default AdminsContainer;
